import { ThemeUICSSObject } from "@theme-ui/css";

export const checklistStyles: ThemeUICSSObject = {
  // Scope everything to the block list layout
  ".block-editor-block-list__layout": {
    ".wp-block-dayone-checklist-item": {
      "input[type=checkbox]": {
        backgroundColor: "transparent",
        border: "2px solid",
        borderColor: "borderHighlight",
        width: 3,
        height: 3,
      },
      "&.is-checked": {
        ".rich-text": {
          textDecoration: "none",
          color: "checkboxChecked",

          mark: {
            color: "checkboxChecked",
          },
        },
        "input[type=checkbox]": {
          backgroundColor: "editorCheckboxBg",
          border: "none",
        },
      },
      display: "flex",
      marginBottom: 2,
      alignItems: "flex-start",
      input: {
        width: 4,
      },
      ".rich-text": {
        color: "editorTextDefault",
        fontWeight: "body",
        fontSize: 2,
        lineHeight: 1,
        flex: 1,

        mark: {
          color: "textPrimary",
        },
      },
    },
  },
};
