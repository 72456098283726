import { TagDBRow } from "@/data/db/migrations/tag";
import { TagCount } from "@/data/stores/TagStore";

export const tagsAsArray = (tags: string[] | string): string[] => {
  if (typeof tags === "string") {
    return tags.length > 0 ? tags.split(",") : [];
  }
  return tags;
};

export const uniqueTagId = (t: TagDBRow) =>
  `${t.tag}/${t.journal_id}/${t.entry_id}`;

export const sortTags = (a: TagCount, b: TagCount) => {
  const [tagA, countA] = a;
  const [tagB, countB] = b;

  if (countA === countB) {
    return tagA.localeCompare(tagB);
  }

  return countB - countA;
};
