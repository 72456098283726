import { ThemeUIStyleObject } from "@theme-ui/css";
import { useEffect, useState } from "react";
import { SuggestionTypes } from "types/rtj-format";

import { d1Classes } from "@/D1Classes";
import { isSuggestionType } from "@/components/Editor/rtj2gb/rtj-type-checks";
import { SuggestionThumbnail } from "@/components/EntryPreview/SuggestionThumbnail";
import { Icon } from "@/components/Icon";
import { MomentDBRow } from "@/data/db/migrations/moment";

type Props = {
  moment: MomentDBRow | null;
  type: string;
  journalColor: string;
  invertedColor?: boolean;
  activityType?: string;
};

export const Thumbnail: React.FC<Props> = ({
  moment,
  type,
  journalColor,
  invertedColor,
  activityType,
}) => {
  const [momentURL, setMomentURL] = useState<string | null>(null);

  useEffect(() => {
    const getMomentThumbnailUrl = async () => {
      const thumbnailUrl =
        await d1Classes.momentRepository.getThumbnailUrlForMoment(moment);
      return thumbnailUrl;
    };

    if (!momentURL && moment) {
      getMomentThumbnailUrl().then((url) => {
        setMomentURL(url);
      });
    }
    return () => {
      momentURL && URL.revokeObjectURL(momentURL);
    };
  }, [moment]);

  // The suggestions with an associated thumbnail will be rendered as regular moment below
  if (isSuggestionType(type as SuggestionTypes) && !momentURL) {
    return (
      <SuggestionThumbnail
        type={type as SuggestionTypes}
        activityType={activityType}
      />
    );
  }

  if (type === "audio") {
    return (
      <div
        sx={{
          background: invertedColor ? "white" : journalColor,
          borderRadius: "sm",
          display: "grid",
          placeItems: "center",
          flexShrink: 0,
        }}
      >
        <Icon
          icon="audio-wave"
          iconStyles={{
            color: invertedColor ? journalColor : "surface_light1_dark1",
          }}
        />
      </div>
    );
  }

  const videoStyles: ThemeUIStyleObject =
    type === "video"
      ? {
          position: "relative",
          "&:before": {
            content: `""`,
            height: "100%",
            aspectRatio: "1 / 1",
            position: "absolute",
            left: 0,
            right: 0,
            margin: "auto",
            background: "rgba(255,255,255,0.7)",
            clipPath: "polygon(20% 20%, 80% 50%,  20% 80%)",
          },
        }
      : {};

  return (
    <div
      className="entry-media"
      sx={{
        flex: "none",
        backgroundImage: `url(${momentURL})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "sm",
        position: "relative",
        ...videoStyles,
      }}
    />
  );
};
