import { sprintf } from "@wordpress/i18n";
import { makeAutoObservable, reaction } from "mobx";

import { GlobalEntryID } from "@/data/db/migrations/entry";
import { TagDBRow } from "@/data/db/migrations/tag";
import { EntryModel, globalEntryIDsAreEqual } from "@/data/models/EntryModel";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { getTagsFromContent } from "@/data/repositories/TagParser";
import { EntryStore } from "@/data/stores/EntryStore";
import { JournalStore } from "@/data/stores/JournalStore";
import { TagStore } from "@/data/stores/TagStore";
import { TransferType } from "@/hooks/useEntryTransfer";
import { i18n } from "@/utils/i18n";
import {
  journalId_AllEntries,
  PrimaryViewState,
} from "@/view_state/PrimaryViewState";
import { SnackbarViewState } from "@/view_state/SnackbarViewState";
import { TimelineViewState } from "@/view_state/TimelineViewState";
import { viewStates } from "@/view_state/ViewStates";

export const MULTI_SELECT_LIMIT = 100;

export type SelectedEntryTags = {
  [tag: string]: TagDBRow[];
};

export class EntryMultiSelectViewState {
  multiSelectedEntries: GlobalEntryID[] = []; // The entries that are currently selected
  selectedEntryModels: EntryModel[] = [];
  disabledJournalIDs: string[] = []; // The journals that the user cannot add entries to
  movingEntries: GlobalEntryID[] = []; // The entries that are currently being moved
  loadingEntryMoves = false;
  currentTransferType: TransferType = TransferType.MOVE; // Track the current transfer type
  disabledActions = false;
  shouldShowPinned = false;
  shouldShowStarred = false;
  selectedEntryTags: SelectedEntryTags = {};
  selectedEntryHashtags: string[] = [];

  constructor(
    private primaryViewState: PrimaryViewState,
    private journalStore: JournalStore,
    private entryStore: EntryStore,
    private tagStore: TagStore,
    private snackbarViewState: SnackbarViewState,
    private syncStateRepo: SyncStateRepository,
    private timelineViewState: TimelineViewState,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => ({
        selectedEntry: primaryViewState.selectedGlobalEntryID,
        selectedJournal: primaryViewState.selectedJournalId,
      }),
      (
        { selectedEntry, selectedJournal },
        {
          selectedEntry: prevSelectedEntry,
          selectedJournal: prevSelectedJournal,
        },
      ) => {
        // Reset the multi-selection if the selected entry or selected journal changes
        // When the Entry changes, we only reset when something was selected
        if (
          (selectedEntry?.id && selectedEntry?.id !== prevSelectedEntry?.id) ||
          selectedJournal !== prevSelectedJournal
        ) {
          this.resetMultiSelection();
        }
      },
    );

    reaction(
      () => primaryViewState.journals,
      (journals) => {
        for (const journal of journals) {
          if (journal.is_shared) {
            this.journalStore
              .userCanAddEntryToJournal(journal)
              .then((canAdd) => {
                if (canAdd === "CANNOT_CREATE") {
                  this.disabledJournalIDs.push(journal.id);
                }
                if (canAdd === "CAN_CREATE") {
                  this.disabledJournalIDs = this.disabledJournalIDs.filter(
                    (id) => id !== journal.id,
                  );
                }
              });
          }
        }
      },
    );

    // Check if we have any pinned, starred or shared entries we don't own selected
    // This will be used to disable actions on the multi-select menu and to know which
    // actions to show - pin/unpin, star/unstar
    reaction(
      () => this.multiSelectedEntries.length,
      () => {
        // Using a subscription here to avoid having to manually update Entries
        // after toggling pin/star
        this.entryStore.subscribeToEntries(
          this.multiSelectedEntries,
          (entries) => {
            this.shouldShowStarred = true;
            this.shouldShowPinned = true;
            this.disabledActions = false;

            this.selectedEntryModels = entries.map((e) => {
              const entryModel = this.entryStore.makeEntryModel(e);

              // Only show as Unstar/Unpin if all entries are starred/pinned
              if (!entryModel.isPinned) {
                this.shouldShowPinned = false;
              }
              if (!entryModel.isStarred) {
                this.shouldShowStarred = false;
              }
              if (entryModel.isShared) {
                const isUserCreator =
                  entryModel.creatorUserId === primaryViewState.user?.id;
                this.disabledActions = !isUserCreator;
              }

              if (entryModel.body) {
                this.selectedEntryHashtags.push(
                  ...getTagsFromContent(entryModel.richTextJSON.contents),
                );
              }
              return entryModel;
            });
          },
        );
      },
      {
        fireImmediately: true,
      },
    );

    reaction(
      () => this.multiSelectedEntries.length,
      () => {
        this.tagStore.subscribeToAll((tags) => {
          const taggedEntries: SelectedEntryTags = {};
          for (const tag of tags) {
            if (this.multiSelectedEntries.some((e) => e.id === tag.entry_id)) {
              if (!taggedEntries[tag.tag]) {
                taggedEntries[tag.tag] = [tag];
              } else {
                taggedEntries[tag.tag].push(tag);
              }
            }
          }
          this.selectedEntryTags = taggedEntries;
        });
      },
    );

    reaction(
      () => this.multiSelectedEntries.length,
      () => {
        this.tagStore.subscribeToAll((tags) => {
          const taggedEntries: SelectedEntryTags = {};
          for (const tag of tags) {
            if (this.multiSelectedEntries.some((e) => e.id === tag.entry_id)) {
              if (!taggedEntries[tag.tag]) {
                taggedEntries[tag.tag] = [tag];
              } else {
                taggedEntries[tag.tag].push(tag);
              }
            }
          }
          this.selectedEntryTags = taggedEntries;
        });
      },
    );
  }

  /**
   * Adds or removes entries from the multi-selection.
   * If the entry is already in the multi-selection, it will be removed unless `keepPrevious` is true.
   * This is useful when users mix Cmd+Click and Shift+Click to select entries.
   *
   * @param globalIds The entries to toggle
   * @param keepPrevious Whether to keep the previous multi-selection
   */
  toggleMultiSelection = (globalIds: GlobalEntryID[], keepPrevious = false) => {
    // This allows us to keep the currently selected entry when we start multi-selecting
    if (
      this.multiSelectedEntries.length === 0 &&
      this.primaryViewState.selectedGlobalEntryID?.id
    ) {
      this.multiSelectedEntries.push(
        this.primaryViewState.selectedGlobalEntryID,
      );
    }

    for (const globalId of globalIds) {
      const index = this.multiSelectedEntries.findIndex(
        ({ id }) => id === globalId.id,
      );
      if (index === -1) {
        const numSelectedEntries = this.multiSelectedEntries.length;
        if (numSelectedEntries >= MULTI_SELECT_LIMIT) {
          if (globalIds.length > 1) {
            // If we've reached the limit, scroll to the last entry in the selection
            const { position, height } =
              this.timelineViewState.getPositionForEntryId(
                this.multiSelectedEntries[numSelectedEntries - 1].journal_id,
                this.multiSelectedEntries[numSelectedEntries - 1].id,
              );
            this.timelineViewState.setScrollTop(position - height);
          }

          this.snackbarViewState.newMessage(
            sprintf(
              i18n.__("You can only select up to %s entries at a time."),
              MULTI_SELECT_LIMIT.toString(),
            ),
          );
          return;
        }

        this.multiSelectedEntries.push(globalId);
      } else if (!keepPrevious) {
        this.multiSelectedEntries.splice(index, 1);
        if (this.multiSelectedEntries.length === 1) {
          this.primaryViewState.selectEntry(
            this.multiSelectedEntries[0],
            this.multiSelectedEntries[0].journal_id === journalId_AllEntries,
          );
          this.resetMultiSelection();
        }
      }
    }
  };

  /**
   * Checks if an entry is in the current multi-selection.
   *
   * @param globalId The entry to check
   * @returns Whether the entry is in the multi-selection
   */
  isEntryInMultiSelection = (globalId: GlobalEntryID) => {
    return this.multiSelectedEntries.some((id) =>
      globalEntryIDsAreEqual(id, globalId),
    );
  };

  /**
   * Checks if an entry can be transferred to a journal.
   * For COPY operations, we always allow the transfer.
   * For MOVE operations, we check if the journal is disabled.
   *
   * @param journalId The journal to check
   * @param transferType The type of transfer (MOVE or COPY)
   * @returns Whether the entry can be transferred to the journal
   */
  canTransferEntryToJournal = (
    journalId: string,
    transferType: TransferType,
  ) => {
    // Always allow COPY operations
    if (transferType === TransferType.COPY) {
      return true;
    }

    // For MOVE operations, check if the journal is disabled
    return !this.disabledJournalIDs.includes(journalId);
  };

  /**
   * Checks if an entry can be moved.
   * We allow the move action from the "All Entries" view.
   * We do not allow the move action from a shared journal.
   * Shared Entries moved from the "All Entries" view will still not
   * be moved, but we handle it in the `useEntryTransfer` hook and display the appropriate message.
   *
   * @param entry The entry to check
   * @returns Whether the entry can be moved
   */
  isMoveAllowed = (entry: EntryModel) => {
    const isAllEntries =
      this.primaryViewState.selectedJournalId === journalId_AllEntries;

    return !entry.isShared || isAllEntries;
  };

  /**
   * Stores the entries that are being moved.
   *
   * @param globalIds The entries to move
   */
  startDraggingEntries = (globalIds: GlobalEntryID[]) => {
    this.movingEntries = globalIds;
  };

  /**
   * Resets the moving entries and transfer type.
   */
  stopDraggingEntries = () => {
    this.movingEntries = [];
    this.currentTransferType = TransferType.MOVE;
  };

  /**
   * Resets the multi-selection.
   */
  resetMultiSelection = () => {
    this.multiSelectedEntries = [];
    this.selectedEntryModels = [];
  };

  /**
   * Sets the loading state of the entry moves and locks the sync.
   * This is used to prevent conflicting feed updates while we are moving entries locally.
   *
   * @param loading Whether the entry moves are loading
   */
  setLoadingEntryMoves = (loading: boolean) => {
    if (loading) {
      this.syncStateRepo.setLock();
    } else {
      this.syncStateRepo.clearLock();
    }
    this.loadingEntryMoves = loading;
  };

  /**
   * Checks if entries are currently being moved.
   *
   * @returns Whether entries are currently being moved
   */
  get isMovingEntries() {
    return this.movingEntries.length > 0;
  }

  /**
   * Sets the current transfer type (MOVE or COPY).
   * This is used to determine if journals should be disabled during drag operations.
   *
   * @param transferType The type of transfer
   */
  setCurrentTransferType = (transferType: TransferType) => {
    this.currentTransferType = transferType;
  };

  /**
   * Gets the current transfer type.
   *
   * @returns The current transfer type
   */
  getCurrentTransferType = () => {
    return this.currentTransferType;
  };

  deleteSelectedEntries = async () => {
    const deletedCount = await this.entryStore.bulkDeleteEntries(
      this.multiSelectedEntries,
    );
    if (deletedCount) {
      const failedCount = this.multiSelectedEntries.length - deletedCount;

      if (failedCount > 0) {
        viewStates.snackbar.newMessage(
          sprintf(
            i18n.__(
              "Successfully deleted %s entries. %s entries could not be deleted.",
            ),
            deletedCount.toString(),
            failedCount.toString(),
          ),
        );
      } else {
        viewStates.snackbar.newMessage(
          sprintf(
            i18n.__("Successfully deleted %s entries."),
            deletedCount.toString(),
          ),
        );
      }
    }
    this.multiSelectedEntries = [];
  };

  toggleFavoriteSelectedEntries = async (newValue: boolean) => {
    await this.entryStore.bulkTogglePinOrFavorite(
      this.selectedEntryModels
        .filter((entry) => entry.isStarred !== newValue)
        .map((entry) => ({ id: entry.id, journal_id: entry.journalId })),
      "favorite",
      newValue,
    );
  };

  togglePinnedSelectedEntries = async (newValue: boolean) => {
    await this.entryStore.bulkTogglePinOrFavorite(
      this.selectedEntryModels
        .filter((entry) => entry.isPinned !== newValue)
        .map((entry) => ({ id: entry.id, journal_id: entry.journalId })),
      "pin",
      newValue,
    );
  };

  updateSelectedEntriesTags = async (tag: string, isAdd = true) => {
    // If we are adding a tag, we only want to update the entries that don't already have the tag
    // If we are removing a tag, we want to update all the entries
    const entriesToUpdate = isAdd
      ? this.multiSelectedEntries.filter(
          (e) => !this.selectedEntryTags[tag]?.some((t) => t.entry_id === e.id),
        )
      : this.multiSelectedEntries;
    const newTagRows = entriesToUpdate.map((e) => ({
      tag,
      journal_id: e.journal_id,
      entry_id: e.id,
    }));
    if (isAdd) {
      await this.tagStore.addForMultipleEntries(newTagRows);
    } else {
      await this.tagStore.removeForMultipleEntries(newTagRows);
    }

    await this.entryStore.updatedMultipleEntryTags(entriesToUpdate);
  };
}
