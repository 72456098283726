import { getBlobByURL, revokeBlobURL } from "@wordpress/blob";
import { store as blockEditorStore } from "@wordpress/block-editor";
import { createBlock } from "@wordpress/blocks";
import { useDispatch, useSelect } from "@wordpress/data";
import { useI18n } from "@wordpress/react-i18n";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import {
  IMAGE_BLOCK_ID,
  PDF_BLOCK_ID,
  AUDIO_BLOCK_ID,
  VIDEO_BLOCK_ID,
  JournalEditAttributes,
  ImageBlockEditProps,
  PARAGRAPH_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { useFilePicker } from "@/components/Editor/hooks/useFilePicker";
import { md5 } from "@/crypto/utils/md5";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { MomentDBRow, momentType } from "@/data/db/migrations/moment";
import { MomentModel } from "@/data/models/MomentModel";
import { isValidMomentBlobURL } from "@/data/utils/moments/media";
import { useGalleryMedia } from "@/hooks/useGalleryMedia";
import { cloneArrayBuffer } from "@/utils/buffer-helper";
import { canvasToBlob, createCanvas } from "@/utils/canvas-helpers";
import {
  getThumbnailDimension,
  loadFileArrayBuffer,
} from "@/utils/file-helper";
import { MomentIdsWithModel } from "@/utils/gallery";
import { loadWindowScript } from "@/utils/load-window-script";
import { ViewStates, activeEntryViewState } from "@/view_state/ViewStates";

const MAX_FILE_SIZE = 500e6; // 500mb
const ALLOWED_VIDEO_FORMATS = [".mp4", ".mov"];
const ALLOWED_AUDIO_FORMATS = [".mp4", ".m4a"];
type AttachmentSource = "file_picker" | "paste" | "drag_drop" | "rotate";

const isAllowedType = (file: File) => {
  const ext = ("." + file.name.split(".").pop()).toLowerCase();
  return (
    file.type.startsWith("image/") ||
    file.type === "application/pdf" ||
    ALLOWED_VIDEO_FORMATS.includes(ext) ||
    ALLOWED_AUDIO_FORMATS.includes(ext) ||
    // this type of file name comes from moment copy on the web
    file.name.startsWith("copied-image:") ||
    file.name.startsWith("copied-video:")
  );
};

function isLimitedType(
  file: File,
  permissions: { [key: string]: boolean },
  isEntryShared: boolean,
) {
  const ext = ("." + file.name.split(".").pop()).toLowerCase();
  if (file.type === "application/pdf") {
    return (
      (!isEntryShared && !permissions.canAttachPDF) ||
      (isEntryShared && !permissions.canAttachSharedPDF)
    );
  } else if (ALLOWED_VIDEO_FORMATS.includes(ext)) {
    return (
      (!isEntryShared && !permissions.canAttachVideo) ||
      (isEntryShared && !permissions.canAttachSharedVideo)
    );
  } else if (ALLOWED_AUDIO_FORMATS.includes(ext)) {
    return (
      (!isEntryShared && !permissions.canAttachAudio) ||
      (isEntryShared && !permissions.canAttachSharedAudio)
    );
  }
  return false;
}

function getMediaPermissionList(isEntryShared: boolean) {
  return isEntryShared
    ? [
        "canAttachSharedAudio",
        "canAttachSharedVideo",
        "canAttachSharedPDF",
        "canAttachSharedImage",
      ]
    : ["canAttachAudio", "canAttachVideo", "canAttachPDF", "canAttachImage"];
}

const getAllowedFiles = (
  permissions: { [key: string]: boolean },
  isEntryShared: boolean,
) => {
  const allowedFiles = ["image/*"];
  if (
    permissions.canAttachAudio ||
    (isEntryShared && permissions.canAttachSharedAudio)
  ) {
    allowedFiles.push(...ALLOWED_AUDIO_FORMATS);
  }
  if (
    permissions.canAttachVideo ||
    (isEntryShared && permissions.canAttachSharedVideo)
  ) {
    allowedFiles.push(...ALLOWED_VIDEO_FORMATS);
  }
  if (
    permissions.canAttachPDF ||
    (isEntryShared && permissions.canAttachSharedPDF)
  ) {
    allowedFiles.push("application/pdf");
  }
  return allowedFiles;
};

export const useUpload = (
  globalEntryID: null | GlobalEntryID,
  viewStates: ViewStates,
  attributes: Readonly<JournalEditAttributes>,
  setAttributes: (attrs: Partial<JournalEditAttributes>) => void,
  setFileNotRegistered: Dispatch<SetStateAction<boolean>>,
) => {
  const { handlePastedFiles } = useMediaUpload(globalEntryID, viewStates);

  async function uploadFile() {
    const { journalId, entryId, clientId, src } = attributes;
    if (src && globalEntryID) {
      const f = getBlobByURL(src);
      revokeBlobURL(src); // free memory
      // This is a special case to handle undoing a removed image which was pasted into the editor
      if (!f && journalId && entryId && clientId) {
        const hasMoment = await d1Classes.momentStore.getMomentById(
          journalId,
          entryId,
          clientId,
        );
        if (!hasMoment) {
          setFileNotRegistered(true); // invalid blob; this block will self-destruct on render
        }
      } else if (f) {
        handlePastedFiles([f], setAttributes, setFileNotRegistered);
      }
    }
  }
  return {
    uploadFile,
  };
};

export const useMediaUpload = (
  globalEntryID: null | GlobalEntryID,
  viewStates: ViewStates,
  isEntryShared?: boolean,
) => {
  const primaryViewState = viewStates.primary;
  const [largeFiles, setLargeFiles] = useState<string[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<string[]>([]);
  const [filesMessage, setFilesMessage] = useState<string>("");
  const [someFilesWereRejected, setSomeFilesWereRejected] =
    useState<boolean>(false);
  const mediaPermissionsList = getMediaPermissionList(isEntryShared || false);

  // Any components using this hook will need to be `observer` components
  // since we reference mobx state here. The good news is, since this hook function
  // runs synchronously in the render function of the component, everything will
  // work as expected without doing strange stuff here as long as the caller is an
  // `observer` component.
  const permissions =
    primaryViewState.getEnabledFeatureValues(mediaPermissionsList);

  const attachmentsPerEntry = primaryViewState.attachmentsPerEntryLimit;

  const {
    momentCount,
    setMomentCount,
    userCanAddMedia: canAddMedia,
  } = viewStates.activeEntry;

  const { _n } = useI18n();

  const resetFilesMessage = useCallback(() => {
    setFilesMessage("");
  }, []);

  const isPremium = primaryViewState.user?.subscription_status === "premium";

  const entryId = globalEntryID?.id;
  const journalId = globalEntryID?.journal_id;
  const { momentStore } = d1Classes;

  const { insertBlocks, removeBlock, replaceBlock } =
    useDispatch(blockEditorStore);
  const { currentIndex, getBlockIndex, getBlockRootClientId, getBlocks } =
    useSelect((select) => {
      const {
        // @ts-ignore - The type defs are out of date.
        getBlockIndex,
        // @ts-ignore - The type defs are out of date.
        getBlockInsertionPoint,
        // @ts-ignore - The type defs are out of date.
        getBlockRootClientId,
        // @ts-ignore - The type defs are out of date.
        getBlocks,
      } = select(blockEditorStore);

      return {
        currentIndex: getBlockInsertionPoint().index,
        getBlockIndex,
        getBlockRootClientId,
        getBlocks,
      };
    }, []);
  const { createGalleryFromMoments } = useGalleryMedia();

  const allowedFiles = getAllowedFiles(permissions, isEntryShared || false);
  const { openFilePicker } = useFilePicker(
    allowedFiles,
    // Basic users can also upload multiple images at once
    isEntryShared || isPremium,
  );

  useEffect(() => {
    if (largeFiles.length > 0) {
      const message = _n(
        "File is too large:",
        "Files are too large:",
        largeFiles.length,
      );
      setFilesMessage((m) => m + `${message} \n  ${largeFiles.join(", ")}\n`);
      setLargeFiles([]);
    }
    if (unsupportedFiles.length > 0) {
      const message = _n(
        "File is not a supported file type:",
        "Files are not a supported file type:",
        unsupportedFiles.length,
      );
      setFilesMessage(
        (m) => m + `${message} \n  ${unsupportedFiles.join(", ")}\n`,
      );
      setUnsupportedFiles([]);
    }
    setSomeFilesWereRejected(false);
  }, [someFilesWereRejected]);

  const insertMediaWithFilePicker = async () => {
    const files = await openFilePicker();
    insertMedia(files, "file_picker");
  };

  const updateEditMedia = async (
    file: File,
    momentId: string,
    setAttributes?: ImageBlockEditProps["setAttributes"],
    blockClientId?: string,
  ) => {
    const rootBlockClientId = getBlockRootClientId(blockClientId);
    const blockIndex = getBlockIndex(blockClientId);

    await insertMedia(
      [file],
      "rotate",
      setAttributes,
      undefined,
      rootBlockClientId,
      blockIndex,
    );

    if (journalId && entryId) {
      await momentStore.deleteMoment(journalId, entryId, momentId);
    }

    if (!setAttributes && rootBlockClientId) {
      removeBlock(blockClientId);
    }
  };

  const handleDroppedFiles = useCallback(
    (files: File[], blockClientId?: string, blockIndex?: number) => {
      insertMedia(
        files,
        "drag_drop",
        undefined,
        undefined,
        blockClientId,
        blockIndex,
      );
    },
    [currentIndex, entryId, journalId, momentCount],
  );

  const handlePastedFiles = useCallback(
    (
      files: [File],
      setAttributes: (attrs: Partial<JournalEditAttributes>) => void,
      setFileNotRegistered: Dispatch<SetStateAction<boolean>>,
    ) => {
      insertMedia(files, "paste", setAttributes, setFileNotRegistered);
    },
    [currentIndex, entryId, journalId, momentCount],
  );

  const handlePastedMomentBlobURL = (blobURL: string, type: momentType) => {
    if (isValidMomentBlobURL(blobURL)) {
      let blockID;

      if (type === "image") {
        blockID = IMAGE_BLOCK_ID;
      } else if (type === "video") {
        blockID = VIDEO_BLOCK_ID;
      }

      const blocks = getBlocks();
      const currentBlock = blocks[currentIndex - 1];

      if (blockID && currentBlock) {
        replaceBlock(
          currentBlock.clientId,
          createBlock(blockID, {
            src: blobURL,
          }),
        );
      }
    }
  };

  const insertMedia = async (
    files: File[],
    attachmentSource: AttachmentSource,
    setAttributes?: (attrs: Partial<JournalEditAttributes>) => void,
    setFileNotRegistered?: Dispatch<SetStateAction<boolean>>,
    blockClientId?: string,
    blockIndex?: number,
  ) => {
    // Set this to true to prevent a race condition when adding multiple media files at once
    // Need to set to false when done adding media.
    // This will stop us from updating the editor until all media are ready to be added.
    activeEntryViewState.currentlyAddingMedia = true;
    const largeFiles: string[] = [];
    const unsupportedFiles: string[] = [];
    let showUpgradeModal = false;
    const imageAndVideoMoments: MomentIdsWithModel[] = [];

    for (const f of files) {
      if (viewStates.activeEntry.momentCount >= attachmentsPerEntry.limit) {
        setFileNotRegistered?.(true);
        if (attachmentsPerEntry.canUpgrade) {
          showUpgradeModal = true;
        }
        break;
      }
      if (!isAllowedType(f)) {
        unsupportedFiles.push(f.name);
        continue;
      }
      if (isLimitedType(f, permissions, isEntryShared || false)) {
        showUpgradeModal = true;
        continue;
      }
      if (f.size > MAX_FILE_SIZE) {
        console.log("detected large file");
        largeFiles.push(f.name);
        continue;
      }

      if (!entryId || !journalId) {
        activeEntryViewState.currentlyAddingMedia = false;
        return;
      }
      // Optimistically increment moment count on state as soon as we try to create the moment
      // Then if we don't actually add the file decrease the count
      // This is to handle pasting files as multiple files will be processed at the same time
      // in separate instantces of this hook.
      // This allows us to have an accurate count of moments across all instances.
      setMomentCount(viewStates.activeEntry.momentCount + 1);
      const moment = await momentStore.createLocalMomentFromFile(
        journalId,
        entryId,
        f,
      );
      if (!moment?.thumbnail_md5_body && moment?.type !== "pdfAttachment") {
        Sentry.captureException(new Error(`ERROR: Missing thumbnail md5`));
      }

      if (moment) {
        analytics.tracks.recordEvent(EVENT.attachmentAdd, {
          attachment_type: moment.type,
          attachment_source: attachmentSource,
          entry_id: entryId,
        });
        let blockId = IMAGE_BLOCK_ID;
        switch (moment.type) {
          case "image":
            blockId = IMAGE_BLOCK_ID;
            break;
          case "pdfAttachment":
            blockId = PDF_BLOCK_ID;
            break;
          case "audio":
            blockId = AUDIO_BLOCK_ID;
            break;
          case "video":
            blockId = VIDEO_BLOCK_ID;
            break;
          default:
            break;
        }
        if (setAttributes) {
          setAttributes({
            clientId: moment.id,
            entryId: moment.entry_id,
            journalId: moment.journal_id,
          });
        } else if (moment.type === "image" || moment.type === "video") {
          imageAndVideoMoments.push({
            moment: new MomentModel(moment),
            clientId: moment.id,
            entryId: moment.entry_id,
            journalId: moment.journal_id,
            type: moment.type === "image" ? "photo" : "video",
          });
        } else {
          insertBlocks(
            createBlock(blockId, {
              clientId: moment.id,
              entryId: moment.entry_id,
              journalId: moment.journal_id,
            }),
            currentIndex,
          );
        }

        // Do some special handling to create a pdf thumbnail outside of code that will get imported by our worker
        if (moment.type === "pdfAttachment") {
          await handlePdf(f, moment);
        }
      } else {
        setMomentCount(viewStates.activeEntry.momentCount - 1);
      }
    }

    if (journalId && entryId) {
      const blocks = getBlocks();
      const currentBlock = blocks[currentIndex - 1];
      const isEmptyParagraph =
        currentBlock?.name === PARAGRAPH_BLOCK_ID &&
        !currentBlock?.attributes.content.trim().length;
      // Replace the current block if it's an empty paragraph
      const nextInsertIndex = isEmptyParagraph
        ? currentIndex - 1
        : currentIndex;
      createGalleryFromMoments(
        imageAndVideoMoments,
        journalId,
        entryId,
        blockIndex ?? 1,
        nextInsertIndex,
        blockClientId,
      );
    }

    activeEntryViewState.currentlyAddingMedia = false;

    if (showUpgradeModal) {
      viewStates.modalRouter.showPremiumUpgradeModal("media_upload");
      return;
    }
    if (largeFiles.length > 0) {
      setLargeFiles(largeFiles);
    }
    if (unsupportedFiles.length > 0) {
      setUnsupportedFiles(unsupportedFiles);
    }
    if (largeFiles.length > 0 || unsupportedFiles.length > 0) {
      setSomeFilesWereRejected(true);
    }
  };

  return {
    attachmentsPerEntry,
    canAddMedia,
    canUpgrade: attachmentsPerEntry?.canUpgrade,
    filesMessage,
    handleDroppedFiles,
    handlePastedFiles,
    insertMediaWithFilePicker,
    permissions,
    resetFilesMessage,
    updateEditMedia,
    handlePastedMomentBlobURL,
  };
};

export const createPdfThumbnailBlob = async (pdf: any) => {
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: 1 });
  const thumbnailDimensions = getThumbnailDimension(
    viewport.width,
    viewport.height,
  );
  const { canvas, context } = createCanvas(
    thumbnailDimensions.newWidth,
    thumbnailDimensions.newHeight,
  );
  await page.render({ canvasContext: context, viewport }).promise;
  const thumbnailQuality = 0.5;
  const thumbnailFileType = "image/jpeg";
  return await canvasToBlob(canvas, thumbnailFileType, thumbnailQuality);
};

export const getPdfDimensionsFromArrayBuffer = async (
  arrayBuffer: ArrayBuffer,
) => {
  const pdfjsLib: any = await loadWindowScript("/pdf.min.js", "pdfjsLib");

  pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";
  const uint8Array = new Uint8Array(arrayBuffer);
  const pdf = await pdfjsLib.getDocument({ data: uint8Array }).promise;
  const page = await pdf.getPage(1);
  const { width, height } = page.getViewport({ scale: 1 });
  return { pdf, width, height };
};

export const handlePdf = async (f: File | Uint8Array, moment: MomentDBRow) => {
  const arrayBuffer = f instanceof File ? await loadFileArrayBuffer(f) : f;
  if (!arrayBuffer) return;

  const {
    pdf,
    height: pdfHeight,
    width: pdfWidth,
  } = await getPdfDimensionsFromArrayBuffer(
    // pdf library passes arraybuffer to worker, which will cause buffer to be detached
    // detached buffer cannot be used to create typed array like Uint8Array
    // so we need to clone it
    cloneArrayBuffer(arrayBuffer),
  );

  const thumbnailBlob = await createPdfThumbnailBlob(pdf);
  const thumbnailArrayBuffer = await thumbnailBlob.arrayBuffer();
  const thumbnail = {
    data: thumbnailArrayBuffer,
    width: Math.round(pdfWidth),
    height: Math.round(pdfHeight),
    contentType: "image/jpeg",
    md5: await md5(thumbnailArrayBuffer),
  };

  await d1Classes.momentStore.addThumbnailToMoment(moment, thumbnail);
};
