import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";

import { Sentry } from "@/Sentry";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Item } from "@/components/ItemGroup";
import { ManageSubscriptionModal } from "@/components/Settings/Account/ManageSubscriptionModal";
import { Attribute } from "@/components/Settings/Attribute";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { Label } from "@/components/Settings/Label";
import { UserStore } from "@/data/stores/UserStore";
import { usePromise } from "@/hooks/d1_hooks";
import { viewStates } from "@/view_state/ViewStates";

type Props = {
  userStore: UserStore;
  showUpgrade?: () => void;
};

export const Subscription: React.FC<Props> = ({ userStore, showUpgrade }) => {
  const [, setLocation] = useLocation();
  const [subscription, subscriptionError] = usePromise(
    () => userStore.getActiveUserSubscription(),
    [],
  );
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { __ } = useI18n();
  const { modalRouter } = viewStates;

  useEffect(() => {
    if (subscriptionError) {
      Sentry.captureException(subscriptionError);
    }
  }, [subscriptionError]);

  return (
    <>
      {subscription && (
        <>
          <Item>
            <FlexItem>
              <Label>{__("Account Status")}</Label>
              <Attribute>
                {subscription.subscriptionName === "free" && <span>Basic</span>}
                {(subscription.subscriptionName === "grandfathered" ||
                  subscription.subscriptionName === "plus") && (
                  <span>Plus ({subscription.bundleReason || "purchase"})</span>
                )}
                {subscription.subscriptionName === "premium" && (
                  <span>Premium</span>
                )}
              </Attribute>
            </FlexItem>
            <FlexItem>
              {subscription.subscriptionName === "free" && (
                <EditLinkButton
                  onClick={() => {
                    if (showUpgrade) {
                      showUpgrade();
                    }
                  }}
                  title={__("Go Premium")}
                  label={__("Go Premium")}
                />
              )}
              {subscription.subscriptionName === "premium" && (
                <EditLinkButton
                  onClick={() => setShowSubscriptionModal(true)}
                  title={__("Manage")}
                  label={__("Manage")}
                />
              )}
            </FlexItem>
          </Item>

          {subscription.subscriptionName === "free" && (
            <Item>
              <FlexItem>
                <EditLinkButton
                  onClick={() => {
                    modalRouter.close();
                    setLocation("/redeem");
                  }}
                  title={__("Redeem Gift Code")}
                  label={__("Redeem Gift Code")}
                />
              </FlexItem>
            </Item>
          )}
        </>
      )}
      {subscriptionError && (
        <ErrorMessage
          message={__("There was a problem loading your subscription info")}
        ></ErrorMessage>
      )}
      {showSubscriptionModal && subscription && (
        <ManageSubscriptionModal
          onClose={() => setShowSubscriptionModal(false)}
          subscription={subscription}
        />
      )}
    </>
  );
};
