import { makeAutoObservable } from "mobx";

export class LightboxViewState {
  openedByMomentId: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  openByMomentId = (momentId: string | undefined) => {
    this.openedByMomentId = momentId;
  };
}
