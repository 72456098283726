import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { DODexie } from "@/data/db/dexie_db";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { TagDBRow } from "@/data/db/migrations/tag";
import { uuid } from "@/utils/uuid";

export class TagRepository {
  constructor(protected db: DODexie) {}

  async addForEntry(tag: string, globalEntryID: GlobalEntryID) {
    return await this.db.tags.add({
      tag,
      journal_id: globalEntryID.journal_id,
      entry_id: globalEntryID.id,
    });
  }

  async bulkUpdateHashTagsForEntry(
    tagsToAdd: TagDBRow[],
    tagsToRemove: TagDBRow[],
  ) {
    await this.db.transaction("rw", this.db.tags, async () => {
      await this.addMultipleTags(tagsToAdd);
      await this.bulkRemoveForEntry(tagsToRemove);
    });
  }

  async addMultipleTags(tags: TagDBRow[]) {
    return await this.db.tags.bulkPut(tags);
  }

  async bulkRemoveForEntry(tags: TagDBRow[]) {
    return await this.db.tags
      .where(["tag", "journal_id", "entry_id"])
      .anyOf(tags.map((t) => [t.tag, t.journal_id, t.entry_id]))
      .delete();
  }

  async removeForEntry(tag: string, globalEntryID: GlobalEntryID) {
    return await this.db.tags
      .where({
        tag,
        journal_id: globalEntryID.journal_id,
        entry_id: globalEntryID.id,
      })
      .delete();
  }

  async getTagsForEntry(journalId: string, entryId: string) {
    const tags = await this.db.tags
      .where(["journal_id", "entry_id"])
      .equals([journalId, entryId])
      .toArray();
    return tags.map((t) => t.tag);
  }

  async getTagsForJournal(journalId: string) {
    const tags = await this.db.tags
      .where("journal_id")
      .equals(journalId)
      .toArray();
    return tags.map((t) => t.tag);
  }

  async addTagsForEntry(tags: TagDBRow[]) {
    this.db.tags.bulkPut(tags);
  }

  subscribeToTagsByEntry(
    callback: (tags: string[]) => void,
    journalId: string,
    entryId: string,
  ) {
    const stream = liveQuery(async () => {
      const tags = await this.db.tags
        .where(["journal_id", "entry_id"])
        .equals([journalId, entryId])
        .toArray();
      return tags.map((t) => t.tag);
    }).subscribe((tags) => callback(tags));
    return () => stream.unsubscribe();
  }

  subscribeToTagCountByJournal(
    callback: (tags: number) => void,
    journalId: string,
  ) {
    const stream = liveQuery(async () => {
      const tags = await this.db.tags
        .where("journal_id")
        .equals(journalId)
        .toArray();
      const unique = new Set(tags.map((t) => t.tag));
      return unique.size || 0;
    }).subscribe((count) => callback(count));
    return () => stream.unsubscribe();
  }

  async subscribeToAll(callback: (tags: TagDBRow[]) => void) {
    const sub = liveQuery(() => this.db.tags.toArray()).subscribe(
      callback,
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      sub.unsubscribe();
    };
  }

  async removeLocalTagsForJournals(journalIds: string[]) {
    await this.db.tags.where("journal_id").anyOf(journalIds).delete();
  }

  async changeJournalIdForTagsInEntry(
    entryId: string,
    oldJournalId: string,
    newJournalId: string,
  ) {
    return await this.db.tags
      .where(["journal_id", "entry_id"])
      .equals([oldJournalId, entryId])
      .modify({ journal_id: newJournalId });
  }

  async test_makeTestTag(journalId: string, entryId: string) {
    const x: TagDBRow = {
      journal_id: journalId,
      entry_id: entryId,
      tag: uuid(),
    };
    await this.db.tags.add(x);
    return x;
  }
}
