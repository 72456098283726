import { SelectControl as WpSelectControl } from "@wordpress/components";
import { SelectControlProps } from "@wordpress/components/build-types/select-control/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

import { useIsWindows } from "@/hooks/useIsWindows";

export const SelectControl: React.FC<
  WordPressComponentProps<SelectControlProps, "select", false>
> = (props) => {
  const isWindows = useIsWindows();

  return (
    <div
      sx={{
        "&& label": {
          color: "textPrimary",
        },
        "&&&& .components-input-control__backdrop": {
          borderColor: "borderPrimary",
        },
        ".components-base-control__field": {
          mb: 0,
        },
        ".components-select-control": {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          ".components-flex-item": {
            flexGrow: 1,
          },
        },
        "& .components-input-control__container": {
          justifyContent: "flex-end",
          flexGrow: 1,
          backgroundColor: `${isWindows ? "#fff" : "transparent"}`,
          "& > .components-input-control__suffix svg path": {
            fill: "textPrimary",
            color: "textPrimary",
          },
        },
        "&&& .components-select-control__input": {
          backgroundColor: `${isWindows ? "surface_light1_dark5" : "transparent"}`,
          color: "textPrimary",
          fontSize: 1,
        },
      }}
    >
      <WpSelectControl __nextHasNoMarginBottom {...props} />
    </div>
  );
};
