import { RTJNode, SuggestionTypes } from "types/rtj-format";

import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import {
  getNodeArtworkIdentifier,
  isEmbeddableContentNode,
  isSuggestionType,
} from "@/components/Editor/rtj2gb/rtj-type-checks";
import {
  Activity,
  EntryDBRow,
  GlobalEntryID,
} from "@/data/db/migrations/entry";
import { MomentDBRow } from "@/data/db/migrations/moment";
import { EntryModel } from "@/data/models/EntryModel";
import { Outbox } from "@/data/models/Outbox";
import {
  EntryMoveSendable,
  EntrySendable,
  OutboxResult,
} from "@/data/models/OutboxTypes";
import {
  CreateNewEntryOpts,
  EntryRepository,
} from "@/data/repositories/EntryRepository";
import { MomentRepository } from "@/data/repositories/MomentRepository";
import {
  JournalSyncStatus,
  SyncStateRepository,
} from "@/data/repositories/SyncStateRepository";
import { JournalStore } from "@/data/stores/JournalStore";
import { MomentStore } from "@/data/stores/MomentStore";
import { hasPrefilledContent } from "@/utils/entry";
import { ImportMedia } from "@/utils/import";

export interface EntryPreviewMediaItem {
  id: string;
  type: string;
  moment: MomentDBRow | null;
  activityType?: string;
}

export class EntryStore {
  constructor(
    private entryRepository: EntryRepository,
    private momentStore: MomentStore,
    private momentRepository: MomentRepository,
    private getJournalStore: () => JournalStore,
    private outbox: Outbox,
    private syncStateRepo: SyncStateRepository,
  ) {}

  public makeEntryModel(data: EntryDBRow, moments?: MomentDBRow[]) {
    return new EntryModel(data, moments);
  }

  /*
  SUBS ------------------------------------------------------------------------
  */

  subToCount(journalId: string, callback: (count: number) => void) {
    return this.entryRepository.subscribeToCount(journalId, callback);
  }

  subToEntry(
    globalEntryID: GlobalEntryID,
    callback: (entry: EntryModel | null) => void,
  ) {
    return this.entryRepository.subscribeToEntry(
      globalEntryID,
      ({ entry, moments }) => {
        const entryModel = entry ? this.makeEntryModel(entry, moments) : null;
        callback(entryModel);
      },
    );
  }

  subscribeToEntries(
    globalEntryIDs: GlobalEntryID[],
    callback: (entry: EntryDBRow[]) => void,
  ) {
    return this.entryRepository.subscribeToEntries(globalEntryIDs, callback);
  }

  subToEntryMove(
    entryId: string,
    callback: (entry: EntryMoveSendable[] | null) => void,
  ) {
    return this.outbox.subscribeToEntryMove(entryId, callback);
  }

  /*
  ASYNC -----------------------------------------------------------------------
  */

  async getEntryById(journalId: string, entryId: string) {
    const entry = await this.entryRepository.getEntryById({
      journalId,
      entryId,
    });
    if (entry) {
      return this.makeEntryModel(entry);
    }
    return null;
  }

  async persistEntry(entry: EntryModel, oldContents?: RTJNode[]) {
    if (!entry.isChanged) {
      return;
    }
    try {
      const x = await this.entryRepository.updateEntryLocal(
        entry.journalId,
        entry.id,
        entry.richTextJSON.contents,
        entry.body,
        entry.date,
        entry.isAllDay,
        entry.editDate,
        entry.weather,
        entry.templateID,
        entry.promptID,
        entry.isStarred,
        entry.isPinned,
        oldContents,
        entry.activity,
      );
      const sendable: EntrySendable = {
        id: `${entry.journalId}-${entry.id}`,
        type: "Entry",
        action: "UPDATE",
        entryId: entry.id,
        journalId: entry.journalId,
      };

      await this.outbox.add(sendable);

      const updatedEntry = await this.entryRepository.getEntryById({
        journalId: entry.journalId,
        entryId: entry.id,
      });
      if (updatedEntry) {
        await this.entryRepository.storePendingEntryUpdates(
          entry.id,
          updatedEntry,
        );
      }
      // Reseting the Entry's changed state to make sure it's not persisted through
      // multiple edits
      entry.resetChangedState();

      return x;
    } catch (e: unknown) {
      Sentry.captureException(e);
    }
  }

  async persistMultipleEntries(entryIds: GlobalEntryID[]) {
    const sendables: EntrySendable[] = [];

    for (const entry of entryIds) {
      const sendable: EntrySendable = {
        id: `${entry.journal_id}-${entry.id}`,
        type: "Entry",
        action: "UPDATE",
        entryId: entry.id,
        journalId: entry.journal_id,
      };
      sendables.push(sendable);
    }
    await this.outbox.addMany(sendables);
    await this.entryRepository.bulkStorePendingEntryUpdates(entryIds);
  }

  async createNewEntry(
    journalId: string,
    entryDate: Date,
    opts?: CreateNewEntryOpts,
  ) {
    const entry = await this.entryRepository.createNew(
      journalId,
      entryDate,
      opts,
    );

    if (entry && !hasPrefilledContent(opts)) {
      const sendable: EntrySendable = {
        id: `${entry.journal_id}-${entry.id}`,
        type: "Entry",
        action: "UPDATE",
        entryId: entry.id,
        journalId: entry.journal_id,
      };
      this.outbox.add(sendable);
    }
    return this.makeEntryModel(entry);
  }

  async importEntry(
    entry: EntryDBRow,
    contents: RTJNode[],
    tags: string[],
    media: ImportMedia,
    createPDFThumbnail: (
      f: File | Uint8Array,
      moment: MomentDBRow,
    ) => Promise<void>,
  ) {
    const savedEntry = await this.entryRepository.importEntry(
      entry,
      contents,
      tags,
      media,
      createPDFThumbnail,
    );
    if (savedEntry) {
      const sendable: EntrySendable = {
        id: `${entry.journal_id}-${entry.id}`,
        type: "Entry",
        action: "UPDATE",
        entryId: entry.id,
        journalId: entry.journal_id,
      };
      this.outbox.add(sendable);
    }
    return this.makeEntryModel(entry);
  }

  async persistEntryDelete(entry: GlobalEntryID) {
    await this.outbox.removeItemsForEntry(entry.journal_id, entry.id);

    const sendable: EntrySendable = {
      id: `${entry.journal_id}-${entry.id}`,
      type: "Entry",
      action: "DELETE",
      entryId: entry.id,
      journalId: entry.journal_id,
    };
    this.outbox.add(sendable);
    await this.momentRepository.markAsDeletedForEntry(
      entry.journal_id,
      entry.id,
    );
  }

  async deleteEntry(entry: EntryModel) {
    // If we successfully delete the entry:
    const success = await this.entryRepository.deleteById(
      entry.id,
      entry.journalId,
    );
    if (success) {
      await this.persistEntryDelete({
        journal_id: entry.journalId,
        id: entry.id,
      });
    }
    return success;
  }

  async bulkDeleteEntries(entryIds: GlobalEntryID[]) {
    try {
      const { failedKeys, deletedCount } =
        await this.entryRepository.bulkRemoveLocalEntries(entryIds);

      if (deletedCount > 0) {
        // First we need to filter out the entries that failed to delete
        const deleted =
          failedKeys.length > 0
            ? entryIds.filter(
                (e) =>
                  !failedKeys.find(
                    (f) =>
                      (f as unknown as [string, string])[0] === e.journal_id &&
                      (f as unknown as [string, string])[1] === e.id,
                  ),
              )
            : entryIds;

        // Once we know which ones we successfully deleted, we can add them to the outbox
        for (const entry of deleted) {
          await this.persistEntryDelete(entry);

          analytics.tracks.recordEvent(EVENT.entryDelete, {
            entry_id: entry.id,
          });
        }
      }
      return deletedCount;
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async deleteEntryFromServer(entryId: string, journalId: string) {
    const response = await this.entryRepository.pushEntryDelete(
      entryId,
      journalId,
    );
    if (response.result === "success") {
      // Then delete associated moments, too
      await this.momentStore.deleteForEntry(journalId, entryId);
    }
    return response;
  }

  async updateEntryDate(entry: EntryModel, date: Date) {
    entry.updateDate(date);
    return this.persistEntry(entry);
  }

  async toggleAllDay(entry: EntryModel, date: Date) {
    entry.toggleAllDay();
    entry.updateDate(date);
    return this.persistEntry(entry);
  }

  async updatedEntryTags(entry: EntryModel) {
    entry.updatedTags();
    return this.persistEntry(entry);
  }

  async updatedMultipleEntryTags(entryIds: GlobalEntryID[]) {
    try {
      for (const entry of entryIds) {
        const entryRow = await this.entryRepository.getEntryById({
          journalId: entry.journal_id,
          entryId: entry.id,
        });
        if (entryRow) {
          new EntryModel(entryRow).updatedTags();
        }
      }
      await this.persistMultipleEntries(entryIds);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async updateEntryTemplateInfo(entry: EntryModel, templateID: string) {
    entry.updateTemplateID(templateID);
    return this.persistEntry(entry);
  }

  async toggleFavorite(entry: EntryModel, shouldFavorite?: boolean) {
    entry.toggleFavorite(shouldFavorite);
    return this.persistEntry(entry);
  }

  async updateActivity(entry: EntryModel, activity: Activity) {
    entry.updateActivity(activity);
    return this.persistEntry(entry);
  }

  async togglePinned(entry: EntryModel, shouldPin?: boolean) {
    entry.togglePinned(shouldPin);
    return this.persistEntry(entry);
  }

  async bulkTogglePinOrFavorite(
    entryIds: GlobalEntryID[],
    toggleType: "pin" | "favorite",
    newValue: boolean,
  ) {
    try {
      const { failedKeys, updatedCount } =
        await this.entryRepository.bulkTogglePinOrFavorite(
          entryIds,
          toggleType,
          newValue,
        );

      if (updatedCount > 0) {
        // First we need to filter out the entries that failed to delete
        const updated =
          failedKeys.length > 0
            ? entryIds.filter(
                (e) =>
                  !failedKeys.find(
                    (k) => k.journal_id === e.journal_id && k.id === e.id,
                  ),
              )
            : entryIds;

        const sendables: EntrySendable[] = [];
        // Once we know which ones we successfully deleted, we can add them to the outbox
        for (const entry of updated) {
          const sendable: EntrySendable = {
            id: `${entry.journal_id}-${entry.id}`,
            type: "Entry",
            action: "UPDATE",
            entryId: entry.id,
            journalId: entry.journal_id,
          };
          sendables.push(sendable);
        }
        await this.outbox.addMany(sendables);
        await this.entryRepository.bulkStorePendingEntryUpdates(updated);
      }
      return updatedCount;
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async updateEntryContents(
    entry: EntryModel,
    editDate: number,
    markdown: string,
    rtjNodes: RTJNode[],
    templateID?: string,
  ) {
    const oldContents = entry.richTextJSON.contents;
    entry.updateContents(markdown, rtjNodes, editDate, /*tags,*/ templateID);

    await Promise.all([
      this.persistEntry(entry, oldContents),
      this.momentRepository.updateMomentDeletionFromRTJ(
        entry.journalId,
        entry.id,
        rtjNodes,
      ),
    ]);
  }

  async isE2E(entry: EntryModel) {
    const j = await this.getJournalStore().isJournalE2EE(entry.journalId);
    if (j == null) {
      Sentry.captureException(
        new Error(
          `Journal ${entry.journalId} not found when checking if entry is E2EE`,
        ),
      );
    }
    return !!j;
  }

  async markEntryRead(globalEntryID: GlobalEntryID, unreadMarkerId: string) {
    return this.entryRepository.markEntryRead(globalEntryID, unreadMarkerId);
  }

  /*
    SYNC ------------------------------------------------------------------------
  */

  async updateSyncStatus(
    journalId: string,
    status: JournalSyncStatus,
    hasCompleted?: boolean,
  ) {
    this.syncStateRepo.setJournalSyncStatus(journalId, status, hasCompleted);
  }

  async getSyncIds() {
    return this.syncStateRepo.getIdsForSyncingJournals();
  }

  async syncOneJournal(id: string) {
    try {
      await this.updateSyncStatus(id, "SYNCING");
      await this.entryRepository.synchronizeByJournalId(id);
      await this.updateSyncStatus(id, "IDLE", true);
    } catch (e: any) {
      Sentry.captureException(e);
      await this.updateSyncStatus(id, "ERROR");
    }
  }

  async sync(excludeJournalId?: string): Promise<void> {
    // First check if there's a journal that's waiting for initial sync
    const waitingJournalId =
      await this.syncStateRepo.getIdForNextQueuedInitialSync();
    // If so, do that and recurse.
    if (waitingJournalId) {
      await this.syncOneJournal(waitingJournalId);
      // Passing the waitingJournalId to avoid syncing it again below
      return this.sync(waitingJournalId);
    }
    // If not, sync all existing IDs and don't recurse.
    const journalIds = await this.syncStateRepo.getIdsForSyncingJournals();
    if (journalIds.length) {
      // Sync journals in sequence
      for (const id of journalIds) {
        // This avoids syncing the same journal twice on initial sync
        if (id === excludeJournalId) {
          continue;
        }
        await this.syncOneJournal(id);
      }
    }
  }

  // Only entries read remotely need to be updated locally
  // If an entry came with a new unread marker it will be because it
  // has been updated remotely and in that case the new entry revision
  // will already have the correct unread marker.
  async syncUnread(): Promise<void> {
    const entriesFromDB = (await this.entryRepository.getUnreadEntries()).map(
      (entry) => ({ journal_id: entry.journal_id, id: entry.id }),
    );
    const entriesFromApi = (await this.entryRepository.syncUnread()).map(
      (unreadEntry: { id: string; journal_id: string; entry_id: string }) => ({
        journal_id: unreadEntry.journal_id,
        id: unreadEntry.entry_id,
      }),
    ) as GlobalEntryID[];

    const entriesToMarkRead = entriesFromDB.reduce(
      (acc: GlobalEntryID[], globalEntryId) => {
        if (
          !entriesFromApi.find(
            (e) =>
              e.id === globalEntryId.id &&
              e.journal_id === globalEntryId.journal_id,
          )
        ) {
          acc.push(globalEntryId);
        }
        return acc;
      },
      [],
    );
    await this.entryRepository.bulkUpdateUnreadMarker(entriesToMarkRead);
  }

  async subToSync(
    callback: (status: Record<string, JournalSyncStatus>) => void,
  ) {
    return this.syncStateRepo.subscribeToJournalSyncStatuses(callback);
  }

  subscribeToUnreadByJournal(
    callback: (hasUnread: boolean) => void,
    journalId: string,
  ) {
    return this.entryRepository.subscribeToUnreadByJournal(callback, journalId);
  }

  async pushEntryToServer(
    journalId: string,
    entryId: string,
  ): Promise<OutboxResult> {
    return this.entryRepository
      .syncEntryUp(journalId, entryId)
      .then((response) => {
        if (analytics) {
          analytics.tracks.recordEvent(EVENT.entryUpload, {
            entry_id: entryId,
            status: response.result,
          });
        }
        return response;
      });
  }

  removeJournalFromSyncJobs(journalId: string) {
    return this.syncStateRepo.disableSyncForJournal(journalId);
  }

  public addJournalToSyncJobs(journalId: string) {
    return this.syncStateRepo.enableSyncForJournal(journalId);
  }

  public getOrderedMomentsAndSuggestions = async (
    entry: EntryModel,
    useMarkdown?: boolean,
  ) => {
    const orderedMoments: {
      audio: EntryPreviewMediaItem[];
      favorites: EntryPreviewMediaItem[];
      suggestions: EntryPreviewMediaItem[];
      rest: EntryPreviewMediaItem[];
    } = {
      audio: [],
      favorites: [],
      suggestions: [],
      rest: [],
    };

    const isRTJEntry = !useMarkdown && entry?.richTextJSON.contents.length > 0;

    const addToSortedMoments = (
      id: string,
      type: string,
      moment: MomentDBRow | null,
      activityType?: string,
    ) => {
      const mediaNode = {
        id,
        type,
        moment,
        activityType,
      };

      if (orderedMoments.audio.length < 1 && moment?.type === "audio") {
        orderedMoments.audio.push(mediaNode);
      } else if (moment?.favorite) {
        orderedMoments.favorites.push(mediaNode);
      } else if (isSuggestionType(mediaNode.type as SuggestionTypes)) {
        orderedMoments.favorites.push(mediaNode);
      } else {
        orderedMoments.rest.push(mediaNode);
      }
    };
    if (isRTJEntry) {
      for (const content of entry.entryContents) {
        if (isEmbeddableContentNode(content)) {
          for (const node of content.embeddedObjects) {
            const id = getNodeArtworkIdentifier(node);
            if (id) {
              // If the the node is a suggestion without thumbnail it won't have a moment
              const moment = await this.momentRepository.getMomentById(
                entry.journalId,
                entry.id,
                id,
              );
              const activityType =
                node.type === "workout" ? node.activityType : undefined;
              addToSortedMoments(id, node.type, moment, activityType);
            }
          }
        }
      }
    }
    // If it's not RTJ we are processing a Markdown Entry.
    else {
      const moments = await this.entryRepository.getEntryMomentsFromBody(entry);
      if (moments) {
        for (const moment of moments) {
          addToSortedMoments(moment.id, moment.type, moment);
        }
      }
    }

    return [
      ...orderedMoments.audio,
      ...orderedMoments.favorites,
      ...orderedMoments.suggestions,
      ...orderedMoments.rest,
    ];
  };

  // move journal locally
  public async moveToJournal(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
    fail = false,
  ) {
    const moveResult = await this.entryRepository.moveEntryToJournal(
      entryId,
      sourceJournalId,
      destinationJournalId,
    );

    // Make sure we need to add the entry move to the outbox
    if (moveResult.shouldAddToOutbox) {
      // Check if there's an existing entry move in the outbox and, if it exists
      // use that as the source journalId
      const oldEntryMoves = await this.outbox.getAllEntryMoves(entryId);

      const sendable: EntryMoveSendable = {
        id: `${sourceJournalId}-${destinationJournalId}-${entryId}`,
        type: "Entry",
        action: "MOVE",
        entryId,
        journalId: sourceJournalId,
        destinationJournalId,
        _testFailMove: fail,
      };
      if (oldEntryMoves.length === 0) {
        sendable.isNextMove = true;
      }

      const syncStatus = await this.syncStateRepo.getSyncStatus();
      // If there's only one Entry Move in the Outbox and the Sync is IDLE
      // we can safely update it with the new move Journal ids.

      if (oldEntryMoves.length === 1 && syncStatus === "IDLE") {
        const pendingMove = oldEntryMoves[0];
        sendable.id = `${pendingMove.journalId}-${destinationJournalId}-${entryId}`;
        sendable.journalId = pendingMove.journalId;
        await this.outbox.updateExistingEntryMove(sendable, pendingMove);
      } else if (oldEntryMoves.length > 1) {
        // There should only be at most 2 Entry Moves in the Outbox at all times
        const pendingMove = oldEntryMoves.find(
          (move) => move.isNextMove !== true,
        );
        // In this case, whene we already have a move that has been pushed to the server
        // and there is another move that is pending, we can update the pending move with
        // the new journal ids
        if (pendingMove) {
          sendable.id = `${pendingMove.journalId}-${destinationJournalId}-${entryId}`;
          sendable.journalId = pendingMove.journalId;
          await this.outbox.updateExistingEntryMove(sendable, pendingMove);
        }
      }
      // Every other case we add the entry move to the outbox.
      else {
        await this.outbox.add(sendable);
      }
    }

    return moveResult;
  }

  // copy entry locally
  public async copyToJournal(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
  ) {
    const copyResult = await this.entryRepository.copyEntryToJournal(
      entryId,
      sourceJournalId,
      destinationJournalId,
    );

    if (!copyResult.resultEntryId) {
      return null;
    }

    return copyResult;
  }

  public async pushEntryMove(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
    fail = false,
  ) {
    const entryMoveReponse = await this.entryRepository.pushEntryMove(
      entryId,
      sourceJournalId,
      destinationJournalId,
      fail,
    );

    return entryMoveReponse;
  }

  public async getEntryMoveStatus(entryMoveId: string) {
    return this.entryRepository.getEntryMoveStatus(entryMoveId);
  }

  public async getRtjNodesAndMedia(id: GlobalEntryID): Promise<{
    rtjson: string;
    media: { id: string; extension: string; blob: Uint8Array }[];
  }> {
    const entry = await this.entryRepository.getEntryById({
      journalId: id.journal_id,
      entryId: id.id,
    });
    if (!entry) {
      throw new Error(
        "Tried to load up RTJ nodes and media for entry that doesn't exist",
      );
    }

    const rtjson = entry.rich_text_json;
    const moments = await this.momentRepository.getForEntry(
      id.journal_id,
      id.id,
      true,
    );

    const allowedMedia = ["image", "photo", "video", "pdfAttachment"];

    const filterMomentsFromRTJ = (rtjson: string) => {
      const parsed = JSON.parse(rtjson);
      const filtered = parsed.contents.filter((node: RTJNode) => {
        if (isEmbeddableContentNode(node)) {
          return allowedMedia.includes(node.embeddedObjects[0].type);
        }
        return true;
      });
      return JSON.stringify({ ...parsed, contents: filtered });
    };

    const rtjsonFiltered = filterMomentsFromRTJ(rtjson);
    const media: { id: string; extension: string; blob: Uint8Array }[] = [];
    for (const moment of moments) {
      if (allowedMedia.includes(moment.type)) {
        const blob = await this.momentRepository.getMediaForMoment(
          moment.id,
          moment.md5_body,
          moment.md5_envelope,
          moment.journal_id,
        );
        if (!blob || typeof blob === "string") {
          throw new Error(
            `Failed when trying to load media for moment ${moment.id} while building rtjson and moments in memory`,
          );
        }
        const extension = moment.content_type.split("/")[1];
        media.push({ id: moment.id, extension, blob });
      }
    }

    return {
      rtjson: rtjsonFiltered,
      media,
    };
  }
}
