import { getClientMeta } from "@/data/utils/clientMeta";
import { isDevEnvironment } from "@/utils/is-dev-environment";

/**
 * Checks if the current environment URL is development and
 * if the forcedevoff query parameter is not set.
 *
 * @returns boolean
 */
export const isDevelopment = (): boolean => {
  return isDevEnvironment() && !forceDevelopmentOff();
};

export const isWindows = async (): Promise<boolean> => {
  const clientMeta = await getClientMeta();
  return (
    clientMeta.creationOSName === "Windows" ||
    clientMeta.creationOSName === "Win32"
  );
};

export const isMac = async (): Promise<boolean> => {
  const clientMeta = await getClientMeta();
  return clientMeta.creationOSName === "macOS";
};

export const isElectronApp = (): boolean => {
  if (typeof window == "undefined") {
    return false;
  }
  return !!window.IS_ELECTRON_APP;
};

// A non-async method to check if we are in electron on windows
export const isElectronWindows = () => {
  const isWindows = navigator.platform.toLowerCase().includes("win");
  return isElectronApp() && isWindows;
};

const forceDevelopmentOff = (): boolean => {
  const urlParams = new URLSearchParams(self.location.search);
  return urlParams.get("forcedevoff") ? true : false;
};

export const supportsCredentialless = async () => {
  const clientMeta = await getClientMeta();
  const browser = clientMeta.browserName || "";
  const supportedBrowsers = ["Chrome", "Google Chrome", "Microsoft Edge"];
  if (supportedBrowsers.includes(browser)) {
    return true;
  }
  return false;
};
