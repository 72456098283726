import { useBlockProps } from "@wordpress/block-editor";
import { observer } from "mobx-react-lite";
import { CSSProperties, useEffect, useRef } from "react";
import { useState } from "react";

import { FailedMedia } from "@/components/Editor/components/FailedMedia";
import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { UploadingMediaIcon } from "@/components/Editor/components/UploadingMediaIcon";
import { TopRightButtonWrapper } from "@/components/SyncableImage/TopRightButton";
import { useMoment } from "@/data/hooks/moments";
import { dayOneBlue } from "@/styles/theme";
import { getMediaWidth } from "@/utils/gallery";
import {
  activeEntryViewState,
  lightboxViewState,
} from "@/view_state/ViewStates";

type BlobVideoProps = {
  journalId: string;
  entryId: string;
  clientId: string;
  aspectRatio?: number;
  isSelected: boolean;
  heightInGallery?: number;
  blockClientId: string;
};

// Display a syncable video by MD5
export const SyncableVideo: React.FC<BlobVideoProps> = observer(
  ({
    journalId,
    entryId,
    clientId,
    aspectRatio,
    isSelected,
    heightInGallery,
    blockClientId,
  }) => {
    const { blob, isLoading, moment } = useMoment(journalId, entryId, clientId);
    const blockProps = useBlockProps({
      style: {
        position: "relative",
        border: "none",
      } as CSSProperties,
    });
    const [isHovered, setIsHovered] = useState(false);
    const isInGallery = !!(aspectRatio && heightInGallery);
    const videoSourceRef = useRef<HTMLSourceElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const isLightboxOpen = !!lightboxViewState.openedByMomentId;

    const onLoadStart = () => {
      if (blob && moment) {
        activeEntryViewState.addMediaItem({
          url: videoSourceRef.current?.src ?? URL.createObjectURL(blob),
          moment,
        });
      }
    };

    const pauseVideo = () => {
      if (!videoRef.current?.paused) {
        videoRef.current?.pause();
      }
    };

    const toggleLightbox = (isOpen: boolean) => {
      lightboxViewState.openByMomentId(isOpen ? moment?.id : undefined);
    };

    useEffect(() => {
      if (isLightboxOpen) {
        pauseVideo();
      }
    }, [isLightboxOpen]);

    if (isLoading) {
      if (isInGallery) {
        return (
          <LoadingMediaPlaceholder
            styles={{
              flexBasis: 0,
              flexGrow: aspectRatio,
              aspectRatio,
            }}
          />
        );
      }

      if (moment) {
        return (
          <LoadingMediaPlaceholder
            key={moment.id}
            height={moment.height || undefined}
            width={moment.width || undefined}
          />
        );
      }

      return <LoadingMediaPlaceholder />;
    }

    if (blob && moment) {
      return (
        <>
          <div
            {...blockProps}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            data-momentid={moment?.id}
            sx={{
              position: "relative",
              boxShadow: isInGallery
                ? `0 0 0 1px ${isSelected ? dayOneBlue : "transparent"}`
                : undefined,
              "& video": {
                p: "1px",
                borderRadius: isInGallery ? undefined : "3px",
                border: isInGallery ? undefined : "3px solid",
                borderColor: isSelected ? dayOneBlue : "transparent",
                objectFit: "cover",
                height: isInGallery ? `${heightInGallery}px` : "100%",
                width: isInGallery
                  ? `${getMediaWidth(aspectRatio, heightInGallery)}px`
                  : undefined,
              },
            }}
          >
            <video
              controls
              onLoadStart={onLoadStart}
              onClick={(e) => {
                // detail is the current click count for click event
                // so this is a double click
                if (e.detail === 2) {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleLightbox(true);
                }
              }}
              onPlay={() => {
                if (isLightboxOpen) {
                  pauseVideo();
                }
              }}
              ref={videoRef}
            >
              <source ref={videoSourceRef} src={URL.createObjectURL(blob)} />
            </video>

            <UploadingMediaIcon moment={moment}></UploadingMediaIcon>
            <TopRightButtonWrapper
              blockClientId={blockClientId}
              showButton={isHovered}
              moment={moment}
            />
          </div>
        </>
      );
    }

    if (isInGallery) {
      return (
        <div
          {...blockProps}
          sx={{
            div: {
              my: 0,
            },
          }}
        >
          <FailedMedia icon="video" moment={moment} />
        </div>
      );
    }

    return <FailedMedia icon="video" moment={moment} />;
  },
);
