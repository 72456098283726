import { Button, CheckboxControl } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { GroupHeading, Item, ItemGroup } from "@/components/ItemGroup";
import { D1DevDecryptor } from "@/components/Settings/Developer/D1DevDecryptor";
import { TestCSP } from "@/components/Settings/Developer/TestCSP";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { useGoogleDrive } from "@/hooks/google/useGoogleDrive";
import {
  viewStates,
  primaryViewState,
  featureFlagsViewState,
} from "@/view_state/ViewStates";
type DeleteStatus = "DELETING" | "DELETED" | "ERROR" | "NOT_STARTED";

export const DeveloperSettings: React.FC = observer(() => {
  const { __ } = useI18n();
  const { deleteFile, connectGoogleDrive, authorized } =
    useGoogleDrive(primaryViewState);
  const isElectronApp = primaryViewState.isElectronApp();

  const onClick = () => {
    d1Classes.syncStateRepository.setObliterationFlag().then(() => {
      window.location.reload();
    });
  };
  const showSnackbar = (message: string) => {
    viewStates.snackbar.newMessage(message);
  };

  const [deletedGoogleDriveFile, setDeletedGoogleDriveFile] =
    useState<DeleteStatus>("NOT_STARTED");

  const [makeMissingMedia, setMakeMissingMedia] = useState<null | boolean>(
    null,
  );

  const toggleMakeMissingMedia = () => {
    d1Classes.momentRepository.toggleShouldMakeMissingMedia().then(() => {
      setMakeMissingMedia(!makeMissingMedia);
    });
  };

  const deleteGoogleDriveFile = () => {
    connectGoogleDrive("Can't connect to Google Drive");
    setDeletedGoogleDriveFile("DELETING");
  };

  useEffect(() => {
    d1Classes.momentRepository
      .shouldMakeMissingMedia()
      .then((result) => {
        setMakeMissingMedia(result);
      })
      .catch((err) => Sentry.captureException(err));
  }, []);

  useEffect(() => {
    if (authorized && deletedGoogleDriveFile === "DELETING") {
      deleteFile().then((result) => {
        if (result) {
          setDeletedGoogleDriveFile("DELETED");
          showSnackbar("Google Drive file deleted");
          d1Classes.userKeysRepository.removeLocationOfKey("drive");
        } else {
          showSnackbar("Failed to delete Google Drive file");
        }
      });
    }
  }, [authorized, deletedGoogleDriveFile]);

  return (
    <SettingsPanel header={<span>{__("Developer")}</span>}>
      <GroupHeading id="destructive">
        Destructive Convenience Actions
      </GroupHeading>
      <ItemGroup>
        <Item>
          <p sx={{ fontSize: 1, flex: 2 }}>
            Delete all of your sync data locally without signing out and having
            to sign back in.
          </p>
          <div
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              isDestructive={true}
              onClick={() => onClick()}
              sx={{ display: "block" }}
            >
              Delete
            </Button>
          </div>
        </Item>

        <Item>
          <p sx={{ fontSize: 1, flex: 2 }}>
            Delete Google Drive AppDataFolder file
          </p>
          <div
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              isDestructive={true}
              disabled={
                deletedGoogleDriveFile === "DELETING" ||
                deletedGoogleDriveFile === "DELETED"
              }
              onClick={() => deleteGoogleDriveFile()}
              sx={{ display: "block" }}
            >
              {deletedGoogleDriveFile === "DELETING" && "Deleting..."}
              {deletedGoogleDriveFile === "DELETED" && "Deleted"}
              {deletedGoogleDriveFile === "NOT_STARTED" && "Delete"}
            </Button>
          </div>
        </Item>

        <Item>
          <p sx={{ fontSize: 1, flex: 2 }}>
            Ignores uploads of originals while checked. Not persisted. After a
            page reload this will be reset to "off"
          </p>
          <div sx={{ flex: 1 }}>
            <CheckboxControl
              __nextHasNoMarginBottom
              sx={{ mb: 2 }}
              label="Make Missing Media"
              checked={!!makeMissingMedia}
              disabled={makeMissingMedia === null}
              onChange={() => {
                toggleMakeMissingMedia();
              }}
            />
          </div>
        </Item>

        <Item>
          <p sx={{ fontSize: 1, flex: 2 }}>
            Forcefully fails every other Entry transfer for test purposes. After
            a page reload this will be reset to "off".
          </p>
          <div sx={{ flex: 1 }}>
            <CheckboxControl
              label="Fail Entry transfers"
              checked={!!featureFlagsViewState.shouldFailEntryTransfers}
              onChange={featureFlagsViewState.toggleShouldFailEntryTransfers}
            />
          </div>
        </Item>
      </ItemGroup>

      <GroupHeading id="csp">Test CSP</GroupHeading>
      <ItemGroup>
        <TestCSP />
      </ItemGroup>

      <GroupHeading id="throw-error">Throw an Error</GroupHeading>
      <ItemGroup>
        <Item>
          <p sx={{ fontSize: 1, flex: 2 }}>
            Use this to test error handling / logging
          </p>
          <Button
            variant="primary"
            onClick={() => {
              throw new Error("Dev Test Error");
            }}
          >
            Throw Error
          </Button>
        </Item>
      </ItemGroup>

      <>
        <GroupHeading id="electron-app">App context</GroupHeading>
        <ItemGroup>
          <Item>
            <p>Desktop App: {isElectronApp ? "Yes" : "No"}</p>
          </Item>
        </ItemGroup>
      </>

      <GroupHeading id="dev-d1-decryptor">Dev D1 Decryptor</GroupHeading>
      <ItemGroup>
        <Item>
          <p sx={{ fontSize: 1, mb: 3 }}>
            Select a .d1 file from your hard drive and we'll attempt to decrypt
            it, provided the journal it belongs to is currently being synced.
          </p>
          <D1DevDecryptor />
        </Item>
      </ItemGroup>
    </SettingsPanel>
  );
});

DeveloperSettings.displayName = "DeveloperSettings";
