import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { WPConnectAPIClient } from "@/components/Settings/WPConnect/WPConnectAPIClient";
import { DODevTools } from "@/data/DODevTools";
import { MSApiClient, makeMSApiClient } from "@/data/MSApiClient";
import { AIAPICaller } from "@/data/api_callers/AIAPICaller";
import { DailyChatAPICaller } from "@/data/api_callers/DailyChatAPICaller";
import { JournalPresetAPICaller } from "@/data/api_callers/JournalPresetAPICaller";
import { LoginAPICaller } from "@/data/api_callers/LoginAPICaller";
import { PBCPromptAPICaller } from "@/data/api_callers/PBCPromptAPICaller";
import { PBCTemplateAPICaller } from "@/data/api_callers/PBCTemplateAPICaller";
import { SupportFormAPICaller } from "@/data/api_callers/SupportFormAPICaller";
import { AIController } from "@/data/controllers/AIController";
import { JournalPresetController } from "@/data/controllers/JournalPresetController";
import { PBCPromptController } from "@/data/controllers/PBCPromptController";
import { PBCTemplateController } from "@/data/controllers/PBCTemplateController";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { SecureKeyValueStore } from "@/data/db/SecureKeyValueStore";
import { UsageStatisticsStore } from "@/data/db/UsageStatisticsStore";
import { DODexie } from "@/data/db/dexie_db";
import { addJournalHooks } from "@/data/db/hooks/journalHooks";
import { Outbox } from "@/data/models/Outbox";
import { CommentReactionRepository } from "@/data/repositories/CommentReactionRepository";
import { CommentRepository } from "@/data/repositories/CommentRepository";
import { ContentKeysRepository } from "@/data/repositories/ContentKeysRepository";
import { DailyPromptRepository } from "@/data/repositories/DailyPromptRepository";
import { EntryRepository } from "@/data/repositories/EntryRepository";
import { ImportExportRepository } from "@/data/repositories/ImportExportRepository";
import { JournalCoverRepository } from "@/data/repositories/JournalCoverRepository";
import { JournalParticipantRepository } from "@/data/repositories/JournalParticipantRepository";
import { JournalPresetRepository } from "@/data/repositories/JournalPresetRepository";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { JournalStatsRepository } from "@/data/repositories/JournalStatsRepository";
import { MomentRepository } from "@/data/repositories/MomentRepository";
import { NotificationRepository } from "@/data/repositories/NotificationRepository";
import { PBCPromptRepository } from "@/data/repositories/PBCPromptRepository";
import { PBCTemplateRepository } from "@/data/repositories/PBCTemplateRepository";
import { ReactionRepository } from "@/data/repositories/ReactionRepository";
import { SearchRepository } from "@/data/repositories/SearchRepository";
import { SharedJournalRepository } from "@/data/repositories/SharedJournalsRepository";
import { SyncOperationsRepository } from "@/data/repositories/SyncOperationsRepository";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { SyncTelemetryRepository } from "@/data/repositories/SyncTelemetryRepository";
import { TagRepository } from "@/data/repositories/TagRepository";
import { TemplateRepository } from "@/data/repositories/TemplateRepository";
import { UserKeysRepository } from "@/data/repositories/UserKeysRepository";
import { UserRepository } from "@/data/repositories/UserRepository";
import { UserSettingsRepository } from "@/data/repositories/UserSettingsRepository";
import { VaultRepository } from "@/data/repositories/VaultRepository";
import { DecryptionService } from "@/data/services/DecryptionService";
import { DailyPromptStore } from "@/data/stores/DailyPromptStore";
import { EntryStore } from "@/data/stores/EntryStore";
import { JournalStore } from "@/data/stores/JournalStore";
import { MomentStore } from "@/data/stores/MomentStore";
import { NotificationStore } from "@/data/stores/NotificationStore";
import { ReactionStore } from "@/data/stores/ReactionStore";
import { TagStore } from "@/data/stores/TagStore";
import { TemplateStore } from "@/data/stores/TemplateStore";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { UserStore } from "@/data/stores/UserStore";
import { uuid } from "@/utils/uuid";
import { addSearchWorkerHooks } from "@/worker/search-helpers";

// This file is imported by both the worker and the main UI thread.
// We've got to make sure that anything imported in this file doesn't
// use DOM or window APIs, or the worker will break.
// Keep all React stuff in code that the main thread imports.
// If you need something from a file that also imports front-end stuff
// (like gutenberg blogs, for example), extract the piece you need into a
// separate file and import that instead.

export type D1Classes = {
  db: DODexie;
  fetchWrapper: FetchWrapper;
  journalCoverRepository: JournalCoverRepository;
  keyValueStore: KeyValueStore;
  secureKeyValueStore: SecureKeyValueStore;
  outbox: Outbox;
  userRepository: UserRepository;
  userStore: UserStore;
  userKeysRepository: UserKeysRepository;
  vaultRepository: VaultRepository;
  decryptionService: DecryptionService;
  syncStateRepository: SyncStateRepository;
  journalRepository: JournalRepository;
  journalStatsRepository: JournalStatsRepository;
  sharedJournalRepository: SharedJournalRepository;
  journalParticipantRepository: JournalParticipantRepository;
  momentRepository: MomentRepository;
  momentStore: MomentStore;
  entryRepository: EntryRepository;
  entryStore: EntryStore;
  userKeysStore: UserKeysStore;
  journalStore: JournalStore;
  templateStore: TemplateStore;
  templateRepository: TemplateRepository;
  pbcTemplateController: PBCTemplateController;
  tagStore: TagStore;
  tagRepository: TagRepository;
  reactionStore: ReactionStore;
  reactionRepository: ReactionRepository;
  notificationStore: NotificationStore;
  notificationRepository: NotificationRepository;
  dev: DODevTools;
  commentReactionRepository: CommentReactionRepository;
  commentRepository: CommentRepository;
  usageStatisticsStore: UsageStatisticsStore;
  msApiClient: MSApiClient;
  syncOperationsRepository: SyncOperationsRepository;
  userSettingsRepository: UserSettingsRepository;
  pbcPromptController: PBCPromptController;
  contentKeysRepository: ContentKeysRepository;
  dailyPromptStore: DailyPromptStore;
  dailyPromptRepository: DailyPromptRepository;
  searchRepository: SearchRepository;
  mainServerAPIRepository: LoginAPICaller;
  supportFormAPICaller: SupportFormAPICaller;
  journalPresetController: JournalPresetController;
  journalPresetRepository: JournalPresetRepository;
  syncTelemetryRepository: SyncTelemetryRepository;
  aiController: AIController;
  wpConnectApi: WPConnectAPIClient;
  importExportRepository: ImportExportRepository;
  chatAPICaller: DailyChatAPICaller;
};

export function constructD1Classes(dbName?: string) {
  // By default each test should have it's own clean database
  if (process.env.NODE_ENV === "test" && !dbName) {
    dbName = "test-db-" + uuid();
  }

  const db = new DODexie(dbName);
  addSearchWorkerHooks(db);
  const importExportRepository = new ImportExportRepository(db);
  const searchRepository = new SearchRepository(db);
  const keyValueStore = new KeyValueStore(db);
  const secureKeyValueStore = new SecureKeyValueStore(db);
  const fetchWrapper = new FetchWrapper(keyValueStore, secureKeyValueStore);

  const mainServerAPIRepository = new LoginAPICaller(fetchWrapper);
  const syncStateRepository: SyncStateRepository = new SyncStateRepository(
    db,
    keyValueStore,
  );

  const journalParticipantRepository: JournalParticipantRepository =
    new JournalParticipantRepository(db, fetchWrapper);

  const userRepository: UserRepository = new UserRepository(
    db,
    fetchWrapper,
    secureKeyValueStore,
    keyValueStore,
    journalParticipantRepository,
  );
  const userKeysRepository: UserKeysRepository = new UserKeysRepository(
    db,
    userRepository,
  );

  const contentKeysRepository: ContentKeysRepository =
    new ContentKeysRepository(
      db,
      syncStateRepository,
      userRepository,
      userKeysRepository,
      fetchWrapper,
    );
  const userKeysStore: UserKeysStore = new UserKeysStore(
    userKeysRepository,
    secureKeyValueStore,
    contentKeysRepository,
  );

  const dailyPromptRepository: DailyPromptRepository =
    new DailyPromptRepository(fetchWrapper);
  const dailyPromptStore: DailyPromptStore = new DailyPromptStore(
    db,
    dailyPromptRepository,
    keyValueStore,
  );

  const userStore: UserStore = new UserStore(
    userRepository,
    userKeysStore,
    dailyPromptStore,
  );
  const vaultRepository: VaultRepository = new VaultRepository(
    db,
    secureKeyValueStore,
  );
  const decryptionService: DecryptionService = new DecryptionService(
    userRepository,
    userKeysRepository,
    vaultRepository,
    contentKeysRepository,
  );

  const journalStatsRepository: JournalStatsRepository =
    new JournalStatsRepository(db, syncStateRepository);

  const outbox: Outbox = new Outbox(db, keyValueStore, journalStatsRepository);

  const sharedJournalRepository: SharedJournalRepository =
    new SharedJournalRepository(db, fetchWrapper);

  const journalRepository: JournalRepository = new JournalRepository(
    db,
    syncStateRepository,
    vaultRepository,
    decryptionService,
    keyValueStore,
    journalParticipantRepository,
    userKeysStore,
    journalStatsRepository,
  );

  const commentReactionRepository: CommentReactionRepository =
    new CommentReactionRepository(db, fetchWrapper);

  const commentRepository: CommentRepository = new CommentRepository(
    db,
    fetchWrapper,
    syncStateRepository,
    outbox,
    journalRepository,
    vaultRepository,
    commentReactionRepository,
  );

  const momentRepository: MomentRepository = new MomentRepository(
    db,
    decryptionService,
    userRepository,
    vaultRepository,
    outbox,
    keyValueStore,
    journalRepository,
    fetchWrapper,
  );

  const journalCoverRepository = new JournalCoverRepository(
    db,
    userRepository,
    journalRepository,
    vaultRepository,
    momentRepository,
  );

  const templateRepository = new TemplateRepository(
    db,
    decryptionService,
    syncStateRepository,
    userKeysStore,
    outbox,
    fetchWrapper,
    userRepository,
  );

  const tagRepository = new TagRepository(db);

  const reactionRepository = new ReactionRepository(db, fetchWrapper);
  const reactionStore = new ReactionStore(
    reactionRepository,
    commentReactionRepository,
  );

  const notificationRepository = new NotificationRepository(
    db,
    fetchWrapper,
    syncStateRepository,
    userKeysStore,
    journalRepository,
  );
  const notificationStore = new NotificationStore(notificationRepository);

  const momentStore: MomentStore = new MomentStore(momentRepository, outbox);

  const syncOperationsRepository = new SyncOperationsRepository(db);

  const msApiClient = makeMSApiClient(secureKeyValueStore);

  const userSettingsRepository = new UserSettingsRepository(
    userStore,
    keyValueStore,
    syncStateRepository,
    fetchWrapper,
  );

  const entryRepository: EntryRepository = new EntryRepository(
    db,
    syncStateRepository,
    momentRepository,
    vaultRepository,
    journalRepository,
    journalStatsRepository,
    decryptionService,
    templateRepository,
    tagRepository,
    fetchWrapper,
    userRepository,
    syncOperationsRepository,
    userSettingsRepository,
    keyValueStore,
  );
  addJournalHooks(db, entryRepository);
  const entryStore: EntryStore = new EntryStore(
    entryRepository,
    momentStore,
    momentRepository,
    () => journalStore,
    outbox,
    syncStateRepository,
  );
  const templateStore: TemplateStore = new TemplateStore(
    userStore,
    templateRepository,
    userKeysStore,
  );

  const journalPresetAPICaller = new JournalPresetAPICaller(
    fetchWrapper,
    syncStateRepository,
    Sentry,
  );

  const journalPresetRepository = new JournalPresetRepository(
    db,
    syncStateRepository,
  );
  const journalPresetController: JournalPresetController =
    new JournalPresetController(
      journalPresetAPICaller,
      journalPresetRepository,
    );

  const tagStore: TagStore = new TagStore(tagRepository);

  const journalStore: JournalStore = new JournalStore(
    journalRepository,
    journalStatsRepository,
    journalCoverRepository,
    sharedJournalRepository,
    entryRepository,
    momentRepository,
    userStore,
    userKeysStore,
    templateStore,
    journalPresetController,
    syncStateRepository,
    outbox,
    tagRepository,
    reactionRepository,
  );

  const usageStatisticsStore = new UsageStatisticsStore(
    fetchWrapper,
    userSettingsRepository,
  );

  const pbcTemplateRepository = new PBCTemplateRepository(db);

  const pbcTemplateAPICaller = new PBCTemplateAPICaller(
    fetchWrapper,
    syncStateRepository,
    Sentry,
  );

  const pbcTemplateController = new PBCTemplateController(
    pbcTemplateAPICaller,
    pbcTemplateRepository,
  );

  const pbcPromptRepository = new PBCPromptRepository(db);

  const pbcPromptAPICaller = new PBCPromptAPICaller(
    fetchWrapper,
    syncStateRepository,
    Sentry,
  );

  const pbcPromptController = new PBCPromptController(
    pbcPromptAPICaller,
    pbcPromptRepository,
  );

  const syncTelemetryRepository: SyncTelemetryRepository =
    new SyncTelemetryRepository(
      journalRepository,
      syncStateRepository,
      entryRepository,
      userRepository,
      userKeysStore,
      keyValueStore,
      vaultRepository,
      journalStore,
    );

  const supportFormAPICaller = new SupportFormAPICaller(fetchWrapper, Sentry);
  const wpConnectApi = new WPConnectAPIClient(fetchWrapper);

  const aiController = new AIController(new AIAPICaller(fetchWrapper, Sentry));

  // Create Chat API caller and controller (using in-memory approach)
  const chatAPICaller = new DailyChatAPICaller(fetchWrapper);

  const x: D1Classes = {
    db,
    fetchWrapper,
    keyValueStore,
    secureKeyValueStore,
    outbox,
    userRepository,
    userStore,
    userKeysRepository,
    vaultRepository,
    decryptionService,
    syncStateRepository,
    journalRepository,
    sharedJournalRepository,
    journalStatsRepository,
    journalParticipantRepository,
    momentRepository,
    momentStore,
    syncOperationsRepository,
    entryRepository,
    entryStore,
    userKeysStore,
    journalStore,
    templateStore,
    templateRepository,
    tagStore,
    tagRepository,
    reactionStore,
    reactionRepository,
    dev: new DODevTools(),
    notificationStore,
    notificationRepository,
    commentReactionRepository,
    commentRepository,
    usageStatisticsStore,
    msApiClient,
    userSettingsRepository,
    pbcTemplateController,
    pbcPromptController,
    contentKeysRepository,
    dailyPromptStore,
    dailyPromptRepository,
    searchRepository,
    journalCoverRepository,
    mainServerAPIRepository,
    supportFormAPICaller,
    journalPresetRepository,
    journalPresetController,
    syncTelemetryRepository,
    wpConnectApi,
    importExportRepository,
    aiController,
    chatAPICaller,
  };
  return x;
}

export const d1Classes = constructD1Classes();
