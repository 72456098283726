import { useBlockProps } from "@wordpress/block-editor";
import { CSSProperties } from "react";
import { useEffect, useRef, useState } from "react";

import { ImageBlockEditProps } from "@/components/Editor/blocks/constants";
import { FailedMedia } from "@/components/Editor/components/FailedMedia";
import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { UploadingMediaIcon } from "@/components/Editor/components/UploadingMediaIcon";
import { useMediaUpload } from "@/components/Editor/hooks/mediaUpload";
import { FullImage } from "@/components/SyncableImage/FullImage";
import { TopRightButtonWrapper } from "@/components/SyncableImage/TopRightButton";
import { ImageErrorStates } from "@/components/SyncableImage/utils";
import { useMoment } from "@/data/hooks/moments";
import { MomentModel } from "@/data/models/MomentModel";
import { useElementSize } from "@/hooks/useElementSize";
import { useIsEntryPreview } from "@/hooks/useIsEntryPreview";
import { dayOneBlue } from "@/styles/theme";
import { rotateCounterClockwise } from "@/utils/file-helper";
import { getMediaWidth } from "@/utils/gallery";
import {
  activeEntryViewState,
  lightboxViewState,
  primaryViewState,
  viewStates,
} from "@/view_state/ViewStates";

type BlobImageProps = {
  journalId: string;
  entryId: string;
  clientId: string;
  aspectRatio?: number;
  galleryWidth?: number;
  heightInGallery?: number;
  isSelected: boolean;
  blockClientId: string;
  setAttributes: ImageBlockEditProps["setAttributes"];
};

export const SyncableImage: React.FC<BlobImageProps> = ({
  journalId,
  entryId,
  clientId,
  aspectRatio,
  heightInGallery,
  isSelected,
  blockClientId,
  setAttributes,
}) => {
  const { blob, thumbnailBlob, moment, isLoading } = useMoment(
    journalId,
    entryId,
    clientId,
  );
  const [objectURL, setObjectURL] = useState<string>("");
  const [imageErrorState, setImageErrorState] =
    useState<ImageErrorStates>("NO_ERROR");

  const blockProps = useBlockProps({
    style: {
      position: "relative",
      border: "none",
    } as CSSProperties,
  });

  const { isEntryPreview } = useIsEntryPreview();
  const imageRef = useRef<HTMLImageElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const { height: viewPortHeight, width: viewPortWidth } =
    useElementSize("main");
  const isInGallery = !!(aspectRatio && heightInGallery);

  const globalEntryID = primaryViewState.selectedGlobalEntryID;
  const entryJournal =
    primaryViewState.selectedJournal ??
    primaryViewState.getJournalById(globalEntryID?.journal_id);
  const isLightboxOpen = !!lightboxViewState.openedByMomentId;

  const { updateEditMedia } = useMediaUpload(
    globalEntryID,
    viewStates,
    entryJournal?.is_shared || false,
  );

  const rotateLeft = async () => {
    if (!moment) {
      return;
    }
    const editedImage = await rotateCounterClockwise(objectURL, moment);

    if (!isInGallery) {
      updateEditMedia(editedImage, moment.id, setAttributes);
    } else {
      updateEditMedia(editedImage, moment.id, undefined, blockClientId);
    }
  };

  const toggleLightbox = (isOpen: boolean) => {
    lightboxViewState.openByMomentId(isOpen ? moment?.id : undefined);
  };

  useEffect(() => {
    if (isLightboxOpen) {
      toggleLightbox(false);
    }
  }, [viewPortHeight, viewPortWidth]);

  useEffect(() => {
    let done = false;
    let url = "";

    if (!done) {
      if (blob) {
        setImageErrorState("NO_ERROR");
        url = URL.createObjectURL(blob);
        activeEntryViewState.addMediaItem({
          moment: moment as MomentModel,
          url,
          status: "NO_ERROR",
        });
        done = true;
      } else if (thumbnailBlob) {
        setImageErrorState("LOW_RES");
        url = URL.createObjectURL(thumbnailBlob);
        activeEntryViewState.addMediaItem({
          moment: moment as MomentModel,
          url,
          status: "LOW_RES",
        });
      } else {
        setImageErrorState("NOT_FOUND");
      }

      if (url != "" && url != null) {
        setObjectURL(url);
      }
    }
    return () => {
      if (done) {
        URL.revokeObjectURL(url);
        activeEntryViewState.removeMediaItem(url);
      }
      done = true;
    };
  }, [blob, thumbnailBlob]);

  // no objectURL yet
  if (isLoading) {
    if (isInGallery) {
      return (
        <LoadingMediaPlaceholder
          styles={{
            flexBasis: 0,
            flexGrow: aspectRatio,
            aspectRatio,
            height: heightInGallery,
            my: 0,
          }}
        />
      );
    }

    if (moment) {
      return (
        <LoadingMediaPlaceholder
          key={moment.id}
          height={moment.height || undefined}
          width={moment.width || undefined}
        />
      );
    }

    return <LoadingMediaPlaceholder />;
  }

  if (moment && objectURL) {
    const width = isInGallery
      ? `${getMediaWidth(aspectRatio, heightInGallery)}px`
      : undefined;

    const height = isInGallery ? `${heightInGallery}px` : "auto";

    return (
      <div
        {...blockProps}
        data-testid="inline-image"
        data-momentid={moment.id}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        sx={{
          position: "relative",
          display: "inline-block",
          width,
          height,
          boxShadow: isInGallery
            ? `0 0 0 1px ${isSelected ? dayOneBlue : "transparent"}`
            : undefined,
          "& img": {
            p: "1px",
            borderRadius: isInGallery ? undefined : "3px",
            border: isInGallery ? undefined : "3px solid",
            borderColor: isSelected ? dayOneBlue : "transparent",
            width,
            height,
          },
        }}
      >
        {imageErrorState === "NOT_FOUND" ? (
          <div>
            <FailedMedia icon="photo" moment={moment} />
          </div>
        ) : (
          <FullImage
            src={objectURL}
            height={moment.height || undefined}
            width={moment.width || undefined}
            ref={imageRef}
            onClick={() => {
              if (isEntryPreview) return;
              toggleLightbox(true);
            }}
          />
        )}

        <UploadingMediaIcon moment={moment}></UploadingMediaIcon>
        <TopRightButtonWrapper
          blockClientId={blockClientId}
          moment={moment}
          imageErrorState={imageErrorState}
          showButton={isHovered}
          rotateLeft={rotateLeft}
        />
      </div>
    );
  }

  if (isInGallery) {
    return (
      <div {...blockProps}>
        <FailedMedia moment={moment} icon="photo" />
      </div>
    );
  }

  return <FailedMedia moment={moment} icon="photo" />;
};
