import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { ActivityChoiceModal } from "@/components/Editor/components/MetadataModal/ActivityChoiceModal";
import { Map } from "@/components/Editor/components/MetadataModal/Map";
import { MetadataButton } from "@/components/Editor/components/MetadataModal/MetadataButton";
import { MetadataDivider } from "@/components/Editor/components/MetadataModal/MetadataDivider";
import { MetadataRow } from "@/components/Editor/components/MetadataModal/MetadataRow";
import { SingleEntryTagManagerModal } from "@/components/Editor/components/TagManagerModal";
import { DateTimePicker } from "@/components/Form/DateTimePicker";
import { Icon } from "@/components/Icon";
import { dateTimeToString, entryDateNumberToString } from "@/utils/date-helper";
import { formatDistance } from "@/utils/distance";
import {
  getActivityIconName,
  getMoonIconName,
  getWeatherIconName,
} from "@/utils/icons";
import { getLocationString } from "@/utils/location";
import { getMoonPhaseText } from "@/utils/moon-phases";
import { getTemperatureString } from "@/utils/temperature-helper";
import { ActiveEntryViewState } from "@/view_state/ActiveEntryViewState";
import { userSettingsViewState } from "@/view_state/ViewStates";

type Props = {
  handleClose: () => void;
  entryId: string;
  journalId: string;
  activeEntryViewState: ActiveEntryViewState;
};

export const MetadataModal: React.FC<Props> = observer(
  ({ handleClose, activeEntryViewState, entryId, journalId }) => {
    const { __ } = useI18n();
    const dateRef = useRef<HTMLDivElement>(null);
    const [showTagManager, setShowTagManager] = useState(false);
    const [showEditDate, setShowEditDate] = useState(false);
    const [showActivityChoice, setShowActivityChoice] = useState(false);
    const entry = activeEntryViewState.entryModel;
    const userCanEdit = activeEntryViewState.userCanEdit;

    const location = getLocationString(entry?.location);
    const showLocation = location || entry?.location?.placeName;
    const stats = `${activeEntryViewState.counts?.words || 0} ${__("Words")}, ${activeEntryViewState.counts?.characters || 0} ${__("Characters")}, ${activeEntryViewState.momentCount} ${__("Media")}`;

    const creationDevice = entry?.clientMeta
      ? `${entry.clientMeta.creationDevice}, ${entry.clientMeta.creationDeviceType}, ${entry.clientMeta.creationOSName} ${entry.clientMeta.creationOSVersion}`
      : "";
    const unit =
      userSettingsViewState.temperatureUnit === "C" ? "metric" : "imperial";
    const altitude = entry?.location?.altitude
      ? formatDistance(entry?.location?.altitude, unit, __, true)
      : "";
    const moonPhase = entry?.weather?.moonPhase;
    const moonPhaseCode = entry?.weather?.moonPhaseCode;
    const moonPhaseIcon = getMoonIconName(moonPhaseCode);
    const moonPhaseText = getMoonPhaseText(moonPhase, moonPhaseCode);

    const temperature = entry
      ? getTemperatureString(
          entry.weather,
          userSettingsViewState.temperatureUnit,
        )
      : "";

    const weatherIcon = getWeatherIconName(entry?.weather?.code);

    const tagData = userCanEdit ? (
      <MetadataButton
        onClick={() => {
          analytics.tracks.recordEvent(EVENT.buttonTap, {
            button_identifier: "entryMetadata_tagsCell",
          });
          setShowTagManager(true);
        }}
        text={__("Add...")}
      />
    ) : (
      ""
    );

    const activityData = userCanEdit ? (
      <MetadataButton
        onClick={() => {
          analytics.tracks.recordEvent(EVENT.buttonTap, {
            button_identifier: "entryMetadata_activityCell",
          });
          setShowActivityChoice(true);
        }}
        text={__("Add...")}
      />
    ) : (
      ""
    );

    const showActivity =
      userCanEdit || (entry?.activity !== undefined && entry?.activity !== "");

    useEffect(() => {
      analytics.tracks.recordEvent(EVENT.screenView, {
        screen: "entryMetadata",
      });
    }, []);

    return (
      <>
        <D1Modal
          title={__("Entry Info")}
          onRequestClose={handleClose}
          sx={{
            width: ["100%", "100%", "420px"],
            "& .components-modal__content": {
              pt: 2,
            },
          }}
        >
          {showLocation && (
            <MetadataRow
              icon={<Icon icon="map-pin" />}
              title={location}
              data={entry?.location?.placeName || ""}
            />
          )}
          {entry?.location && <Map location={entry.location} />}

          {!!entry?.duration && (
            <MetadataRow
              icon={<Icon icon="clock" />}
              title={__("Visit Duration")}
              data={`${entry?.duration}`}
            />
          )}

          {showLocation || !!entry?.duration ? <MetadataDivider /> : null}
          <MetadataRow
            icon={<Icon icon="tag" />}
            title={__("Tags")}
            data={activeEntryViewState.tags.join(", ") || tagData}
            edit={
              userCanEdit
                ? () => {
                    analytics.tracks.recordEvent(EVENT.buttonTap, {
                      button_identifier: "entryMetadata_tagsCell",
                    });
                    setShowTagManager(true);
                  }
                : undefined
            }
          />
          <MetadataRow
            icon={<Icon icon="bookmark" />}
            title={__("Journal")}
            data={activeEntryViewState.selectedJournal?.name || ""}
          />
          <div ref={dateRef} />
          <MetadataRow
            icon={<Icon icon="clock" />}
            title={__("Date")}
            data={entry ? entryDateNumberToString(entry) : ""}
            edit={
              userCanEdit
                ? () => {
                    analytics.tracks.recordEvent(EVENT.buttonTap, {
                      button_identifier: "entryMetadata_dateCell",
                    });
                    setShowEditDate(true);
                  }
                : undefined
            }
          />
          <MetadataRow
            icon={<Icon icon="clock" />}
            title={__("Last Edited")}
            data={
              entry
                ? dateTimeToString(new Date(entry.editDate), entry.timeZone)
                : ""
            }
          />
          {entry && entry.editingTime > 0 && (
            <MetadataRow
              icon={<Icon icon="clock" />}
              title={__("Edit Duration")}
              data={`${Math.round(entry.editingTime)}${__("s")}`}
            />
          )}
          <MetadataDivider />
          {temperature && weatherIcon && (
            <MetadataRow
              icon={<Icon icon={weatherIcon} />}
              title={__("Weather")}
              data={temperature}
            />
          )}
          {moonPhaseText && moonPhaseIcon && (
            <MetadataRow
              icon={<Icon icon={moonPhaseIcon} />}
              title={__("Moon Phase")}
              data={moonPhaseText}
            />
          )}
          {creationDevice && (
            <MetadataRow
              icon={<Icon icon="device-phone" />}
              title={__("Entry Creation Device")}
              data={creationDevice}
            />
          )}

          <MetadataRow
            icon={<Icon icon="notes" />}
            title={__("Stats")}
            data={stats}
          />
          {userCanEdit && (
            <MetadataRow
              icon={<Icon icon="heart" />}
              title={__("Favorite")}
              data={
                <MetadataButton
                  onClick={() => {
                    analytics.tracks.recordEvent(EVENT.buttonTap, {
                      button_identifier: "entryMetadata_favoriteCell",
                    });
                    activeEntryViewState.toggleFavorite();
                  }}
                  text={
                    activeEntryViewState.entryModel?.isStarred
                      ? __("Unfavorite")
                      : __("Mark as favorite")
                  }
                />
              }
            />
          )}

          <MetadataRow
            icon={<Icon icon="link" />}
            title={__("Entry ID")}
            data={entryId}
          />
          {altitude && (
            <MetadataRow
              icon={<Icon icon="mountain" />}
              title={__("Altitude")}
              data={altitude}
            />
          )}

          {showActivity && (
            <MetadataRow
              icon={<Icon icon={getActivityIconName(entry?.activity || "")} />}
              title={__("Activity")}
              data={entry?.activity || activityData}
              edit={
                userCanEdit
                  ? () => {
                      analytics.tracks.recordEvent(EVENT.buttonTap, {
                        button_identifier: "entryMetadata_activityCell",
                      });
                      setShowActivityChoice(true);
                    }
                  : undefined
              }
            />
          )}

          {entry?.steps && (
            <MetadataRow
              icon={<Icon icon="shoe" />}
              title={__("Step Count")}
              data={`${entry.steps.stepCount}`}
            />
          )}

          {entry?.music && (
            <MetadataRow
              icon={<Icon icon="music" />}
              title={__("Music")}
              data={`${entry.music.artist} - ${entry.music.track}`}
            />
          )}
        </D1Modal>
        {showTagManager && (
          <SingleEntryTagManagerModal
            globalEntryID={{
              id: entryId,
              journal_id: journalId,
            }}
            onClose={() => setShowTagManager(false)}
            onChangeTags={activeEntryViewState.saveTagsOnly}
          />
        )}
        {showEditDate && entry && (
          <DateTimePicker
            initialDate={new Date(entryDateNumberToString(entry, "en-US"))}
            isAllDay={entry.isAllDay}
            changeDate={activeEntryViewState.changeDateHandler}
            anchorRef={dateRef}
            handleFocusOutside={() => setShowEditDate(false)}
            placement="right"
          />
        )}
        {showActivityChoice && entry && (
          <ActivityChoiceModal
            update={activeEntryViewState.updateActivity}
            handleClose={() => setShowActivityChoice(false)}
            entry={entry}
          />
        )}
      </>
    );
  },
);
