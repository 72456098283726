import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { TagDBRow } from "@/data/db/migrations/tag";
import { TagRepository } from "@/data/repositories/TagRepository";

export type TagCount = [string, number];

export class TagStore {
  constructor(private tagRepository: TagRepository) {}

  async addForEntry(tag: string, globalEntryID: GlobalEntryID) {
    await this.tagRepository.addForEntry(tag, globalEntryID);
    analytics.tracks.recordEvent(EVENT.tagAdded, {
      entry_id: globalEntryID.id,
    });
  }

  async bulkAddForEntry(tags: string[], globalEntryID: GlobalEntryID) {
    return await this.tagRepository.addMultipleTags(
      tags.map((tag) => ({
        tag,
        journal_id: globalEntryID.journal_id,
        entry_id: globalEntryID.id,
      })),
    );
  }

  async addForMultipleEntries(tags: TagDBRow[]) {
    await this.tagRepository.addMultipleTags(tags);
    for (const tag of tags) {
      analytics.tracks.recordEvent(EVENT.tagAdded, {
        entry_id: tag.entry_id,
      });
    }
  }

  async removeForEntry(tag: string, globalEntryID: GlobalEntryID) {
    await this.tagRepository.removeForEntry(tag, globalEntryID);
    analytics.tracks.recordEvent(EVENT.tagRemoved, {
      entry_id: globalEntryID.id,
    });
  }

  async removeForMultipleEntries(tags: TagDBRow[]) {
    await this.tagRepository.bulkRemoveForEntry(tags);
    for (const tag of tags) {
      analytics.tracks.recordEvent(EVENT.tagRemoved, {
        entry_id: tag.entry_id,
      });
    }
  }

  subscribeToAll(callback: (tags: TagDBRow[]) => void) {
    return this.tagRepository.subscribeToAll(callback);
  }

  subscribeToTagCountByJournal(
    callback: (count: number) => void,
    journalId: string,
  ) {
    return this.tagRepository.subscribeToTagCountByJournal(callback, journalId);
  }
}
