import { makeAutoObservable } from "mobx";

import {
  ENABLE_DAILY_CHAT,
  ENABLE_MULTI_ENTRY_MENU,
  ENABLE_PASSKEYS,
  PREFILLED_ENTRY_URLS,
  READ_CONTENTS_AS_MARKDOWN,
  SHOW_ENCRYPTED_INDICATOR,
  SHOW_HOTKEY_GUIDE,
  SHOW_IMPORT_EXPORT,
  SHOW_PROFILE_SHARING_SETTING,
  SHOW_SAVE_TEMPLATE,
  SHOW_SERVER_SIDE_MOVE,
  SHOW_WP_CONNECT_FEATURE_FLAG_NAME,
  ValidFeatureFlagKey,
} from "@/components/Settings/constants";
import { ServerFlagDBRow } from "@/data/db/migrations/server_flags";
import { UserRepository } from "@/data/repositories/UserRepository";
import {
  FeatureFlag,
  UserSettingsRepository,
} from "@/data/repositories/UserSettingsRepository";
import { isFlagEnabled } from "@/data/utils/serverFlags";
import { isDevelopment } from "@/utils/environment";
import { isDeepEqual } from "@/utils/is-equal";

export class FeatureFlagsViewState {
  webFeatureFlags: FeatureFlag[] = [];
  serverFlags: ServerFlagDBRow[] = [];
  failEntryTransfers = false;

  constructor(
    private userSettingsRepo: UserSettingsRepository,
    private userRepository: UserRepository,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.startSubscriptions();
  }

  startSubscriptions() {
    this.userSettingsRepo.subscribe((settings) => {
      if (settings) {
        this.gotNewFeatureFlags(settings.feature_flags);
      }
    });
    this.userRepository.subscribeToAllServerFeatureFlags((flags) => {
      if (flags?.length > 0) {
        this.gotNewServerFlags(flags);
      }
    });
  }

  public setFeatureFlag(name: ValidFeatureFlagKey, enabled: boolean) {
    this.userSettingsRepo.setFeatureFlag(name, enabled);
  }

  private gotNewFeatureFlags(featureFlags: FeatureFlag[]) {
    if (!isDeepEqual(this.webFeatureFlags, featureFlags)) {
      this.webFeatureFlags = featureFlags;
    }
  }

  private gotNewServerFlags(flags: ServerFlagDBRow[]) {
    if (!isDeepEqual(this.serverFlags, flags)) {
      this.serverFlags = flags;
    }
  }

  get labServerFlags() {
    return this.serverFlags.filter((f) => f.is_labs_feature);
  }

  public getServerFeatureFlag(id: string) {
    const featureFlag = this.serverFlags.find((f) => f.id === id);
    return isFlagEnabled(featureFlag);
  }

  private getFeatureFlag(name: ValidFeatureFlagKey) {
    const featureFlag = this.webFeatureFlags.find((f) => f.name === name);
    if (featureFlag) {
      return featureFlag.enabled;
    }
    return false;
  }

  getFlagByName(name: ValidFeatureFlagKey) {
    return this.webFeatureFlags.find((f) => f.name === name);
  }

  // For testing failed Entry transfers
  async toggleShouldFailEntryTransfers() {
    this.failEntryTransfers = !this.failEntryTransfers;
  }

  get shouldFailEntryTransfers() {
    if (!isDevelopment()) {
      return false;
    }
    return this.failEntryTransfers;
  }

  // Please make specific actions for each feature flag you want to
  // use below for the sake of clean logs and performance.
  get readContentsAsMarkdown() {
    return this.getFeatureFlag(READ_CONTENTS_AS_MARKDOWN);
  }

  get prefilledEntryURLs() {
    return this.getFeatureFlag(PREFILLED_ENTRY_URLS);
  }

  get showProfileSharingSetting() {
    return this.getFeatureFlag(SHOW_PROFILE_SHARING_SETTING);
  }

  get enablePasskeys() {
    return this.getFeatureFlag(ENABLE_PASSKEYS);
  }

  get showServerSideMove() {
    return this.getFeatureFlag(SHOW_SERVER_SIDE_MOVE);
  }

  get showSaveTemplate() {
    return this.getFeatureFlag(SHOW_SAVE_TEMPLATE);
  }

  get showImportExport() {
    return this.getFeatureFlag(SHOW_IMPORT_EXPORT);
  }

  get showEncryptedIndicator() {
    return this.getFeatureFlag(SHOW_ENCRYPTED_INDICATOR);
  }

  get showWPConnect() {
    return this.getFeatureFlag(SHOW_WP_CONNECT_FEATURE_FLAG_NAME);
  }

  get showHotkeyGuide() {
    return this.getFeatureFlag(SHOW_HOTKEY_GUIDE);
  }

  get enableMultiEntryMenu() {
    return this.getServerFeatureFlag(ENABLE_MULTI_ENTRY_MENU);
  }

  get enableDailyChat() {
    return this.getFeatureFlag(ENABLE_DAILY_CHAT);
  }
}
