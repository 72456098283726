import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { MenuButton } from "@/components/Editor/components/MenuButton";
import { SearchBox } from "@/components/SearchBox";
import { NoEncryptionInfo } from "@/components/Templates/NoEncryptionInfo";
import { TemplateMenuItem } from "@/components/Templates/TemplateMenuItem";
import {
  PersonalTemplate,
  SelectedTemplate,
  TemplateDBRow,
} from "@/data/db/migrations/template";

type MyTemplatesListProps = {
  modalHeight?: number;
  templates: TemplateDBRow[];
  handleClick: (selected: SelectedTemplate) => void;
  activeTemplate: PersonalTemplate;
  canEncrypt: boolean;
};

export const MyTemplatesList: React.FC<MyTemplatesListProps> = ({
  modalHeight = 0,
  templates,
  handleClick,
  activeTemplate,
  canEncrypt,
}) => {
  const [search, setSearch] = useState<string | null>(null);
  const { __ } = useI18n();
  return (
    <>
      <SearchBox
        value={search}
        setInputValue={(value: string | null) => setSearch(value)}
        placeholder={__("Search Templates")}
      />
      <div
        sx={{
          overflowY: "scroll",
          height: `calc(${modalHeight}px - 60px - 48px)`,
          p: 3,
          button: {
            textAlign: "left",
          },
          svg: {
            mr: 2,
            flexShrink: 0,
          },
          "& .is-active": {
            bg: "surfaceActive",
            color: "textPrimary",
          },
        }}
      >
        {templates
          .filter((t) => {
            if (!search) {
              return true;
            }
            return t.title
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase());
          })
          .map((template) => {
            return (
              <MenuButton
                key={template.id}
                onClick={() => {
                  handleClick({ ...template, type: "mine" });
                }}
                isActive={template.clientId === activeTemplate?.clientId}
                title={template.title}
              >
                <TemplateMenuItem.Default icon="template-add">
                  {template.title}
                </TemplateMenuItem.Default>
              </MenuButton>
            );
          })}
        {!canEncrypt && <NoEncryptionInfo />}
        {canEncrypt && (
          <MenuButton
            key="new-template"
            onClick={() => {
              handleClick({
                id: "",
                journalName: "",
                journalSyncID: "",
                order: 0,
                richText: "",
                tags: [],
                title: __("New Template"),
                type: "new",
                clientId: "",
              });
            }}
          >
            <TemplateMenuItem.Default icon="plus">
              {__("New Template")}
            </TemplateMenuItem.Default>
          </MenuButton>
        )}
      </div>
    </>
  );
};
