import { useEffect, useState } from "react";

import { isWindows } from "@/utils/environment";

export const useIsWindows = () => {
  const [windows, setWindows] = useState(false);

  useEffect(() => {
    isWindows().then((result) => {
      setWindows(result);
    });
  }, []);

  return windows;
};
