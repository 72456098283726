import { Button } from "@wordpress/components";

import { Icon, IconFont } from "@/components/Icon";

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  isActive?: boolean;
  isDestructive?: boolean;
  disabled?: boolean;
  title?: string;
  icon?: IconFont;
}

export const MenuButton: React.FC<Props> = ({
  onClick,
  children,
  className,
  isActive = false,
  disabled = false,
  isDestructive = false,
  icon,
  title,
}) => {
  return (
    <Button
      sx={{
        "&&.components-button": {
          fontSize: 1,
          px: 2,
          width: "100%",
          textWrap: "nowrap",
          color: isDestructive ? "red" : "textSecondary",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          ...(!disabled && {
            ":hover": {
              bg: "surfaceHover",
              color: "textPrimary",
            },
            ":active": {
              bg: "surfaceActive",
              color: "textPrimary",
            },
          }),
        },
      }}
      className={`${className ? className : ""}${isActive ? "is-active" : ""}`}
      autoFocus={false}
      onClick={onClick}
      title={title}
      disabled={disabled}
    >
      {children} {icon && <Icon icon={icon} size={4} />}
    </Button>
  );
};
