import { createInterpolateElement } from "@wordpress/element";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { Link } from "wouter";

import { d1Classes } from "@/D1Classes";
import { GroupHeading, ItemGroup } from "@/components/ItemGroup";
import { Apple } from "@/components/Settings/Account/Apple";
import { Avatar as NewAvatar } from "@/components/Settings/Account/Avatar/Avatar";
import { DayOneID } from "@/components/Settings/Account/DayOneID";
import { DeleteAccount } from "@/components/Settings/Account/DeleteAccount";
import { DisplayName } from "@/components/Settings/Account/DisplayName";
import { Email } from "@/components/Settings/Account/Email";
import { EncryptionKey } from "@/components/Settings/Account/EncryptionKey";
import { Google } from "@/components/Settings/Account/Google";
import { PasskeysSettingsRow } from "@/components/Settings/Account/PasskeysSettingsRow";
import { Password } from "@/components/Settings/Account/Password";
import { SharedProfile } from "@/components/Settings/Account/SharedProfile";
import { SignOut } from "@/components/Settings/Account/SignOut";
import { Subscription } from "@/components/Settings/Account/Subscription";
import { Support } from "@/components/Settings/Account/Support";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const Account: React.FC = observer(() => {
  const { __ } = useI18n();

  const user = primaryViewState.user;
  const userStore = d1Classes.userStore;
  const showProfileSharingSetting =
    viewStates.featureFlags.showProfileSharingSetting;
  const storedMasterKeyString = primaryViewState.masterKeyString;
  const hasUserKeys = primaryViewState.userEncryptionKeyExists;

  return (
    <SettingsPanel header={<span>{__("Account")}</span>}>
      <GroupHeading>{__("Profile")}</GroupHeading>
      <ItemGroup>
        <NewAvatar
          key={
            primaryViewState.user ? JSON.stringify(primaryViewState.user) : null
          }
          primaryViewState={primaryViewState}
        />
        <DisplayName />
        <SignOut />
        {primaryViewState.hasSharedJournals && showProfileSharingSetting && (
          <SharedProfile user={user} userStore={userStore} />
        )}
      </ItemGroup>

      <GroupHeading>{__("Email")}</GroupHeading>
      <ItemGroup>
        <Email
          user={user}
          userStore={userStore}
          snackbar={viewStates.snackbar}
        />
      </ItemGroup>

      <GroupHeading>{__("Day One ID")}</GroupHeading>
      <ItemGroup>
        <DayOneID user={primaryViewState.user} />
      </ItemGroup>

      <GroupHeading>{__("Password")}</GroupHeading>
      <ItemGroup>
        <Password />
        <PasskeysSettingsRow />
      </ItemGroup>

      <GroupHeading>{__("Subscription")}</GroupHeading>
      <ItemGroup>
        {" "}
        <Subscription
          userStore={userStore}
          showUpgrade={() => {
            viewStates.modalRouter.showPremiumUpgradeModal(
              "settings_upgrade_button",
            );
          }}
        />
      </ItemGroup>

      <ItemGroup addMargin={true}>
        <Apple />
        <Google />
      </ItemGroup>

      <GroupHeading>{__("Encryption key")}</GroupHeading>
      {!storedMasterKeyString && !hasUserKeys && (
        <p
          sx={{
            fontSize: 0,
            color: "textSecondary",
            lineHeight: "body",
            mb: 2,
            ml: 3,
            "& a": {
              color: "dayOneBlue",
              textDecoration: "underline",
              ":hover": {
                textDecoration: "none",
              },
            },
          }}
        >
          {createInterpolateElement(
            __(
              "Your journal is a sacred space for your innermost thoughts. Protect it by creating an encryption key and enabling end-to-end encryption. <learnMore>Learn more</learnMore>.",
            ),
            {
              learnMore: <Link to="/encryption" />,
            },
          )}
        </p>
      )}
      <ItemGroup>
        <EncryptionKey />
      </ItemGroup>

      <GroupHeading>{__("Support")}</GroupHeading>
      <ItemGroup>
        <Support />
      </ItemGroup>

      <GroupHeading>{__("Delete account")}</GroupHeading>
      <ItemGroup>
        <DeleteAccount />
      </ItemGroup>
    </SettingsPanel>
  );
});

Account.displayName = "Account";
