import { ThemeUICSSObject } from "@theme-ui/css";
import { BlockMover } from "@wordpress/block-editor";
import { Toolbar, ToolbarGroup, createSlotFill } from "@wordpress/components";
import { observer } from "mobx-react-lite";

import { FileUploadMessagesModal } from "@/components/Editor/components/FileUploadMessagesModal";
import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { FormattingMenuDivider } from "@/components/Editor/components/FormattingMenuDivider";
import { HeadingDropdown } from "@/components/Editor/components/HeadingDropdown";
import { ShareButton } from "@/components/Editor/components/ShareButton";
import { TagManagerButton } from "@/components/Editor/components/TagManagerButton";
import { useBlockSelection } from "@/components/Editor/hooks/blockSelection";
import { useMediaUpload } from "@/components/Editor/hooks/mediaUpload";
import { ListTransform } from "@/components/Editor/transforms/ListTransform";
import { QuoteTransform } from "@/components/Editor/transforms/QuoteTransform";
import { areMediaBlocksRegistered } from "@/components/Editor/utils/register-blocks";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { Z_INDEX_EDITOR_PANEL_TOOLBAR } from "@/styles/theme";
import { ViewStates } from "@/view_state/ViewStates";

export const FORMAT_TOOLBAR_SLOT_FILL = createSlotFill("dayone/format-toolbar");
export const TOOLBAR_HEIGHT = "48px";

const toolbarStyle: ThemeUICSSObject = {
  border: "none",
  paddingX: 3,

  background: "surface_toolbar",

  alignItems: "center",
  zIndex: Z_INDEX_EDITOR_PANEL_TOOLBAR,

  height: TOOLBAR_HEIGHT,

  width: "100%",
  overflowX: "scroll",
  overflowY: "hidden",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },

  "&&& .components-toolbar-group": {
    borderRight: "none !important",
    background: "transparent",
  },

  "&&& .block-editor-block-mover__move-button-container": {
    flexDirection: "column",
    marginRight: 2,
  },

  "&&& .block-editor-block-mover-button:focus::before": {
    boxShadow: "none",
    outline: "none",
    left: "0px",
    right: "0px",
    background: "surface_light3_dark3",
  },

  "&&& .block-editor-block-mover-button": {
    borderRadius: "sm",
    height: "20px",
    minWidth: "0 !important",
    width: "32px",
    "& svg": {
      flexShrink: 0,
    },
    "&:hover": {
      bg: "surface_light3_dark3",
      color: "inherit",
    },
  },
};

const toolbarWrapperStyle: ThemeUICSSObject = {
  background: "none",
  height: TOOLBAR_HEIGHT,
  display: "flex",
  position: "sticky",
  top: 0,
  // To force floating above editor content
  zIndex: Z_INDEX_EDITOR_PANEL_TOOLBAR,
  overflow: "hidden",
  borderBottom: "1px solid",
  borderColor: "borderPrimary",
};

const toolbarGroupStyle: ThemeUICSSObject = {
  border: "none",
  height: 5,
  minHeight: 5,
  background: "none",
  paddingLeft: 0,
  paddingRight: 0,
};

type Props = {
  globalEntryID?: null | GlobalEntryID;
  onChangeTags?: () => void;
  viewStates: ViewStates;
  isEntryShared?: boolean;
  disableMediaUpload?: boolean;
};

export const FixedFormattingToolbar = observer(
  ({
    globalEntryID,
    onChangeTags,
    viewStates,
    isEntryShared,
    disableMediaUpload,
  }: Props) => {
    const { blockClientIds: selectedClientIds } = useBlockSelection();

    const tags = viewStates.activeEntry.tags;

    const allowMediaUpload = areMediaBlocksRegistered();

    const {
      insertMediaWithFilePicker,
      canAddMedia,
      canUpgrade,
      filesMessage,
      resetFilesMessage,
    } = useMediaUpload(globalEntryID ?? null, viewStates, isEntryShared);
    const isFileUploadDisabled =
      (!canAddMedia && !canUpgrade) || !globalEntryID;
    const handleAttachmentClick = canAddMedia
      ? insertMediaWithFilePicker
      : () => {
          if (canUpgrade) {
            viewStates.modalRouter.showPremiumUpgradeModal("filepicker_button");
          }
        };

    return (
      <div sx={toolbarWrapperStyle}>
        <Toolbar
          sx={{
            ...toolbarStyle,

            "&&&& .block-editor-block-mover__move-button-container": {
              "& svg": {
                left: "0px",
                right: "0px",
              },
            },
          }}
          label="Options"
        >
          <BlockMover
            clientIds={selectedClientIds}
            /* @ts-ignore */
            hideDragHandle={true}
          />
          <FormattingMenuDivider />
          {/* Heading transforms */}
          <ToolbarGroup sx={toolbarGroupStyle}>
            <HeadingDropdown />
          </ToolbarGroup>
          <FormattingMenuDivider />
          {/* List transforms */}
          <ToolbarGroup sx={toolbarGroupStyle}>
            <ListTransform isContextMenu={false} listType="unordered" />
            <ListTransform isContextMenu={false} listType="ordered" />
            <ListTransform isContextMenu={false} listType="checklist" />
          </ToolbarGroup>
          <FormattingMenuDivider />
          {/* Other blocks */}
          <ToolbarGroup sx={toolbarGroupStyle}>
            <QuoteTransform />
          </ToolbarGroup>

          {/* Other menu items */}
          {allowMediaUpload && !disableMediaUpload && (
            <>
              <FormattingMenuDivider />
              <ToolbarGroup sx={toolbarGroupStyle}>
                <FixedToolbarButton
                  disabled={isFileUploadDisabled}
                  onClick={handleAttachmentClick}
                  title="Upload media"
                  iconName="attachment"
                />
                {globalEntryID && tags && onChangeTags && (
                  <TagManagerButton
                    onChangeTags={onChangeTags}
                    globalEntryID={globalEntryID}
                  />
                )}
                {viewStates.featureFlags.prefilledEntryURLs && <ShareButton />}
              </ToolbarGroup>
            </>
          )}
        </Toolbar>
        {filesMessage?.length > 0 && (
          <FileUploadMessagesModal
            message={filesMessage}
            onClose={resetFilesMessage}
          />
        )}
      </div>
    );
  },
);
FixedFormattingToolbar.displayName = "FixedFormattingToolbar";
