import { ThemeUICSSObject } from "@theme-ui/css";

export const codeStyles: ThemeUICSSObject = {
  // Scope everything to the block list layout
  ".block-editor-block-list__layout": {
    ".wp-block-code": {
      backgroundColor: "surface_light3_dark3",
      border: "1px solid",
      borderColor: "editorBorderSecondary",
      fontSize: 1,
      my: 4,
    },
    // Inline code styles
    "p, h1, h2, h3, h4, h5, li, .wp-block-dayone-checklist-item span, blockquote":
      {
        code: {
          backgroundColor: "surface_light3_dark3",
          color: "textPrimary",
          fontSize: 1,
          px: 1,
          borderRadius: 2,
          textTransform: "none",
        },
      },
    "h6 code": {
      color: "textPrimary",
      px: 1,
      borderColor: "surface_light3_dark2",
      borderWidth: "1px",
      textTransform: "none",
    },
  },
};
