import { JournalDBRow } from "@/data/db/migrations/journal";

type Props = {
  journal: JournalDBRow;
};

export const SharedInfo: React.FC<Props> = ({ journal }) => {
  const participants = journal.participants.filter(
    (p) => p.role !== "previous",
  ).length;
  return (
    <div
      sx={{
        color: "textSecondary",
        display: "flex",
        gap: 1,
        flexWrap: "nowrap",
        flexShrink: 0,
      }}
    >
      <div sx={{ textWrap: "nowrap" }}>{`${participants} members`} </div>
    </div>
  );
};
