import { BlockInstance } from "@wordpress/blocks";

import { rgbaToHex } from "./formatting";

import { TextNode } from "@/../types/rtj-format";
import { CheckListItem } from "@/components/Editor/blocks/checklist/CheckListItemBlock";
import { getListHTML } from "@/components/Editor/gb2rtj/gb2md";
import { ZERO_WIDTH_NO_BREAK_SPACE } from "@/components/Editor/rtj2gb/text2paragraph";

export const convertGBListToRTJNodes = (block: BlockInstance) => {
  /* ==> v2 List structure
  attributes:
    ordered: true
    values: ""
  [[Prototype]]: Object
  clientId: "05d213c5-3aef-4cb7-b1fc-faabf1cbf67d"
  innerBlocks: Array(3)
  0:
    clientId: "547ef98b-31b7-4657-906b-a1cfa0cda50d"
    innerBlocks: []
    isValid: true
    name: "core/list-item"
      attributes:
          content: "item1"
  */

  const listHTML = getListHTML(block);
  const listNodes = new DOMParser()
    .parseFromString(listHTML, "text/html")
    .querySelector("body")?.childNodes;

  const rtjNodes = parseHTMLList(
    block.attributes.ordered ? "numbered" : "bulleted",
    listNodes ? Array.from(listNodes) : [],
  );

  /* sample rtjNodes output
    0:
      attributes:
        line: {listStyle: 'numbered', indentLevel: 1}
        [[Prototype]]: Object
        text: "item1\n"
        [[Prototype]]: Object
    1:
      attributes:
        line: {listStyle: 'numbered', indentLevel: 1}
        [[Prototype]]: Object
        text: "item2\n"
        [[Prototype]]: Object
  */

  if (rtjNodes.length > 0 && rtjNodes[rtjNodes.length - 1].text) {
    rtjNodes[rtjNodes.length - 1].text += "\n";
  }

  return rtjNodes.map((item) => {
    item.text = item.text.replace(/\n\n$/g, "\n");
    return item;
  });
};

type ListState = {
  currentIndent: number;
  listItems: TextNode[];
  listStyle: "numbered" | "bulleted" | "checkbox";
  currentFormatting: {
    bold?: boolean;
    highlightedColor?: string;
    inlineCode?: boolean;
    italic?: boolean;
    linkURL?: string;
    strikethrough?: boolean;
    underline?: boolean;
  };
};

export const parseHTMLList = (
  initialListStyle: "numbered" | "bulleted" | "checkbox",
  htmlNodes: Node[],
  listState: ListState = {
    listStyle: initialListStyle,
    currentIndent: 0,
    listItems: [],
    currentFormatting: {},
  },
) => {
  const listStyles = [initialListStyle];
  htmlNodes.forEach((node) => {
    switch (node.nodeName) {
      case "LI": {
        const lastItem = listState.listItems[listState.listItems.length - 1];
        if (lastItem) {
          lastItem.text += "\n";
        }
        break;
      }
      case "A":
        listState.currentFormatting.linkURL = (node as HTMLAnchorElement).href;
        break;
      case "STRONG":
        listState.currentFormatting.bold = true;
        break;
      case "EM":
        listState.currentFormatting.italic = true;
        break;
      case "MARK": {
        const bgColor = (node as HTMLElement).style.getPropertyValue(
          "background-color",
        );
        listState.currentFormatting.highlightedColor = rgbaToHex(bgColor);
        break;
      }
      case "S":
        listState.currentFormatting.strikethrough = true;
        break;
      case "CODE":
        listState.currentFormatting.inlineCode = true;
        break;
      case "UL": {
        listStyles.push("bulleted");
        listState.listStyle = "bulleted";
        listState.currentIndent++;
        break;
      }
      case "OL": {
        listStyles.push("numbered");
        listState.listStyle = "numbered";
        listState.currentIndent++;
        break;
      }
      case "U":
        listState.currentFormatting.underline = true;
        break;
      case "#text": {
        const text = node.textContent || "";
        listState.listItems.push({
          attributes: {
            line: {
              listStyle: listState.listStyle,
              indentLevel: listState.currentIndent,
            },
            ...listState.currentFormatting,
          },
          text,
        });
        listState.currentFormatting = {};
        break;
      }
    }

    if (node.childNodes.length) {
      parseHTMLList(
        listState.listStyle,
        Array.from(node.childNodes),
        listState,
      );

      // as we close an item, depth goes shallower
      if (
        listState.currentIndent > 1 &&
        (node.nodeName === "UL" || node.nodeName === "OL")
      ) {
        listStyles.pop();
        listState.listStyle = listStyles[listStyles.length - 1];
        listState.currentIndent--;
      }
    }
  });

  return listState.listItems;
};

type ListItemState = {
  listNodes: TextNode[];
  currentFormatting: {
    bold?: boolean;
    highlightedColor?: string;
    inlineCode?: boolean;
    italic?: boolean;
    linkURL?: string;
    strikethrough?: boolean;
    underline?: boolean;
  };
};

const parseHTMLChecklistItem = (
  htmlNodes: Node[],
  listItemAttributes: CheckListItem,
  listItemState: ListItemState = {
    listNodes: [],
    currentFormatting: {},
  },
) => {
  const parentFormatting = structuredClone(listItemState.currentFormatting);
  htmlNodes.forEach((node) => {
    switch (node.nodeName) {
      case "A":
        listItemState.currentFormatting.linkURL = (
          node as HTMLAnchorElement
        ).href;
        break;
      case "CODE":
        listItemState.currentFormatting.inlineCode = true;
        break;
      case "STRONG":
        listItemState.currentFormatting.bold = true;
        break;
      case "EM":
        listItemState.currentFormatting.italic = true;
        break;
      case "MARK": {
        const bgColor = (node as HTMLElement).style.getPropertyValue(
          "background-color",
        );
        listItemState.currentFormatting.highlightedColor = rgbaToHex(bgColor);
        break;
      }
      case "S":
        listItemState.currentFormatting.strikethrough = true;
        break;
      case "U":
        listItemState.currentFormatting.underline = true;
        break;
      case "#text": {
        const text = node.textContent || "";
        listItemState.listNodes.push({
          attributes: {
            line: {
              listStyle: "checkbox",
              indentLevel: listItemAttributes.indent,
              checked: listItemAttributes.checked,
            },
            ...listItemState.currentFormatting,
          },
          text,
        });
        break;
      }
    }

    if (node.childNodes.length) {
      parseHTMLChecklistItem(
        Array.from(node.childNodes),
        listItemAttributes,
        listItemState,
      );
      // reset formatting after processing children
      listItemState.currentFormatting = parentFormatting;
    }
  });

  return listItemState.listNodes;
};

export const convertChecklistItemToRTJNodes = (block: BlockInstance) => {
  const _content = block.attributes.content;
  const content =
    _content.trim().length > 0
      ? _content
      : `<span>${ZERO_WIDTH_NO_BREAK_SPACE}</span>`;
  const nodes = new DOMParser()
    .parseFromString(content, "text/html")
    .querySelector("body")?.childNodes;

  const items = parseHTMLChecklistItem(
    nodes ? Array.from(nodes) : [],
    block.attributes as CheckListItem,
  );

  const lastItem = items[items.length - 1];

  if (lastItem && lastItem.text) {
    lastItem.text += "\n";
  }

  return items.map((item) => {
    item.text = item.text.replace(/\n\n$/g, "\n");
    return item;
  });
};
