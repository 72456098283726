import z from "zod";

export const DailyChatMessageSchema = z.object({
  id: z.string(),
  role: z.enum(["user", "assistant"]),
  content: z.string().nullable().optional(),
  alternative_content: z.array(z.string()).optional(),
  selected_alternative_content_idx: z.number().optional(),
  timestamp: z
    .string()
    .or(z.number())
    .transform((val) =>
      typeof val === "string" ? new Date(val).getTime() : val,
    ),
});

export const DailyChatContextSchema = z.object({
  events: z.array(z.string()).optional(),
  locations: z.array(z.string()).optional(),
  city: z.string().optional(),
  weather: z.string().optional(),
  people: z.array(z.string()).optional(),
  mood: z.string().optional(),
  mood_notes: z.string().optional(),
  stress: z.string().optional(),
  stress_notes: z.string().optional(),
  exercise: z.array(z.string()).optional(),
  exercise_notes: z.string().optional(),
  sleep_duration: z.string().optional(),
  sleep_notes: z.string().optional(),
  primary_goal_intention: z.string().optional(),
  food_meals: z.array(z.string()).optional(),
  energy_level: z.string().optional(),
  energy_level_notes: z.string().optional(),
  media_consumed: z.array(z.string()).optional(),
  images: z.array(z.string()).optional(),
  bio: z.string().optional(),
});

export const DailyChatSchema = z.object({
  date: z.string(),
  timezone: z.string(),
  chat_history: z.array(DailyChatMessageSchema).optional().default([]),
  context: DailyChatContextSchema.optional().nullable().default({}),
});

export type DailyChat = z.infer<typeof DailyChatSchema>;
export type DailyChatMessage = z.infer<typeof DailyChatMessageSchema>;
export type DailyChatContext = z.infer<typeof DailyChatContextSchema>;
