import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance } from "@wordpress/blocks";
import { Draggable, Popover } from "@wordpress/components";
import { useSelect } from "@wordpress/data";

import { ALLOWED_BLOCKS_FOR_GALLERY } from "@/components/Editor/blocks/gallery/GalleryBlock";
import { useBlockSelection } from "@/components/Editor/hooks/blockSelection";
import { Icon } from "@/components/Icon";
import { topRightButtonStyles } from "@/components/SyncableImage/TopRightButton";
import { Z_INDEX_EDITOR_MEDIA_BUTTONS } from "@/styles/theme";

// This data type is used by Gutenberg's onBlockDrop handler so we can reuse it
export const DRAGGABLE_MEDIA_TRANSFER_DATA_TYPE = "wp-blocks";
export type DraggableMediaTransferDataType = {
  type: string;
  srcRootClientId: string;
  srcClientIds: BlockInstance["clientId"][];
  blocks: BlockInstance[];
};

export const DraggableMediaButton = ({
  clientId,
  onDragStart,
  onDragEnd,
  rightMargin = 0,
}: {
  clientId: string;
  onDragStart: () => void;
  onDragEnd: () => void;
  rightMargin?: number | string;
}) => {
  const { getBlock, getBlockRootClientId } = useSelect(blockEditorStore, []);
  const { blocks: selectedBlocks } = useBlockSelection();
  const block = getBlock(clientId);
  const rootClientId = getBlockRootClientId(clientId);

  const transferBlocks =
    selectedBlocks.length === 0
      ? [block]
      : [
          block,
          ...selectedBlocks.filter(
            (selectedBlock) =>
              ALLOWED_BLOCKS_FOR_GALLERY.includes(selectedBlock.name) &&
              selectedBlock.clientId !== block.clientId,
          ),
        ];

  return (
    <Popover
      focusOnMount={false}
      animate={false}
      placement="right-start"
      sx={{
        zIndex: Z_INDEX_EDITOR_MEDIA_BUTTONS,
      }}
    >
      <Draggable
        elementId={`image-${clientId}`}
        transferData={
          {
            type: "block",
            srcRootClientId: rootClientId,
            srcClientIds: transferBlocks.map((block) => block.clientId),
            blocks: transferBlocks,
          } as DraggableMediaTransferDataType
        }
        __experimentalDragComponent={
          <div sx={{ ...topRightButtonStyles, color: "white" }}>
            <Icon icon="photo" />
          </div>
        }
        // This prevents pasting the data in the clipboard
        __experimentalTransferDataType={DRAGGABLE_MEDIA_TRANSFER_DATA_TYPE}
      >
        {({ onDraggableStart, onDraggableEnd }) => (
          <div
            role="button"
            className="image-drag-button"
            key={clientId + "button"}
            sx={{
              position: "absolute",
              right: rightMargin,
              cursor: "pointer",
              backgroundColor: "black",
              borderRadius: "xs",
              height: "24px",
              opacity: "0.5",
            }}
            draggable={true}
            onDragStart={(e) => {
              // Somehow this is the only way to send the data on `transferData`
              e.dataTransfer.clearData();
              onDragStart();
              onDraggableStart(e);
            }}
            onDragEnd={(e) => {
              onDragEnd();
              onDraggableEnd(e);
            }}
          >
            <Icon icon="drag" iconStyles={{ color: "white" }} />
          </div>
        )}
      </Draggable>
    </Popover>
  );
};
