import Dexie, { liveQuery } from "dexie";

import { RTJNode, RichTextJSON } from "@/../types/rtj-format";
import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { FetchWrapper } from "@/api/FetchWrapper";
import {
  isEmbeddedAudioNode,
  isEmbeddedPhotoNode,
  isEmbeddableContentNode,
  isEmbeddedVideoNode,
  isEmbeddedContactNode,
  isEmbeddedLocationNode,
  isEmbeddedPodcastNode,
  isEmbeddedSongNode,
  isEmbeddedMotionActivityNode,
  isEmbeddedWorkoutNode,
  isEmbeddedPdfNode,
  isEmbeddedGenericMediaNode,
  isEmbeddedStateOfMindNode,
} from "@/components/Editor/rtj2gb/rtj-type-checks";
import { DOCrypto } from "@/crypto/DOCrypto";
import { getDecryptedKeyInfo, generateNewKeys } from "@/crypto/DOCryptoKeys";
import { EncryptionKeyInfo } from "@/crypto/types/lockedKeyInfo";
import { toBase64 } from "@/crypto/utils";
import { md5 } from "@/crypto/utils/md5";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { dexieMaxKey, DODexie } from "@/data/db/dexie_db";
import {
  Activity,
  EntryDBRow,
  EntryMoveDBRow,
  GlobalEntryID,
  Weather,
} from "@/data/db/migrations/entry";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { MomentDBRow } from "@/data/db/migrations/moment";
import { EntryModel } from "@/data/models/EntryModel";
import { momentNeedsUpload } from "@/data/models/MomentModel";
import {
  isEntryMoveSendable,
  isEntrySendable,
  isOriginalMediaSendable,
} from "@/data/models/Outbox";
import {
  EntryMoveSendable,
  OutboxItem,
  OutboxResult,
  Sendable,
} from "@/data/models/OutboxTypes";
import { UserModel } from "@/data/models/UserModel";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { JournalStatsRepository } from "@/data/repositories/JournalStatsRepository";
import {
  MomentRepository,
  MomentWithThumb,
} from "@/data/repositories/MomentRepository";
import {
  MomentUpdatesFromSyncPerEntry,
  SyncOperationsRepository,
  makeMomentUpdateFromEntryInFeed,
} from "@/data/repositories/SyncOperationsRepository";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { EntryBlob, EntryBlobMoment } from "@/data/repositories/Syncables";
import { getTagsFromContent } from "@/data/repositories/TagParser";
import { TagRepository } from "@/data/repositories/TagRepository";
import { TemplateRepository } from "@/data/repositories/TemplateRepository";
import { UserRepository } from "@/data/repositories/UserRepository";
import { UserSettingsRepository } from "@/data/repositories/UserSettingsRepository";
import {
  Entry,
  EntryRevisionData,
  EntryWithFeedRecord,
} from "@/data/repositories/V2API";
import {
  PutEntryEnvelope,
  PutEntryMomentEnvelope,
  PutEntryMomentThumbnailEnvelope,
} from "@/data/repositories/V2PutEntryTypes";
import {
  InflatedVault,
  VaultRepository,
} from "@/data/repositories/VaultRepository";
import { DecryptionService } from "@/data/services/DecryptionService";
import { EntryService } from "@/data/services/EntryService";
import { FeedRecordWithData, parseFeed } from "@/data/services/SyncFeedParser";
import { getClientMeta } from "@/data/utils/clientMeta";
import { entryDBRowFromFeed } from "@/data/utils/converters";
import {
  MomentFeatureChecks,
  calculateFeatureFlags,
} from "@/data/utils/entryFeatureFlags";
import { makeDebugLogger } from "@/utils/debugLog";
import { ImportMedia } from "@/utils/import";
import { keyBy } from "@/utils/key-by";
import {
  createRichTextJsonString,
  decodeRichTextJson,
  getAppCode,
} from "@/utils/rtj";
import { tagsAsArray } from "@/utils/tags";
import { inlineThrow } from "@/utils/throwExpression";
import { constructUrlWithQueryParams } from "@/utils/url-helper";
import { mkEntryID } from "@/utils/uuid";
import { journalId_AllEntries } from "@/view_state/PrimaryViewState";

// Don't make it too small, or it'll bog down the DB
// Don't make it too large, or the user won't get visual
// on entries rolling in.
const ENTRY_BATCHING_SIZE = 10;

export type DatedGlobalEntryID = GlobalEntryID & {
  date: number;
  timezone?: string;
};
export type FullTimelineEntryID = DatedGlobalEntryID & {
  is_pinned: number;
  is_shared: number;
  is_starred: number;
  contents: RTJNode[];
  creator_user_id: string | null;
};

export type EntryKeys = GlobalEntryID & {
  date: number;
};

// A global entry ID plus the URL for the first thumbnail
// in the entry, if there is one.
export type CalendarEntryPreviewMetadata = GlobalEntryID & {
  media: string | null;
};

export const EntryMoveStatus = {
  CREATED: "created",
  RUNNING: "running",
  FAILED: "failed",
  COMPLETED: "completed",
  REVERTED: "reverted",
} as const;

export type EntryMoveStatus =
  (typeof EntryMoveStatus)[keyof typeof EntryMoveStatus];

export type EntryMoveResponse = {
  id: string;
  status: EntryMoveStatus;
  message: string;
  created_at?: string;
  ended_at?: string;
};

export type FailedMoveReason =
  | "shared_journal_source"
  | "server_revert"
  | "moment_copy_failed"
  | "unknown";

export interface FailedMove {
  reason: FailedMoveReason;
  entryCount: number;
  entryInfo?: {
    journalId: string;
    entryId: string;
  };
}

export class EntryRepository {
  user: UserModel | null = null;
  constructor(
    protected db: DODexie,
    private syncStateRepository: SyncStateRepository,
    private momentRepository: MomentRepository,
    private vaultRepository: VaultRepository,
    private journalRepository: JournalRepository,
    private journalStatsRepository: JournalStatsRepository,
    private decryptionService: DecryptionService,
    private templateRepository: TemplateRepository,
    private tagRepository: TagRepository,
    private fetch: FetchWrapper,
    private userRepository: UserRepository,
    private syncOperationsRepository: SyncOperationsRepository,
    private userSettingsRepository: UserSettingsRepository,
    private kv: KeyValueStore,
  ) {
    this.getUser();
  }

  private async getUser() {
    this.user = await this.userRepository.getActiveUser();
  }

  async synchronizeByJournalId(journalId: string): Promise<void> {
    // first check for a cursor, this one is identified by the journal ID
    const syncState =
      await this.syncStateRepository.getSyncCursorById(journalId);
    const cursor = syncState?.cursor || "";

    const syncUrl = constructUrlWithQueryParams(
      `/v2/sync/entries/${journalId}/feed`,
      {
        cursor: cursor.length && cursor !== "-1" ? cursor : null,
        excludeDeleted: cursor.length || cursor === "-1" ? null : "true",
        groups: journalId,
      },
    );

    const res = await this.fetch.fetchAPI(syncUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200 && res.body) {
      const journal = await this.journalRepository.getById(journalId);
      if (!journal) {
        throw new Error("Journal not found when syncing entry");
      }

      let entryBatch: FeedRecordWithData[] = [];
      const flushEntryBatch = async () => {
        if (entryBatch.length) {
          // Refresh the sync lock every time we write a batch
          // because it only lives a short time (in case the sync
          // process dies). This is a heartbeat to let the app
          // know that sync is still working and shouldn't be
          // launched again.
          await this.syncStateRepository.setLock();
          const decrypted = await this.decryptFeedRecordsWithData(entryBatch);

          const debugLogging = await this.kv.get<boolean>("debug-logging");
          if (debugLogging) {
            const lastEntry = decrypted[decrypted.length - 1].entry;
            console.log(
              `Number of decrypted entries for journal: ${journal.id}`,
              decrypted.length,
            );
            console.log("Last entry ID:", lastEntry?.id);
          }

          await this.persistEntriesFromFeed(
            journalId,
            decrypted,
            !!journal.hide_all_entries,
          );
          entryBatch = [];
        } else {
          const currentCursor =
            await this.syncStateRepository.getSyncCursorById(journalId);
          if (!currentCursor) {
            await this.updateCursor(journalId, -1);
          }
        }
      };
      for await (const feedRecordAndData of parseFeed(res.body, false, {
        journalId,
        syncState,
        syncUrl,
      })) {
        const id = feedRecordAndData?.record.revision.entryId;

        if (id) {
          entryBatch.push(feedRecordAndData);
          if (entryBatch.length >= ENTRY_BATCHING_SIZE) await flushEntryBatch();
        }
      }
      await flushEntryBatch();
    } else {
      const isLoggingOut = await this.kv.get("is-logging-out");
      if (!(isLoggingOut && res.status === 403)) {
        Sentry.captureException(
          new Error(`Error syncing entries: ${res.status}`),
        );
      }
    }
  }

  private async decryptFeedRecordsWithData(records: FeedRecordWithData[]) {
    const journalsWithVaults =
      await this.journalRepository.getJournalsWithValidVaults();
    const decryptedAsPromises = await EntryService.decryptEntries(
      records,
      this.decryptionService,
      journalsWithVaults,
    );

    const decrypted = decryptedAsPromises.reduce(
      (
        acc: EntryWithFeedRecord[],
        p: PromiseSettledResult<EntryWithFeedRecord>,
      ) => {
        if (p.status === "fulfilled") {
          acc.push(p.value);
        }
        return acc;
      },
      [],
    );

    return decrypted;
  }

  private async updateCursor(journalId: string, cursor: number) {
    await this.syncStateRepository.updateSyncCursorById(journalId, cursor);
  }

  private async processEntryMoves(
    entryRevision: EntryWithFeedRecord,
    existingEntry: EntryDBRow,
    journalId: string,
  ) {
    const entryId = entryRevision.record.revision.entryId;
    const lastMove = entryRevision.record.move_info?.[0];

    const pendingMoves = (await this.db.outbox_items
      .where({
        entryId: entryId,
        action: "MOVE",
      })
      .toArray()) as unknown as EntryMoveSendable[];

    const pendingJournalMove = pendingMoves.find(
      (move) => move.journalId === journalId,
    );

    // If there is a pending move and we receive an update to the source journal
    // this might mean that we are receiving the old Entry again.
    // We just ignore this update since it will be resolved later
    if (
      entryRevision.record.revision.type !== "delete" &&
      !existingEntry &&
      pendingJournalMove &&
      lastMove?.id !== pendingJournalMove.entryMoveId
    ) {
      return false;
    }
    return null;
  }

  private async shouldUpdate(
    feedEntry: EntryWithFeedRecord,
    existingEntry?: EntryDBRow,
  ) {
    // we don't have the entry locally, so we should update
    if (!existingEntry) {
      return true;
    }

    const isDelete = feedEntry.record.revision.type === "delete";
    // If we are deleting an entry, first compare the revision id
    if (isDelete && existingEntry) {
      return (
        existingEntry.revision_id &&
        existingEntry.revision_id <= feedEntry.record.revision.revisionId
      );
    }

    // local entry already has the right field to compare
    if (existingEntry.edit_date && feedEntry.record.revision.editDate) {
      if (existingEntry.edit_date === feedEntry.record.revision.editDate) {
        return (
          existingEntry.revision_id &&
          existingEntry.revision_id < feedEntry.record.revision.revisionId
        );
      }
    }
    return existingEntry.edit_date < feedEntry.record.revision.editDate;
  }

  private async persistEntries(
    journalId: string,
    revisions: EntryWithFeedRecord[],
    hideAllEntries = false,
  ) {
    if (!this.user) {
      await this.getUser();
    }

    return await this.db.transaction(
      "rw",
      [
        this.db.entries,
        this.db.moments,
        this.db.comments,
        this.db.comment_reactions,
        this.db.notifications,
        this.db.sync_states,
        this.db.entry_counts_cache,
        this.db.tags,
        this.db.reactions,
        this.db.outbox_items,
        this.db.pending_entry_updates,
      ],
      async () => {
        // First thing we need to do here is get any existing entries
        // that we're going to be updating, so that we can check the
        // user edit date and not overwrite newer local changes.

        const existing = await this.db.entries
          .where(["journal_id", "id"])
          .anyOf(revisions.map((e) => [journalId, e.record.revision.entryId]))
          .toArray();
        const existingWithGlobalIdKeyAdded = existing.map((e) => {
          return {
            ...e,
            globalId: `${e.journal_id}:${e.id}`,
          };
        });
        const existingDict = keyBy(
          existingWithGlobalIdKeyAdded,
          "globalId",
        ) as Record<string, EntryDBRow & { globalId: string }>;

        // Filter the incoming revisions, dropping the ones whose local
        // copies are newer. (this is our conflict resolution strategy
        // across clients, last edit wins)
        const entryRevisions: EntryWithFeedRecord[] = [];

        const idsToDelete: string[] = [];
        const incomingMomentUpdates: MomentUpdatesFromSyncPerEntry[] = [];
        // This looks messy, but we're building two arrays of entries to upsert
        // with different shapes so we don't have to pass around the big list
        // of revisions everywhere. The moments, tags, and reactions all need
        // the Entry object with a journal ID, but the actual upsert into the
        // entries table needs the EntryDBRow object, which requires the revision
        // to generate. It's inelegant, but it's simple.
        const entriesForUpsert: Array<Entry & { journal_id: string }> = [];
        const entryDBRowsForUpsert: EntryDBRow[] = [];
        const entryRevisionsForUpsert: EntryRevisionData[] = [];

        for (const entryRevision of revisions) {
          const { entry, record: envelope } = entryRevision;

          const entryId = entryRevision.record.revision.entryId;
          const existingEntry = existingDict[`${journalId}:${entryId}`];

          let shouldUpdateFromMove: boolean | null = null;

          // Checks for any existing entry moves and handles content updates accordingly
          // This is mostly logic for when the user has made local changes to an entry
          // and then the server-side move happens, which "undoes" the local changes.
          // In this scenario, we want to update the entry with the local changes so that
          // the user doesn't lose their changes.
          shouldUpdateFromMove = await this.processEntryMoves(
            entryRevision,
            existingEntry,
            journalId,
          );

          const shouldUpdate =
            shouldUpdateFromMove ??
            (await this.shouldUpdate(entryRevision, existingEntry));

          if (shouldUpdate) {
            entryRevisions.push(entryRevision);
          } else {
            continue;
          }

          if (envelope.revision.type === "delete") {
            idsToDelete.push(envelope.revision.entryId);
          } else if (entry) {
            const existingEntry = existing.find(
              (e) => e.id === entry.id && e.journal_id === journalId,
            );
            let localEntry: EntryDBRow | undefined = undefined;

            const entryPendingUpdates =
              await this.getPendingEntryUpdates(entryId);

            if (
              entryPendingUpdates?.entry &&
              entryPendingUpdates.entry.edit_date >
                entryRevision.record.revision.editDate
            ) {
              localEntry = entryPendingUpdates.entry;
              const sendable = {
                id: `ENTRY:${journalId}-${entryId}`,
                type: "Entry",
                action: "UPDATE",
                entryId,
                journalId,
                userModifiedAt: Date.now(),
              };
              await this.db.outbox_items.add(sendable as unknown as OutboxItem);
            }
            await this.removePendingEntryUpdates(entryId);

            const entryRow = entryDBRowFromFeed(
              entryRevision,
              existingEntry,
              hideAllEntries,
              this.user?.id,
              localEntry,
            );

            entryDBRowsForUpsert.push(entryRow);
            entriesForUpsert.push({ ...entry, journal_id: journalId });
            entryRevisionsForUpsert.push(envelope.revision);
            incomingMomentUpdates.push(
              makeMomentUpdateFromEntryInFeed(entryRevision)!,
            );
          }
        }

        if (entryRevisions.length === 0) {
          return;
        }

        if (idsToDelete.length) {
          // This removes local entries and all related data for those entries all in one transaction.
          await this.syncOperationsRepository.removeEntriesAndAssociatedStuff(
            idsToDelete.map((id) => ({ id, journal_id: journalId })),
          );
        }

        if (entryDBRowsForUpsert.length) {
          await Promise.all([
            this.db.entries.bulkPut(entryDBRowsForUpsert),
            this.syncOperationsRepository.updateMomentsFromEntrySync(
              incomingMomentUpdates,
            ),
            this.syncOperationsRepository.updateTagsFromEntrySync(
              entriesForUpsert,
            ),
            this.syncOperationsRepository.updateReactionsFromEntrySync(
              entryRevisionsForUpsert,
            ),
          ]);
        }
        return entryRevisions;
      },
    );
  }

  public async persistEntryFromUpdateResponse(
    journalId: string,
    revision: EntryWithFeedRecord,
  ) {
    try {
      await this.persistEntries(journalId, [revision]);
    } catch (err) {
      console.error(err);
      throw new Error(`Error persisting entry from update response -> ${err}`, {
        cause: err,
      });
    }
  }

  public async persistEntriesFromFeed(
    journalId: string,
    _entryRevisions: EntryWithFeedRecord[],
    hideAllEntries = false,
  ): Promise<void> {
    try {
      const entryRevisions = await this.persistEntries(
        journalId,
        _entryRevisions,
        hideAllEntries,
      );
      if (entryRevisions?.length) {
        const lastEntry = entryRevisions[entryRevisions.length - 1];
        await this.updateCursor(journalId, lastEntry.record.cursor);
      }
    } catch (err) {
      console.error(err);
      throw new Error(`Error persisting entries from feed -> ${err}`, {
        cause: err,
      });
    }
  }

  async getFirstEntry(journalId: string) {
    if (journalId === journalId_AllEntries) {
      const entry = await this.db.entries
        .where(["is_deleted", "date", "journal_id", "id"])
        .between(
          [0, Dexie.minKey, Dexie.minKey, Dexie.minKey],
          [0, dexieMaxKey(), dexieMaxKey(), dexieMaxKey()],
          true,
          true,
        )
        .first();
      return entry;
    } else {
      const entry = await this.db.entries
        .where(["journal_id", "is_deleted", "date", "id"])
        .between(
          [journalId, 0, Dexie.minKey, Dexie.minKey],
          [journalId, 0, dexieMaxKey(), dexieMaxKey()],
          true,
          true,
        )
        .first();
      return entry;
    }
  }

  async getLastEntry(journalId: string) {
    if (journalId === journalId_AllEntries) {
      const entry = await this.db.entries
        .where(["is_deleted", "date", "journal_id", "id"])
        .between(
          [0, Dexie.minKey, Dexie.minKey, Dexie.minKey],
          [0, dexieMaxKey(), dexieMaxKey(), dexieMaxKey()],
          false,
          true,
        )
        .last();
      return entry;
    } else {
      const entry = await this.db.entries
        .where(["journal_id", "is_deleted", "date", "id"])
        .between(
          [journalId, 0, Dexie.minKey, Dexie.minKey],
          [journalId, 0, dexieMaxKey(), dexieMaxKey()],
          false,
          true,
        )
        .last();
      return entry;
    }
  }

  async getEntryKeysBetweenDates(
    journalId: string,
    startDate?: number,
    endDate?: number,
  ) {
    if (journalId === journalId_AllEntries) {
      const entryKeys = await this.db.entries
        .where(["hide_all_entries", "is_deleted", "date", "journal_id", "id"])
        .between(
          [0, 0, startDate ?? Dexie.minKey, Dexie.minKey, Dexie.minKey],
          [0, 0, endDate ?? dexieMaxKey(), dexieMaxKey(), dexieMaxKey()],
          true,
          false,
        )
        .keys((keys) =>
          keys.map((key) => {
            if (Array.isArray(key) && key.length === 5) {
              return {
                id: key[4] as unknown as string,
                journal_id: key[3] as unknown as string,
                date: key[2] as unknown as number,
              };
            }
          }),
        );

      return entryKeys.filter((value) => value !== undefined) as EntryKeys[];
    } else {
      const entryKeys = await this.db.entries
        .where(["journal_id", "is_deleted", "date", "id"])
        .between(
          [journalId, 0, startDate ?? Dexie.minKey, Dexie.minKey],
          [journalId, 0, endDate ?? dexieMaxKey(), dexieMaxKey()],
          true,
          false,
        )
        .keys((keys) =>
          keys.map((key) => {
            if (Array.isArray(key) && key.length === 4) {
              return {
                id: key[3] as unknown as string,
                journal_id: key[0] as unknown as string,
                date: key[2] as unknown as number,
              };
            }
          }),
        );

      return entryKeys.filter((value) => value !== undefined) as EntryKeys[];
    }
  }

  async getEntryCountByJournal(journalId: string) {
    return this.db.entries
      .where(["journal_id", "is_deleted", "date", "id"])
      .between(
        [journalId, 0, Dexie.minKey, Dexie.minKey],
        [journalId, 0, dexieMaxKey(), dexieMaxKey()],
      )
      .count();
  }

  async getEntryCountForJournalMember(journalId: string, userId: string) {
    const entries = await this.db.entries
      .where(["is_shared", "journal_id", "creator_user_id"])
      .equals([1, journalId, userId])
      .count();
    return entries;
  }

  async getEntriesByDay(journalId: string, date: Date) {
    const start = date.setHours(0, 0, 0, 0);
    const end = date.setHours(23, 59, 59, 999);
    return this.getEntriesBetweenDates(journalId, start, end);
  }

  async getEntriesBetweenDates(
    journalId: string,
    startDate: number,
    endDate: number,
  ) {
    if (journalId === journalId_AllEntries) {
      return this.db.entries
        .where(["hide_all_entries", "is_deleted", "date", "journal_id", "id"])
        .between(
          [0, 0, startDate, Dexie.minKey, Dexie.minKey],
          [0, 0, endDate, dexieMaxKey(), dexieMaxKey()],
          true,
          false,
        )
        .toArray();
    } else {
      return this.db.entries
        .where(["journal_id", "is_deleted", "date", "id"])
        .between(
          [journalId, 0, startDate, Dexie.minKey],
          [journalId, 0, endDate, dexieMaxKey()],
          true,
          false,
        )
        .toArray();
    }
  }

  async getEntryById({
    journalId,
    entryId,
  }: {
    journalId: string;
    entryId: string;
  }): Promise<EntryDBRow | null> {
    const result = await this.db.entries
      .where(["journal_id", "id"])
      .equals([journalId, entryId])
      .first();

    return result || null;
  }

  async pushEntry(
    entry: EntryDBRow,
    moments: MomentWithThumb[],
    journal: JournalDBRow,
    tags: string[],
  ): Promise<OutboxResult> {
    const rtj = decodeRichTextJson(entry.rich_text_json);
    const body = entry.body;

    let vault: null | InflatedVault = null;

    // MARK: - Encrypt the moments if we need to.
    if (journal?.e2e) {
      vault = await this.vaultRepository.getVaultByJournalId(entry.journal_id);
      if (vault == null) {
        throw new Error(
          `Cannot upload an entry when journal ${journal.id} is missing a vault`,
        );
      }
      for (const moment of moments) {
        if (moment.thumbnail_data) {
          moment.thumbnail_data = await DOCrypto.JournalKey.encrypt(
            moment.thumbnail_data,
            vault!.vault.keys[0],
            2,
          );
          moment.thumbnail_md5_envelope = await md5(moment.thumbnail_data);
        }
      }
    }

    const momentFeatureChecks: MomentFeatureChecks = {
      nonJpeg: false,
      video: false,
      audio: false,
      pdf: false,
      sketch: false,
      multipleAttachments: moments.length > 1,
    };

    // MARK: - Build the envelope (metadata & md5s of encrypted thumbs)
    const envelope: PutEntryEnvelope = {
      editDate: entry.edit_date,
      entryDate: entry.date,
      type: "update",
      entryId: entry.id,
      featureFlags: entry.feature_flags,
      moments: moments.map((moment) => {
        // Here we make use of parsing the moments to calculate new feature flags
        if (moment.is_sketch) {
          momentFeatureChecks.sketch = true;
        } else if (
          moment.type === "image" &&
          moment.content_type !== "image/jpeg"
        ) {
          momentFeatureChecks.nonJpeg = true;
        } else if (moment.type === "audio") {
          momentFeatureChecks.audio = true;
        } else if (moment.type === "video") {
          momentFeatureChecks.video = true;
        } else if (moment.type === "pdfAttachment") {
          momentFeatureChecks.pdf = true;
        }

        const md5 = journal?.e2e
          ? moment.thumbnail_md5_envelope
          : moment.thumbnail_md5_body;
        if (moment.thumbnail_content_type && !md5) {
          throw new Error(
            `Moment pending upload has no md5 - content type: ${moment.thumbnail_content_type}`,
          );
        }
        const thumbnail: PutEntryMomentThumbnailEnvelope | null =
          moment.thumbnail_content_type
            ? {
                contentType: moment.thumbnail_content_type,
                md5: md5!,
                height: moment.thumbnail_height,
                width: moment.thumbnail_width,
              }
            : null;
        if (moment.thumbnail_size_bytes && thumbnail) {
          thumbnail.fileSize = moment.thumbnail_size_bytes;
        }
        const m: PutEntryMomentEnvelope = {
          contentType: moment.content_type,
          momentType: moment.type,
          id: moment.id,
          height: moment.height,
          width: moment.width,
        };
        if (thumbnail) {
          m.thumbnail = thumbnail;
        }
        return m;
      }),
    };

    // Calculate the new feature flags for the entry
    const entryFeatureFlags = calculateFeatureFlags(
      entry,
      momentFeatureChecks,
      rtj,
    );
    // If the feature flags have changed, update the envelope data and the DB
    if (entryFeatureFlags !== entry.feature_flags) {
      envelope.featureFlags = entryFeatureFlags;
      await this.db.entries
        .where(["journal_id", "id"])
        .equals([journal.id, entry.id])
        .modify({
          feature_flags: entryFeatureFlags,
        });
    }

    // MARK: - Build the entry (JSON of entry & moments. Will all be encrypted before uploading if E2E)
    const entryBlob: EntryBlob = {
      id: entry.id,
      activity: entry.activity,
      body,
      clientMeta: JSON.parse(entry.client_meta),
      date: entry.date,
      duration: entry.duration,
      editingTime: entry.editing_time,
      isAllDay: !!entry.is_all_day,
      isPinned: !!entry.is_pinned,
      location: entry.location,
      richTextJSON: JSON.stringify(rtj),
      starred: !!entry.is_starred,
      timeZone: entry.timezone,
      moments: moments.map((moment) => {
        const m: EntryBlobMoment = {
          id: moment.id,
          md5: moment.md5_body,
          contentType: moment.content_type,
          type: moment.type,
          date: moment.date,
          // Possible metadata
          location: moment.metadata?.location,
          duration: moment.metadata?.duration,
          audioChannels: moment.metadata?.audioChannels,
          format: moment.metadata?.format,
          recordingDevice: moment.metadata?.recordingDevice,
          sampleRate: moment.metadata?.sampleRate,
          timeZoneName: moment.metadata?.timeZoneName,
          title: moment.metadata?.title,
          height: moment.height,
          width: moment.width,
          favorite: !!moment.favorite,
          isSketch: !!moment.is_sketch,
          ...(moment.type === "pdfAttachment" && { pdfName: moment.pdfName }),
        };
        if (moment.thumbnail_md5_body) {
          m.thumbnail = {
            contentType:
              moment.thumbnail_content_type ??
              inlineThrow(
                "Tried to add a thumbnail to a moment in the body without a content type",
              ),
            width: moment.thumbnail_width || 0,
            height: moment.thumbnail_height || 0,
            md5: moment.thumbnail_md5_body,
          };
        }
        return m;
      }),
      tags,
      templateID: entry.templateID,
      promptID: entry.promptID,
      userEditDate: entry.edit_date,
      weather: entry.weather,
      music: entry.music,
      steps: entry.steps,
    };

    // MARK: - Put together the actual request.
    const formData = new FormData();

    formData.append(
      "envelope",
      new Blob([JSON.stringify(envelope)], { type: "application/json" }),
      "envelope",
    );

    if (vault) {
      const buffer = await DOCrypto.EntryBlob.encrypt(
        entryBlob,
        vault.vault.keys[0],
        2,
      );
      formData.append(
        "encrypted-content",
        new Blob([buffer], { type: "vnd/day-one-encrypted" }),
        "encrypted-content",
      );
    } else {
      formData.append(
        "content",
        new Blob([JSON.stringify(entryBlob)], { type: "application/json" }),
        "content",
      );
    }

    // MARK: - Attach the moment datas to the form if they need upload
    for (const moment of moments) {
      if (moment.thumbnail_data) {
        formData.append(
          `thumbnail.${moment.id}`,
          new Blob([moment.thumbnail_data], {
            type: "application/octet-stream",
          }),
          `thumbnail.${moment.id}`,
        );
      }
    }
    const syncUrl = constructUrlWithQueryParams(
      `/v2/sync/entries/${entry.journal_id}/${entry.id}`,
      {
        parent: `${entry.revision_id}`,
      },
    );
    // MARK: - Send the request.
    const resp = await this.fetch.fetchAPI(syncUrl, {
      method: "put",
      body: formData,
    });

    if (!resp.ok || !resp.body) {
      const msg = await resp.text();
      return {
        result: "failed",
        message: `Error updating entry: ${resp.status} - ${msg}`,
      };
    }

    let entryBatchFromPush: FeedRecordWithData[] = [];
    let decrypted: EntryWithFeedRecord | null = null;
    const flushEntry = async () => {
      if (entryBatchFromPush.length) {
        const decryptedArray =
          await this.decryptFeedRecordsWithData(entryBatchFromPush);
        // We only update one entry at a time so we will only get back one entry in the response from the server.
        if (decryptedArray.length > 0) {
          decrypted = decryptedArray[0];
        }
        entryBatchFromPush = [];
      }
    };
    for await (const feedRecordAndData of parseFeed(resp.body, false, {})) {
      const id = feedRecordAndData?.record.revision.entryId;
      if (id) {
        entryBatchFromPush.push(feedRecordAndData);
        if (entryBatchFromPush.length >= ENTRY_BATCHING_SIZE)
          await flushEntry();
      }
    }
    await flushEntry();

    if (decrypted == null) {
      return {
        result: "failed",
        message: `Error pushing entry ${entry.id} in journal {${entry.journal_id}}. Entry could not be processed from response.`,
      };
    }

    // Typescript for some reason isn't seeing that decrypted can be EntryWithFeedRecord
    // It gets set in the flushEntry function above
    const resultFromPush = decrypted as EntryWithFeedRecord;

    // If outcome is dirty we need to update our local copy to be what was returned in the push response
    // That is `resultFromPush.entry`
    if (resultFromPush.record.outcome === "dirty") {
      const entryFromResult = resultFromPush.entry;
      if (!entryFromResult) {
        return {
          result: "failed",
          message: `Error pushing entry ${entry.id} in journal {${entry.journal_id}}. Entry was dirty but no entry was returned in the response.`,
        };
      }
      const debugLogging = await this.kv.get<boolean>("debug-logging");
      const logDebug = makeDebugLogger("Conflict", !!debugLogging);
      logDebug({
        Entry: entry.id,
        Journal: entry.journal_id,
        "Revision edit date": entryFromResult.userEditDate
          ? new Date(entryFromResult.userEditDate).toISOString()
          : "No edit date",
        "Local edit date": new Date(entry.user_edit_date).toISOString(),
      });
      await this.persistEntryFromUpdateResponse(entry.journal_id, {
        entry: entryFromResult,
        record: resultFromPush.record,
      });
    } else if (
      resultFromPush.record.outcome === "clean" &&
      resultFromPush.record.revision.revisionId
    ) {
      await this.db.entries.update([entry.journal_id, entry.id], {
        revision_id: resultFromPush.record.revision.revisionId,
      });
    }

    if (moments.length) {
      const momentsAllUploaded = await Promise.all(
        moments.filter(momentNeedsUpload).map((moment) => {
          analytics.tracks.recordEvent(EVENT.attachmentUpload, {
            attachment_type: moment.type,
          });
          return this.momentRepository
            .uploadToServer(moment)
            .then((response) => {
              if (response.result === "success") {
                analytics.tracks.recordEvent(EVENT.attachmentUploadSuccess, {
                  attachment_type: moment.type,
                });
              } else {
                analytics.tracks.recordEvent(EVENT.attachmentUploadFailed, {
                  attachment_type: moment.type,
                });
              }
              return response;
            });
        }),
      );
      if (momentsAllUploaded.some((m) => m.result === "failed")) {
        return {
          result: "failed",
          message: `Error uploading moments for entry ${entry.id} in journal {${entry.journal_id}}`,
        };
      }
    }

    await this.removePendingEntryUpdates(entry.id);

    return { result: "success" };
  }

  // Pushes an entry from the local database to the server, enqueues uploads of its originals
  // And records the push in the local database. This is used for entries in the local database
  // for a journal that has been synced. If you're looking to just upload a new entry directly
  // use `pushEntry` instead.
  async syncEntryUp(journalId: string, entryId: string): Promise<OutboxResult> {
    const entry = await this.getEntryById({ journalId, entryId });

    if (!entry) {
      const error = `Tried to push entry ${entryId} to server, but it doesn't exist`;
      Sentry.captureException(new Error(error));
      return { result: "failed", message: error };
    }
    const entryTags = await this.tagRepository.getTagsForEntry(
      journalId,
      entryId,
    );

    const entryJournal = await this.journalRepository.getById(entry.journal_id);
    if (!entryJournal) {
      throw new Error(
        "Could not push entry ${entryId}:${journalId}. Journal not found locally.",
      );
    }
    const moments =
      await this.momentRepository.getMomentsForEntryWithThumbnailData(
        entry.journal_id,
        entry.id,
      );

    const result = await this.pushEntry(
      entry,
      moments,
      entryJournal,
      entryTags,
    );

    await this.momentRepository.markMomentThumbsAfterUpload(moments);

    return result;
  }

  async syncUnread() {
    const res = await this.fetch.fetchAPI("/shares/unread-entries", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      const json = await res.json();
      return json;
    } else {
      Sentry.captureException(
        new Error(
          `Error synchronizing unread entries: ${
            res.status
          }. Res: ${JSON.stringify(res)}`,
        ),
      );
    }
  }

  private getMediaCounts(content: RTJNode[]) {
    return content.reduce(
      (acc, value) => {
        if (isEmbeddableContentNode(value)) {
          if (isEmbeddedPhotoNode(value)) {
            acc.photos++;
          } else if (isEmbeddedVideoNode(value)) {
            acc.videos++;
          } else if (isEmbeddedAudioNode(value)) {
            acc.audio++;
          } else if (isEmbeddedPdfNode(value)) {
            acc.pdfs++;
          } else if (isEmbeddedContactNode(value)) {
            acc.contact++;
          } else if (isEmbeddedLocationNode(value)) {
            acc.location++;
          } else if (isEmbeddedPodcastNode(value)) {
            acc.podcast++;
          } else if (isEmbeddedSongNode(value)) {
            acc.song++;
          } else if (isEmbeddedMotionActivityNode(value)) {
            acc.motionActivity++;
          } else if (isEmbeddedWorkoutNode(value)) {
            acc.workout++;
          } else if (isEmbeddedGenericMediaNode(value)) {
            acc.genericMedia++;
          } else if (isEmbeddedStateOfMindNode(value)) {
            acc.stateofMind++;
          }
        }

        return acc;
      },
      {
        videos: 0,
        audio: 0,
        photos: 0,
        pdfs: 0,
        contact: 0,
        location: 0,
        podcast: 0,
        song: 0,
        motionActivity: 0,
        workout: 0,
        genericMedia: 0,
        stateofMind: 0,
      },
    );
  }

  async updateEntryLocal(
    journalId: string,
    entryId: string,
    contents: RTJNode[],
    body: string,
    date: number,
    isAllDay: boolean,
    editDate: number,
    weather: Weather = null,
    templateID: string | null = null,
    promptID: string | null = null,
    isStarred = false,
    isPinned = false,
    prevContents: RTJNode[] | null = null,
    activity: Activity = "",
  ) {
    const entry = await this.getEntryById({ journalId, entryId });
    const clientMeta = await getClientMeta();

    const getTagsFromHashtags =
      (await this.userSettingsRepository.loadSettings())
        ?.create_tags_from_hashtags ?? true;

    let rtj = createRichTextJsonString(contents);
    if (entry) {
      const oldRtj = decodeRichTextJson(entry.rich_text_json) as RichTextJSON;
      const oldContents = prevContents || oldRtj.contents;

      // If the old entry has meta, we should keep it
      if (oldRtj.meta) {
        // Fix for bug with metadata created version which caused a crash on Android
        // See https://github.com/bloom/DayOne-Web/pull/3531
        if (
          oldRtj.meta.created?.version &&
          oldRtj.meta.created.version.toString().includes(".")
        ) {
          oldRtj.meta.created.version = getAppCode();
        }
        rtj = createRichTextJsonString(contents, oldRtj.meta);
      }

      if (getTagsFromHashtags) {
        const oldTagsFromContent = getTagsFromContent(oldContents);
        const newTagsFromContent = getTagsFromContent(contents);

        // We'll upsert all the tags found but only delete the ones gone on the last edit.
        const tagsToRemove = oldTagsFromContent.reduce((acc: string[], tag) => {
          if (!newTagsFromContent.includes(tag)) {
            acc.push(tag);
          }
          return acc;
        }, []);

        this.tagRepository.bulkUpdateHashTagsForEntry(
          newTagsFromContent.map((tag) => ({
            tag,
            entry_id: entryId,
            journal_id: journalId,
          })),
          tagsToRemove.map((tag) => ({
            tag,
            entry_id: entryId,
            journal_id: journalId,
          })),
        );
      }

      const oldMediaCounts = this.getMediaCounts(oldContents);
      const newMediaCounts = this.getMediaCounts(contents);
      const mediaDifferences = {
        photos: newMediaCounts.photos - oldMediaCounts.photos,
        audio: newMediaCounts.audio - oldMediaCounts.audio,
        videos: newMediaCounts.videos - oldMediaCounts.videos,
      };
      if (
        mediaDifferences.photos !== 0 ||
        mediaDifferences.audio !== 0 ||
        mediaDifferences.videos !== 0
      ) {
        await this.journalStatsRepository.modifyCachedCount(
          journalId,
          mediaDifferences,
        );
      }
    }

    return await this.db.entries
      .where(["journal_id", "id"])
      .equals([journalId, entryId])
      .modify({
        // We sometimes add in zero width spaces to preserve blank lines or list
        // items. But we shouldn't save those characters as part of the content
        // as it can cause issues with other clients, and they don't need to be there
        // this will strip out those zero width characters when saving to our local DB
        rich_text_json: rtj.replace(/[\u200B\uFEFF]/g, ""),
        body: body.replace(/[\u200B\uFEFF]/g, ""),
        date,
        weather,
        templateID,
        promptID,
        is_all_day: isAllDay ? 1 : 0,
        user_edit_date: editDate,
        edit_date: editDate,
        is_starred: isStarred ? 1 : 0,
        is_pinned: isPinned ? 1 : 0,
        last_editing_device_name: clientMeta.deviceName,
        last_editing_device_id: clientMeta.deviceId,
        activity,
      });
  }

  async deleteById(entryId: string, journalId: string) {
    const momentCounts = await this.momentRepository.getMomentCountsForEntry(
      journalId,
      entryId,
    );
    try {
      await this.db.transaction(
        "rw",
        this.db.entries,
        this.db.entry_counts_cache,
        async () => {
          // Soft delete the entry
          await this.db.entries
            .where(["journal_id", "id"])
            .equals([journalId, entryId])
            .modify({ is_deleted: 1 });

          await this.journalStatsRepository.modifyCachedCount(
            journalId,
            { ...momentCounts, count: 1 },
            "-",
          );
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  async bulkRemoveLocalEntries(entryIds: GlobalEntryID[]) {
    return await this.db.entries
      .where(["journal_id", "id"])
      .anyOf(entryIds.map((e) => [e.journal_id, e.id]))
      .delete()
      .then((deletedCount) => {
        return { failedKeys: [], deletedCount };
      })
      .catch((err) => {
        if (err instanceof Dexie.ModifyError) {
          err.failures.forEach(function (failure) {
            console.error(failure.stack || failure.message);
          });
          return { failedKeys: err.failedKeys, deletedCount: err.successCount };
        } else {
          throw err;
        }
      });
  }

  async bulkTogglePinOrFavorite(
    entryIds: GlobalEntryID[],
    toggleType: "pin" | "favorite",
    newValue: boolean,
  ) {
    const toggleField = toggleType === "pin" ? "is_pinned" : "is_starred";
    return await this.db.entries
      .where(["journal_id", "id"])
      .anyOf(entryIds.map((e) => [e.journal_id, e.id]))
      .modify((entry) => {
        entry[toggleField] = newValue ? 1 : 0;
        entry.edit_date = Date.now();
      })
      .then((updatedCount) => {
        return { failedKeys: [], updatedCount };
      })
      .catch((err) => {
        if (err instanceof Dexie.ModifyError) {
          err.failures.forEach(function (failure) {
            console.error(failure.stack || failure.message);
          });
          return {
            failedKeys: err.failedKeys.map((f) => ({
              journal_id: (f as unknown as [string, string])[0],
              id: (f as unknown as [string, string])[1],
            })),
            updatedCount: err.successCount,
          };
        } else {
          throw err;
        }
      });
  }

  async pushEntryDelete(
    entryId: string,
    journalId: string,
  ): Promise<OutboxResult> {
    // Basic envelope for update
    const envelope = {
      editDate: Date.now(),
      type: "delete",
      entryId: entryId,
    };

    const formData = new FormData();

    formData.append(
      "envelope",
      new Blob([JSON.stringify(envelope)]),
      "envelope",
    );

    const result = await this.fetch.fetchAPI(
      `/v3/sync/entries/${journalId}/${entryId}`,
      {
        method: "put",
        body: formData,
      },
    );
    if (result.status === 200) {
      // Actually remove the entry from the local database
      await this.db.entries
        .where(["journal_id", "id"])
        .equals([journalId, entryId])
        .delete();
      return { result: "success" };
    } else {
      const errorMsg = await result.text();
      return {
        result: "failed",
        message: `Error deleting Entry ${entryId} in journal ${journalId}: ${result.status} - ${errorMsg}`,
      };
    }
  }

  async removeLocalEntriesForJournals(journalIds: string[]) {
    await this.db.entries.where("journal_id").anyOf(journalIds).delete();
  }

  async createNew(
    journalId: string,
    entryDate: Date,
    opts?: CreateNewEntryOpts,
  ) {
    const id = opts?.useEntryId ?? mkEntryID();
    const clientMeta = await getClientMeta();
    const journal = await this.journalRepository.getById(journalId);
    const user = await this.userRepository.getActiveUser();

    // Load pre existing content from prefilled or template
    // prefilled takes precedence over the default template
    const isPrefilled =
      !!opts?.prefill &&
      (!!opts.prefill.markdown || !!opts.prefill.richTextJson?.length);
    const template = journal?.template_id
      ? await this.templateRepository.getById(journal.template_id)
      : undefined;
    const useTemplate = !isPrefilled && !!template;

    // only add template tags if it's going to use the template
    const tags = useTemplate ? template?.tags : (opts?.prefill?.tags ?? []);
    let templateId: string | null = null;
    if (useTemplate) {
      templateId = template?.clientId;
    } else if (opts?.prefill?.templateId) {
      templateId = opts.prefill.templateId;
    }
    const promptId =
      isPrefilled && opts?.prefill?.promptId ? opts.prefill.promptId : null;

    let existingRtj = createRichTextJsonString([{ text: "" }]);
    const autoTitleFirstLine =
      (await this.userSettingsRepository.loadSettings())
        ?.auto_title_first_line ?? true;
    if (autoTitleFirstLine) {
      existingRtj = createRichTextJsonString([
        { text: "", attributes: { line: { header: 1 } } },
      ]);
    }
    if (isPrefilled && opts?.prefill?.richTextJson) {
      existingRtj = createRichTextJsonString(opts?.prefill?.richTextJson);
    } else if (useTemplate) {
      existingRtj = template?.richText;
      const parsedJson = JSON.parse(existingRtj) as RichTextJSON;
      if (parsedJson.meta && !parsedJson.meta.created.version) {
        parsedJson.meta.created.version = getAppCode();
        existingRtj = JSON.stringify(parsedJson);
      }
    }

    const entry = await this.addEntryToDB(
      {
        id,
        journal_id: journalId,
        owner_user_id: null,
        creator_user_id: user?.id ?? null,
        editor_user_id: null,
        body: opts?.prefill?.markdown ?? "",
        activity: "",
        client_meta: JSON.stringify(clientMeta),
        date: entryDate.getTime(),
        duration: 0,
        edit_date: entryDate.getTime(),
        editing_time: 0,
        // This a new Entry, we only add the RTJ flag
        feature_flags: "10",
        is_all_day: 0,
        is_deleted: 0,
        is_pinned: 0,
        is_starred: 0,
        location: null,
        timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
        user_edit_date: entryDate.getTime(),
        last_editing_device_name: clientMeta.deviceName,
        last_editing_device_id: clientMeta.deviceId,
        rich_text_json: existingRtj,
        templateID: templateId,
        promptID: promptId,
        weather: null,
        reactions: [],
        unread_marker_id: null,
        is_shared: journal?.is_shared ? 1 : 0,
        hide_all_entries: journal?.hide_all_entries ? 1 : 0,
      },
      tagsAsArray(tags),
      { audio: 0, photos: 0, videos: 0 },
    );
    if (!entry) {
      throw new Error(
        "Failed to get entry from DB after creating it, this should never happen",
      );
    } else {
      return entry;
    }
  }

  async addEntryToDB(
    entry: EntryDBRow,
    tags: string[],
    counts = { audio: 0, photos: 0, videos: 0 },
  ) {
    return this.db.transaction(
      "rw",
      this.db.entries,
      this.db.entry_counts_cache,
      this.db.tags,
      this.db.reactions,
      async () => {
        await this.db.entries.add(entry);

        if (tags.length > 0) {
          const tagRows = tags.map((tag) => ({
            entry_id: entry.id,
            journal_id: entry.journal_id,
            tag,
          }));
          await this.tagRepository.addTagsForEntry(tagRows);
        }

        await this.journalStatsRepository.modifyCachedCount(entry.journal_id, {
          count: 1,
          photos: counts.photos,
          audio: counts.audio,
          videos: counts.videos,
        });

        const newOne = await this.getEntryById({
          journalId: entry.journal_id,
          entryId: entry.id,
        });
        return newOne;
      },
    );
  }

  async importEntry(
    entry: EntryDBRow,
    contents: RTJNode[],
    tags: string[],
    media: ImportMedia,
    createPDFThumbnail: (
      f: File | Uint8Array,
      moment: MomentDBRow,
    ) => Promise<void>,
  ) {
    const mediaCounts = this.getMediaCounts(contents);
    analytics.tracks.recordEvent(EVENT.entryCreate, {
      method: "import",
      shared_journal: false,
      entry_id: entry.id,
    });
    if (!entry.rich_text_json) {
      entry.rich_text_json = JSON.stringify({ contents: [] });
    }
    await this.importMomentData(
      media,
      entry.id,
      entry.journal_id,
      createPDFThumbnail,
    );
    const result = await this.addEntryToDB(entry, tags, {
      photos: mediaCounts.photos,
      audio: mediaCounts.audio,
      videos: mediaCounts.videos,
    });
    return result;
  }

  async importMomentData(
    media: ImportMedia,
    entryId: string,
    journalId: string,
    createPDFThumbnail: (
      f: File | Uint8Array,
      moment: MomentDBRow,
    ) => Promise<void>,
  ) {
    const photos = media.photos ?? [];
    const videos = media.videos ?? [];
    const audios = media.audios ?? [];
    const pdfs = media.pdfs ?? [];

    for (const photo of photos) {
      await this.momentRepository.createPhotoMomentFromImportData(
        photo,
        entryId,
        journalId,
      );
    }
    for (const video of videos) {
      await this.momentRepository.createVideoMomentFromImportData(
        video,
        entryId,
        journalId,
      );
    }
    for (const audio of audios) {
      await this.momentRepository.createAudioMomentFromImportData(
        audio,
        entryId,
        journalId,
      );
    }
    for (const pdf of pdfs) {
      await this.momentRepository.createPDFMomentFromImportData(
        pdf,
        entryId,
        journalId,
        createPDFThumbnail,
      );
    }
  }

  async getUnreadEntries() {
    return (
      await this.db.entries.where("is_shared").equals(1).toArray()
    ).filter((entry) => !!entry.unread_marker_id);
  }

  async bulkUpdateUnreadMarker(globalEntryIDs: GlobalEntryID[]) {
    await this.db.entries
      .where(["journal_id", "id"])
      .anyOf(globalEntryIDs.map((e) => [e.journal_id, e.id]))
      .modify({
        unread_marker_id: null,
      });
  }

  async getAllEntryIdsByJournal(journalId: string) {
    const entries = await this.db.entries
      .where("journal_id")
      .equals(journalId)
      .toArray();
    return entries.map((entry) => ({
      id: entry.id,
      journal_id: entry.journal_id,
    }));
  }

  subToAllIDs(
    callback: (ids: GlobalEntryID[], causedBy?: string) => void,
    sortMethod = "entryDate",
    journalId?: string,
  ) {
    if (journalId == journalId_AllEntries) {
      journalId = undefined;
    }
    const sortCol = sortMethod === "entryDate" ? "date" : "user_edit_date";
    // If we're querying all journals, we use the index that puts date first
    // so that entries aren't grouped into journals before sorting by date.
    // If we're querying just one journal, we use the index that puts journal_id first
    // so that we can quickly slice into a subset of our data without having to filter
    // keys with a callback.
    const { index, start, end } = journalId
      ? {
          index: ["journal_id", "is_deleted", sortCol, "id"],
          start: [journalId, 0, Dexie.minKey, Dexie.minKey],
          end: [journalId, 0, dexieMaxKey(), dexieMaxKey()],
        }
      : {
          index: ["is_deleted", sortCol, "journal_id", "id"],
          start: [0, Dexie.minKey, Dexie.minKey, Dexie.minKey],
          end: [0, dexieMaxKey(), dexieMaxKey(), dexieMaxKey()],
        };

    const stream = liveQuery(async () => {
      const keys = await this.db.entries
        .where(index)
        .between(start, end)
        .reverse()
        .keys();
      return keys as unknown as string[];
    }).subscribe(
      (keys) => {
        // This is going to be an array that matches the index we used
        // to make the query in the first place.
        const ids: GlobalEntryID[] = keys.map((key) => {
          const journalIdIndex = journalId ? 0 : 2;
          const entryIdIndex = 3;
          return {
            journal_id: key[journalIdIndex],
            id: key[entryIdIndex],
          };
        });
        callback(ids);
      },
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      stream.unsubscribe();
    };
  }

  subToAllDatedIDs(
    callback: (ids: FullTimelineEntryID[], causedBy?: string) => void,
    sortMethod = "entryDate",
    journalId?: string,
  ) {
    if (journalId == journalId_AllEntries) {
      journalId = undefined;
    }
    const sortCol = sortMethod === "entryDate" ? "date" : "user_edit_date";
    // If we're querying all journals, we use the index that puts date first
    // so that entries aren't grouped into journals before sorting by date.
    // If we're querying just one journal, we use the index that puts journal_id first
    // so that we can quickly slice into a subset of our data without having to filter
    // keys with a callback.
    const { index, start, end } = journalId
      ? {
          index: [
            "journal_id",
            "is_deleted",
            sortCol,
            "id",
            "is_shared",
            "is_pinned",
          ],
          start: [journalId, 0, Dexie.minKey, Dexie.minKey],
          end: [journalId, 0, dexieMaxKey(), dexieMaxKey()],
        }
      : {
          index: [
            "hide_all_entries",
            "is_deleted",
            sortCol,
            "journal_id",
            "id",
            "is_shared",
            "is_pinned",
          ],
          start: [0, 0, Dexie.minKey, Dexie.minKey, Dexie.minKey],
          end: [0, 0, dexieMaxKey(), dexieMaxKey(), dexieMaxKey()],
        };

    const stream = liveQuery(async () => {
      return this.db.entries
        .where(index)
        .between(start, end)
        .reverse()
        .toArray();
    }).subscribe(
      (entries) => {
        const ids: FullTimelineEntryID[] = entries.map((entry) => {
          return {
            journal_id: entry.journal_id,
            id: entry.id,
            date:
              sortMethod === "entryDate" ? entry.date : entry.user_edit_date,
            is_shared: entry.is_shared,
            is_pinned: entry.is_pinned,
            is_starred: entry.is_starred,
            contents: decodeRichTextJson(entry.rich_text_json).contents,
            creator_user_id: entry.creator_user_id ?? null,
            timezone: entry.timezone,
          };
        });
        callback(ids);
      },
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      stream.unsubscribe();
    };
  }

  subByIds(
    globalEntryIDs: GlobalEntryID[],
    callback: (entries: EntryModel[], causedBy?: string) => void,
    sortMethod = "entryDate",
  ) {
    const sortCol = sortMethod === "entryDate" ? "date" : "user_edit_date";
    const matchCriteria = globalEntryIDs.map((pair) => [
      pair.journal_id,
      pair.id,
    ]);
    const stream = liveQuery(() => {
      return this.db.entries
        .where(["journal_id", "id"])
        .anyOf(matchCriteria)
        .reverse()
        .sortBy(sortCol);
    }).subscribe(
      (rows) => {
        const data = rows.map((row) => new EntryModel(row));
        callback(data);
      },
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      stream.unsubscribe();
    };
  }

  subToEntriesByDay(
    journalId: string,
    selectedDate: Date,
    callback: (entries: EntryDBRow[]) => void,
  ) {
    const stream = liveQuery(async () => {
      const entries = this.getEntriesByDay(journalId, selectedDate);
      return entries;
    }).subscribe(
      (entries) => {
        callback(entries);
      },
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      stream.unsubscribe();
    };
  }

  subToEntriesByPromptIds(
    promptIds: string[],
    callback: (entries: EntryPromptKeys[]) => void,
  ) {
    const stream = liveQuery(async () => {
      const entries = await this.db.entries
        .where("promptID")
        .anyOf(promptIds)
        .toArray();

      return entries.map((entry) => {
        return {
          promptId: entry.promptID,
          date: entry.date,
          journal_id: entry.journal_id,
          entry_id: entry.id,
        };
      });
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      stream.unsubscribe();
    };
  }

  subToJournalEntriesByPromptIds(
    promptIds: string[],
    journalId: string,
    callback: (entries: EntryPromptKeys[]) => void,
  ) {
    const indexes = promptIds.map((promptId) => [0, journalId, promptId]);
    const stream = liveQuery(async () => {
      const entries = await this.db.entries
        .where("[is_deleted+journal_id+promptID]")
        .anyOf(indexes)
        .toArray();

      return entries.map((entry) => {
        return {
          promptId: entry.promptID,
          date: entry.date,
          journal_id: entry.journal_id,
          entry_id: entry.id,
        };
      });
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      stream.unsubscribe();
    };
  }

  subToFullEntriesByPromptIds(
    promptIds: string[],
    callback: (entries: EntryModel[]) => void,
  ) {
    const stream = liveQuery(async () => {
      const entries = await this.db.entries
        .where("promptID")
        .anyOf(promptIds)
        .toArray();

      return entries;
    }).subscribe(
      (entries) => {
        callback(entries.map((entry) => new EntryModel(entry)));
      },
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      stream.unsubscribe();
    };
  }

  subToCalendarEntryKeys = (
    journalId: string,
    callback: (keys: EntryKeys[]) => void,
  ) => {
    const stream = liveQuery(async () => {
      const entries = this.getEntryKeysBetweenDates(journalId);
      return entries;
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      stream.unsubscribe();
    };
  };

  subscribeToCount(
    journalId: string | null,
    callback: (count: number) => void,
  ) {
    if (!journalId)
      return () => {
        /*An empty function*/
      };
    const stream = liveQuery(async () => {
      const x = await this.db.entry_counts_cache
        .get(journalId)
        .then((x) => x?.count ?? 0);
      return x;
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      stream.unsubscribe();
    };
  }

  subscribeToEntry(
    globalEntryID: GlobalEntryID,
    callback: (data: {
      entry: EntryDBRow | undefined;
      moments: MomentDBRow[];
    }) => void,
  ) {
    const stream = liveQuery(async () => {
      const [entry, moments] = await Promise.all([
        this.db.entries
          .where(["journal_id", "id"])
          .equals([globalEntryID.journal_id, globalEntryID.id])
          .first(),
        this.momentRepository.getForEntry(
          globalEntryID.journal_id,
          globalEntryID.id,
          true,
        ),
      ]);
      return { entry, moments };
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      stream.unsubscribe();
    };
  }

  subscribeToEntries(
    globalEntryIDs: GlobalEntryID[],
    callback: (entry: EntryDBRow[]) => void,
  ) {
    if (globalEntryIDs.length === 0) {
      callback([]);
      return () => {
        /*An empty function*/
      };
    }

    const stream = liveQuery(async () => {
      const entries = await Promise.all(
        globalEntryIDs.map(async (globalEntryID) =>
          this.getEntryById({
            journalId: globalEntryID.journal_id,
            entryId: globalEntryID.id,
          }),
        ),
      );
      const results: EntryDBRow[] = [];
      entries.forEach((entry) => {
        if (entry) {
          results.push(entry);
        }
      });
      return results;
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      stream.unsubscribe();
    };
  }

  subscribeToCalendarPreviewsForDay = (
    journalId: string,
    date: Date,
    callback: (data: CalendarEntryPreviewMetadata[]) => void,
  ) => {
    const momentUrls: {
      [id: string]: { momentId: string; thumbnailUrl: string | null };
    } = {};

    const stream = liveQuery(async () => {
      const entries = await this.getEntriesByDay(journalId, date);
      const meta: CalendarEntryPreviewMetadata[] = await Promise.all(
        entries.map(async (e) => {
          const moments = await this.momentRepository.getForEntry(
            e.journal_id,
            e.id,
            true,
          );
          const firstMoment = this.getFirstMomentByPositionInEntry(moments, e);
          // If we already have a thumbnail for this entry or
          // the first moment changed we should generate a new thumbnail
          const shouldGenerateThumbnail =
            !momentUrls[e.id] || momentUrls[e.id].momentId !== firstMoment?.id;

          // If there is no more moment for this entry we delete the thumbnail URL
          if (!firstMoment) {
            delete momentUrls[e.id];
          } else if (firstMoment && shouldGenerateThumbnail) {
            const thumbnailUrl =
              await this.momentRepository.getThumbnailUrlForMoment(firstMoment);
            momentUrls[e.id] = { thumbnailUrl, momentId: firstMoment.id };
          }

          return {
            id: e.id,
            journal_id: e.journal_id,
            media: momentUrls[e.id]?.thumbnailUrl,
          };
        }),
      );
      return meta;
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      Object.values(momentUrls).forEach(
        (m) => m.thumbnailUrl && URL.revokeObjectURL(m.thumbnailUrl),
      );
      stream.unsubscribe();
    };
  };

  // this relies on the index "is_shared", "journal_id", "id", "unread_marker_id"
  // which will only return results for entries that have a value for the unread marker
  // if another similar index is created this should still be specific enough to make sure it uses this one
  subscribeToUnreadByJournal(
    callback: (count: boolean) => void,
    journalId: string,
  ) {
    const stream = liveQuery(async () => {
      const unread = await this.db.entries
        .where(["is_shared", "journal_id", "id"])
        .equals([1, journalId])
        .count();
      return unread > 0;
    }).subscribe(callback);
    return () => stream.unsubscribe();
  }

  private getEntryMomentIds(entryBody: string) {
    const regex = /dayone-moment:\/\w*\/?([\w-]+)\b/g;
    const momentIds = [];

    let match;
    while ((match = regex.exec(entryBody)) !== null) {
      momentIds.push(match[1]);
    }

    return momentIds;
  }

  async getEntryMomentsFromBody(entry: EntryModel) {
    const momentIds = this.getEntryMomentIds(entry.body);
    const moments: MomentDBRow[] = [];
    for (const id of momentIds) {
      const moment = await this.momentRepository.getMomentById(
        entry.journalId,
        entry.id,
        id,
      );
      if (moment) moments.push(moment);
    }
    return moments;
  }

  async markEntryRead(globalEntryID: GlobalEntryID, unreadMarkerId: string) {
    const res = await this.fetch.fetchAPI(`/shares/unread-entries`, {
      method: "delete",
      body: JSON.stringify({ ids: [unreadMarkerId] }),
    });
    if (res.status === 200) {
      const entry = await this.getEntryById({
        journalId: globalEntryID.journal_id,
        entryId: globalEntryID.id,
      });
      if (entry) {
        await this.db.entries.update([entry.journal_id, entry.id], {
          unread_marker_id: null,
        });
      }
    } else {
      Sentry.captureException(
        new Error(`Unable to mark entry ${globalEntryID.id} as read`),
      );
    }
  }

  private getFirstMomentByPositionInEntry(
    moments: MomentDBRow[],
    entry: EntryDBRow,
  ) {
    // momentRepository.getFirstForEntry does not update when sequence of
    // images change.
    // Instead we can get the sequence of moment ids in entry
    // by parsing the markdown in body of the entry.
    // This is faster than getEntryBlocks(entryModel from entry)

    const momentIds = this.getEntryMomentIds(entry.body);

    const filteredMoments = moments.filter(
      (moment) =>
        momentIds.includes(moment.id) &&
        moment.thumbnail_md5_body &&
        moment.thumbnail_md5_body.length > 0,
    );

    // Sort the filtered moments based on their index in momentIds
    // The earlier the moment id, the earlier it should appear in the array
    const sortedMoments = filteredMoments.sort((a, b) => {
      const indexA = momentIds.indexOf(a.id);
      const indexB = momentIds.indexOf(b.id);
      return indexA - indexB;
    });

    // Get the first moment from the sorted moments, or use the first moment in the array if there are no matches
    const firstMoment = sortedMoments[0] || moments[0];
    return firstMoment;
  }

  async decryptLockedKeys(encryptedKeysInfo: EncryptionKeyInfo) {
    return Promise.all(
      encryptedKeysInfo.locked_key_infos.map(async (keyInfo) => {
        const decryptedKey = await getDecryptedKeyInfo(
          keyInfo,
          this.vaultRepository,
          "Entry move",
        );
        return {
          identifier: keyInfo.identifier,
          key: toBase64(decryptedKey),
        };
      }),
    );
  }

  async getEntriesByJournalID(journalId: string) {
    return this.db.entries.where("journal_id").equals(journalId).toArray();
  }

  async updateOutboxItemsJournalId(
    journalId: string,
    entryId: string,
    newJournalId: string,
    skipEntryMove = false,
  ) {
    return this.db.outbox_items.toCollection().modify((x: Sendable) => {
      const hasJournalId = isOriginalMediaSendable(x) || isEntrySendable(x);
      const shouldSkip = skipEntryMove && isEntryMoveSendable(x);

      if (
        hasJournalId &&
        x.journalId === journalId &&
        x.entryId === entryId &&
        !shouldSkip
      ) {
        x.journalId = newJournalId;
        x.id = x.id.replace(
          `${x.type}:${journalId}`,
          `${x.type}:${newJournalId}`,
        );
      }
    });
  }

  async getEntryMoveStatus(entryMoveId: string) {
    const moveStatus = await this.fetch.fetchAPI(
      `/v3/sync/entries/move/status/${entryMoveId}`,
    );
    if (moveStatus.ok) {
      return (await moveStatus.json()) as EntryMoveResponse;
    }
    return null;
  }

  async removePendingEntryUpdates(entryId: string) {
    await this.db.pending_entry_updates.delete(entryId);
  }

  async getPendingEntryUpdates(
    entryId: string,
  ): Promise<EntryMoveDBRow | undefined> {
    return await this.db.pending_entry_updates.get(entryId);
  }

  async storePendingEntryUpdates(entryId: string, entry: EntryDBRow) {
    const existingEntryMove = await this.db.pending_entry_updates
      .where("id")
      .equals(entryId)
      .first();
    if (existingEntryMove) {
      return await this.db.pending_entry_updates
        .where("id")
        .equals(entryId)
        .modify((x) => {
          x.entry = entry;
        });
    }
    return await this.db.pending_entry_updates.put({
      id: entryId,
      entry: entry,
    });
  }

  async bulkStorePendingEntryUpdates(entries: GlobalEntryID[]) {
    const entriesData = await this.db.entries
      .where(["journal_id", "id"])
      .anyOf(entries.map((e) => [e.journal_id, e.id]))
      .toArray();

    await this.db.pending_entry_updates.bulkPut(
      entriesData.map((x) => ({
        id: x.id,
        changes: { entry: { ...x, edit_date: Date.now() } },
      })),
    );
  }

  // Moves Entry to another Journal locally
  async moveEntryToJournal(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
  ) {
    const destinationJournal =
      await this.journalRepository.getById(destinationJournalId);

    if (
      !destinationJournal ||
      (await this.journalRepository.isSharedJournal(sourceJournalId))
    ) {
      return {
        transferred: false,
        shouldAddToOutbox: false,
        resultEntryId: null,
      };
    }

    let shouldAddToOutbox = true;

    const entryUpdateRes = await this.db.entries
      .where(["journal_id", "id"])
      .equals([sourceJournalId, entryId])
      .modify((x) => {
        x.journal_id = destinationJournalId;
        x.hide_all_entries = destinationJournal.hide_all_entries ? 1 : 0;
        x.is_shared = destinationJournal?.is_shared ? 1 : 0;
        // Only add Move to outbox if the entry has a revision_id, which means it's been synced to the server
        if (!x.revision_id) {
          shouldAddToOutbox = false;
        }
      });

    // If we couldn't update the entry we shouldn't bother updating everything else.
    if (entryUpdateRes) {
      analytics.tracks.recordEvent(EVENT.entryMoveCreated, {
        entry_id: entryId,
      });
      // We should update any outbox items that are related to this entry
      await this.updateOutboxItemsJournalId(
        sourceJournalId,
        entryId,
        destinationJournalId,
        true, // Skip entry move outbox items
      );

      // Update the entry count cache so it's reflected in the UI
      await this.db.entry_counts_cache
        .where("journal_id")
        .anyOf([sourceJournalId, destinationJournalId])
        .modify((x) => {
          if (x.journal_id === sourceJournalId) {
            x.count = Math.max(x.count - 1, 0);
          }
          if (x.journal_id === destinationJournalId) {
            x.count = x.count + 1;
          }
        });

      // Update tags and moments journal ID so they show immediately after the local update
      await this.tagRepository.changeJournalIdForTagsInEntry(
        entryId,
        sourceJournalId,
        destinationJournalId,
      );

      await this.momentRepository.changeJournalIdForMomentsInEntry(
        entryId,
        sourceJournalId,
        destinationJournalId,
      );
      return { transferred: true, shouldAddToOutbox, resultEntryId: entryId };
    }
    await this.removePendingEntryUpdates(entryId);
    return { transferred: false, shouldAddToOutbox, resultEntryId: entryId };
  }

  // Undoes the local changes made by moveEntryToJournal
  async undoEntryMove(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
  ) {
    // Get the old entry so we can clone it
    await this.db.entries
      .where(["journal_id", "id"])
      .equals([sourceJournalId, entryId])
      .modify({
        is_deleted: 0,
      });

    // This deletes the entry from the destination journal
    const deletedMovedEntry = await this.db.entries
      .where(["journal_id", "id"])
      .equals([destinationJournalId, entryId])
      .delete();

    if (deletedMovedEntry) {
      // Update the entry count cache so it's reflected in the UI
      await this.db.entry_counts_cache
        .where("journal_id")
        .anyOf([sourceJournalId, destinationJournalId])
        .modify((x) => {
          if (x.journal_id === sourceJournalId) {
            x.count = x.count + 1;
          }
          if (x.journal_id === destinationJournalId) {
            x.count = Math.max(x.count + 1, 0);
          }
        });

      // Update tags and moments journal ID so they show immediately after the local update
      await this.tagRepository.changeJournalIdForTagsInEntry(
        entryId,
        destinationJournalId,
        sourceJournalId,
      );

      await this.momentRepository.changeJournalIdForMomentsInEntry(
        entryId,
        destinationJournalId,
        sourceJournalId,
      );
    }
  }

  // MARK: - Move Entry to a different Journal using server.
  async pushEntryMove(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
    fail = false,
  ): Promise<EntryMoveResponse | null> {
    const journalIsE2EE =
      await this.journalRepository.isJournalE2EE(sourceJournalId);
    const destinationJournalIsE2EE =
      await this.journalRepository.isJournalE2EE(destinationJournalId);

    let requestBody = {};
    try {
      if (journalIsE2EE) {
        const encKeysResponse = await this.fetch.fetchAPI(
          `/v3/sync/entries/keys/${sourceJournalId}/${entryId}`,
        );

        if (encKeysResponse.ok) {
          const encKeysJSON: EncryptionKeyInfo = await encKeysResponse.json();
          requestBody = {
            ...(destinationJournalIsE2EE && {
              // Generate new keys for the destination journal
              locked_key_infos: await generateNewKeys(
                encKeysJSON,
                destinationJournalId,
                this.vaultRepository,
                "Entry move",
              ),
            }),
            ...(!destinationJournalIsE2EE && {
              // Decrypt the locked keys for the destination journal
              content_keys: await this.decryptLockedKeys(encKeysJSON),
            }),
            sync_date: encKeysJSON.sync_date,
          };
        } else {
          throw new Error("Failed to get encryption keys");
        }
      }

      const moveResult = await this.fetch.fetchAPI(
        `/v3/sync/entries/move/${sourceJournalId}/${destinationJournalId}/${entryId}${fail ? "?pleaseFail=true" : ""}`,
        {
          method: "PUT",
          body: JSON.stringify({
            ...requestBody,
          }),
        },
      );
      if (moveResult.ok) {
        const moveResponse: EntryMoveResponse = await moveResult.json();

        analytics.tracks.recordEvent(EVENT.entryMoveStarted, {
          entry_id: entryId,
        });
        return moveResponse;
      } else {
        if (moveResult.status === 400) {
          const errorbody = await moveResult.json();
          if (errorbody?.code === "entry_already_moved") {
            return {
              status: "completed",
              id: errorbody.code,
              message: errorbody.message,
            };
          }
        }
        throw new Error(`Unable to move entry ${entryId}`);
      }
    } catch (error) {
      Sentry.captureException(error);

      analytics.tracks.recordEvent(EVENT.entryMoveFailed, {
        entry_id: entryId,
        error: JSON.stringify(error),
      });
      await this.undoEntryMove(entryId, sourceJournalId, destinationJournalId);
      return null;
    }
  }

  // Entry copy
  async copyEntryToJournal(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
  ) {
    // ToDo should be ok to copy from shared journals?
    if (await this.journalRepository.isSharedJournal(sourceJournalId)) {
      return {
        transferred: false,
        shouldAddToOutbox: false,
        resultEntryId: null,
      };
    }

    const journal = await this.journalRepository.getById(destinationJournalId);
    if (!journal) {
      return {
        transferred: false,
        shouldAddToOutbox: false,
        resultEntryId: null,
      };
    }

    const isDestinationJournalShared =
      await this.journalRepository.isSharedJournal(destinationJournalId);

    const entry = await this.db.entries
      .where(["journal_id", "id"])
      .equals([sourceJournalId, entryId])
      .first();

    if (!entry) {
      throw new Error(
        `Entry ${entryId} not found in journal ${sourceJournalId}`,
      );
    }

    // Copy the entry and make a new entry id
    const newEntry = {
      ...entry,
      journal_id: destinationJournalId,
      is_shared: isDestinationJournalShared ? 1 : 0,
      id: mkEntryID(),
    };

    // Copy the tags to the new entry
    const tags = await this.tagRepository.getTagsForEntry(
      sourceJournalId,
      entryId,
    );

    const entryCopyRes = await this.addEntryToDB(newEntry, tags);

    // If we couldn't update the entry we shouldn't bother updating everything else.
    if (entryCopyRes) {
      const moments =
        await this.momentRepository.copyMomentsToNewJournalAndEntry(
          sourceJournalId,
          entryId,
          destinationJournalId,
          newEntry.id,
        );

      // Unlike entry move, we just push the copied entry as a new entry
      this.pushEntry(newEntry, moments, journal, tags);

      return {
        transferred: true,
        shouldAddToOutbox: false,
        resultEntryId: newEntry.id,
      };
    }
    await this.removePendingEntryUpdates(entryId);
    return {
      transferred: false,
      shouldAddToOutbox: false,
      resultEntryId: null,
    };
  }

  async updateEntriesHideAllEntriesStatus(
    hide_all_entries: number,
    journalId: string,
  ) {
    await this.db.entries
      .where("journal_id")
      .equals(journalId)
      .modify({ hide_all_entries });
  }
}
export type CreateNewEntryOpts = {
  useEntryId?: string;
  prefill?: Prefill;
  date?: Date;
};

export type EntryPromptKeys = {
  promptId: string | null;
  date: number;
  journal_id: string;
  entry_id: string;
};

type Prefill = PrefillContent & {
  tags?: string[];
  templateId?: string;
  promptId?: string;
  galleryTemplateID?: string;
};

// Need at least one form of content if creating a prefilled entry
type PrefillContent =
  | { richTextJson: RTJNode[]; markdown?: string }
  | { markdown: string; richTextJson?: RTJNode[] };
