import { FetchWrapper } from "@/api/FetchWrapper";
import { safelyDecodeAPIResponse } from "@/data/api_callers/utils/safelyDecodeAPIResponse";
import {
  DailyChat,
  DailyChatContext,
  DailyChatSchema,
} from "@/data/models/DailyChatModels";

/**
 * API Caller for the Daily Chat feature
 */
export class DailyChatAPICaller {
  private baseUrl = "/labs/ai/daily-chat";

  constructor(private fetchWrapper: FetchWrapper) {}

  /**
   * Fetch chat data for a specific date
   * @param date - The date in YYYY-MM-DD format
   * @param timezone - The user's timezone
   * @returns The chat data from the API
   */
  async fetchChatForDate(date: string, timezone: string): Promise<DailyChat> {
    const response = await this.fetchWrapper.fetchAPI(
      `${this.baseUrl}/${date}`,
    );

    if (response.status === 404) {
      // Chat doesn't exist yet, create it
      return this.createChatForDate(date, timezone);
    }

    if (!response.ok) {
      throw new Error(`Failed to fetch chat: ${response.statusText}`);
    }

    const data = await response.json();
    const parsedData = safelyDecodeAPIResponse(DailyChatSchema, data);

    return parsedData;
  }

  /**
   * Create a new chat for a specific date
   * @param date - The date in YYYY-MM-DD format
   * @param timezone - The user's timezone
   * @returns The newly created chat data
   */
  private async createChatForDate(
    date: string,
    timezone: string,
  ): Promise<DailyChat> {
    const response = await this.fetchWrapper.fetchAPI(this.baseUrl, {
      method: "POST",
      body: JSON.stringify({
        date,
        timezone,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to create chat: ${response.statusText}`);
    }

    const data = await response.json();
    const parsedData = safelyDecodeAPIResponse(DailyChatSchema, data);

    return parsedData;
  }

  /**
   * Send a message to the chat
   * @param date - The date in YYYY-MM-DD format
   * @param message - The message content
   * @param responseToAlternativeContentIdx - Optional index of the alternative content being responded to
   * @returns The updated chat with new messages
   */
  async sendMessage(
    date: string,
    message: string,
    responseToAlternativeContentIdx?: number,
  ): Promise<DailyChat> {
    const requestBody: {
      message: string;
      response_to_alternative_content_idx?: number;
    } = {
      message: message,
    };

    // Add the response_to_alternative_content_idx field if provided
    if (responseToAlternativeContentIdx !== undefined) {
      requestBody.response_to_alternative_content_idx =
        responseToAlternativeContentIdx;
    }

    const response = await this.fetchWrapper.fetchAPI(
      `${this.baseUrl}/${date}/messages`,
      {
        method: "POST",
        body: JSON.stringify(requestBody),
      },
    );

    if (!response.ok) {
      throw new Error(`Failed to send message: ${response.statusText}`);
    }

    // API returns the full chat object with updated chat history
    const data = await response.json();
    const parsedData = safelyDecodeAPIResponse(DailyChatSchema, data);

    return parsedData;
  }

  /**
   * Update the context for a chat
   * @param date - The date in YYYY-MM-DD format
   * @param context - The new context data
   * @returns The updated chat data
   */
  async updateContext(
    date: string,
    context: DailyChatContext,
    timezone: string,
  ): Promise<DailyChat> {
    const response = await this.fetchWrapper.fetchAPI(
      `${this.baseUrl}/${date}/context`,
      {
        method: "POST",
        body: JSON.stringify({
          context: context,
          timezone: timezone,
        }),
      },
    );

    if (!response.ok) {
      throw new Error(`Failed to update context: ${response.statusText}`);
    }

    const data = await response.json();
    const parsedData = safelyDecodeAPIResponse(DailyChatSchema, data);

    return parsedData;
  }

  /**
   * Regenerate the last assistant message
   * @param date - The date in YYYY-MM-DD format
   * @returns The updated chat with regenerated message
   */
  async regenerateLastMessage(date: string): Promise<DailyChat> {
    const response = await this.fetchWrapper.fetchAPI(
      `${this.baseUrl}/${date}/regenerate`,
      {
        method: "POST",
      },
    );

    if (!response.ok) {
      throw new Error(`Failed to regenerate message: ${response.statusText}`);
    }

    const data = await response.json();
    const parsedData = safelyDecodeAPIResponse(DailyChatSchema, data);

    return parsedData;
  }

  /**
   * Clear the chat history for a specific date
   * @param date - The date in YYYY-MM-DD format
   * @returns The updated empty chat
   */
  async clearChat(date: string): Promise<DailyChat> {
    const response = await this.fetchWrapper.fetchAPI(
      `${this.baseUrl}/${date}/clear`,
      {
        method: "POST",
      },
    );

    if (!response.ok) {
      throw new Error(`Failed to clear chat: ${response.statusText}`);
    }

    const data = await response.json();
    const parsedData = safelyDecodeAPIResponse(DailyChatSchema, data);

    return parsedData;
  }

  /**
   * Get a summary of the chat for a specific date
   * @param date - The date in YYYY-MM-DD format
   * @returns The chat summary and AI messages
   */
  async getSummary(date: string): Promise<{ summary: string }> {
    const response = await this.fetchWrapper.fetchAPI(
      `${this.baseUrl}/${date}/summary`,
      {
        method: "POST",
      },
    );

    if (!response.ok) {
      throw new Error(`Failed to get summary: ${response.statusText}`);
    }

    const data = await response.json();
    return {
      summary: data.summary,
    };
  }
}
