import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance, createBlock } from "@wordpress/blocks";
import { __experimentalDivider as Divider } from "@wordpress/components";
import { useDispatch, useSelect } from "@wordpress/data";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Dropdown } from "@/components/D1Dropdown";
import { PARAGRAPH_BLOCK_ID } from "@/components/Editor/blocks/constants";
import { MenuButton } from "@/components/Editor/components/MenuButton";
import { MediaButton } from "@/components/SyncableImage/MediaButton";
import { MomentModel } from "@/data/models/MomentModel";
import { useSubscription } from "@/hooks/d1_hooks";
import { useGalleryMedia } from "@/hooks/useGalleryMedia";
import { dateTimeToString } from "@/utils/date-helper";
import { activeEntryViewState } from "@/view_state/ViewStates";

interface Props {
  journalId: string;
  entryId: string;
  momentId: string;
  clientId: BlockInstance["clientId"];
  rotateLeft?: () => void;
}

export const MediaMenu = ({
  journalId,
  entryId,
  momentId,
  clientId,
  rotateLeft,
}: Props) => {
  const { __, _x } = useI18n();
  const { insertBlock, removeBlock, selectionChange } =
    useDispatch(blockEditorStore);
  const {
    getBlock,
    getBlockIndex,
    getBlockRootClientId,
    getNextBlockClientId,
  } = useSelect(blockEditorStore, []);
  const { splitGalleryBlock } = useGalleryMedia();

  const momentSubscribed = useSubscription<MomentModel>(
    (cb) => {
      d1Classes.momentStore.subscribeToMomentById(
        cb,
        journalId,
        entryId,
        momentId,
      );
    },
    [entryId, journalId, momentId],
  );

  // fallback to entry date when moment date is 0
  // check issue https://github.com/bloom/DayOne-Web/issues/4473
  const mediaDate =
    momentSubscribed?.data.date ||
    activeEntryViewState.entryModel?.date ||
    Date.now();

  const toggleFavorite = async () => {
    await d1Classes.momentStore.toggleFavorite(journalId, entryId, momentId);
    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "entry_mediaMenu_toggleFavorite",
    });
  };

  const insertTextBelow = () => {
    const galleryBlockClientId = getBlockRootClientId(clientId);

    if (galleryBlockClientId) {
      splitGalleryBlock(clientId, journalId, entryId);
    } else {
      // for single media
      const nextBlock = getBlock(getNextBlockClientId(clientId));

      if (!nextBlock || nextBlock.name !== PARAGRAPH_BLOCK_ID) {
        insertBlock(
          createBlock(PARAGRAPH_BLOCK_ID, {}),
          getBlockIndex(clientId) + 1,
        );
      }

      selectionChange(getNextBlockClientId(clientId), "content", 0, 0);
    }

    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "entry_mediaMenu_insertTextBelow",
    });
  };

  const onCopy = async () => {
    if (!momentSubscribed) {
      return;
    }

    await navigator.clipboard.writeText(
      JSON.stringify({
        journalId,
        entryId,
        momentId: momentSubscribed.id,
      }),
    );

    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "entry_mediaMenu_copy",
    });
  };

  const onCut = async () => {
    await onCopy();
    await onDelete();
    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "entry_mediaMenu_cut",
    });
  };

  const onDelete = async () => {
    await d1Classes.momentStore.deleteMoment(journalId, entryId, momentId);
    removeBlock(clientId);
    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "entry_mediaMenu_delete",
    });
  };

  const onRotateLeft = () => {
    rotateLeft?.();
    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "entry_mediaMenu_rotateLeft",
    });
  };

  const onUpdateEntryWithMediaInfo = async () => {
    if (momentSubscribed) {
      activeEntryViewState.changeDateHandler(
        new Date(
          dateTimeToString(
            new Date(mediaDate),
            momentSubscribed.data.metadata?.timeZoneName ||
              Intl.DateTimeFormat().resolvedOptions().timeZone,
          ),
        ),
      );
      analytics.tracks.recordEvent(EVENT.buttonTap, {
        button_identifier: "entry_mediaMenu_updateEntryWithMediaInfo",
      });
    }
  };

  const getUpdateEntryByMediaInfoTypeLabel = () => {
    switch (momentSubscribed?.metadata.type) {
      case "image":
        return "Image";
      case "video":
        return "Video";
      default:
        break;
    }
  };

  if (!momentSubscribed) {
    return null;
  }

  return (
    <D1Dropdown
      popoverProps={{ placement: "bottom-start", variant: "primary" }}
      renderToggle={({ isOpen, onToggle }) => (
        <MediaButton
          icon="dots-horizontal"
          label={__("Media menu")}
          onClick={() => {
            onToggle();
            if (!isOpen) {
              analytics.tracks.recordEvent(EVENT.buttonTap, {
                button_identifier: "entry_mediaMenu_opened",
              });
            }
          }}
        />
      )}
      renderContent={() => (
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            backgroundColor: "surface_light1_dark4",
            minWidth: "200px",
            fontSize: 1,
          }}
        >
          <div
            sx={{
              alignItems: "center",
              color: "textTertiary",
              display: "flex",
              fontSize: 1,
              p: 2,
            }}
          >
            {dateTimeToString(
              new Date(mediaDate),
              momentSubscribed.data.metadata?.timeZoneName ||
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              undefined,
              false,
            )}
          </div>

          <Divider sx={{ color: "borderPrimary" }} />

          <MenuButton onClick={onCut} icon="scissors">
            {__("Cut")}
          </MenuButton>
          <MenuButton onClick={onCopy} icon="copy">
            {__("Copy")}
          </MenuButton>

          <Divider sx={{ color: "borderPrimary" }} />

          <MenuButton
            onClick={toggleFavorite}
            icon={momentSubscribed?.data.favorite ? "heart-filled" : "heart"}
          >
            {!momentSubscribed?.data.favorite
              ? __("Favorite")
              : __("Unfavorite")}{" "}
          </MenuButton>
          <MenuButton onClick={insertTextBelow} icon="text-insert">
            {__("Insert Text Below")}
          </MenuButton>
          {rotateLeft && (
            <MenuButton onClick={onRotateLeft} icon="rotate-left">
              {__("Rotate Left")}
            </MenuButton>
          )}
          <MenuButton
            onClick={onUpdateEntryWithMediaInfo}
            icon="calendar-dates"
          >
            {sprintf(
              _x(
                "Update Entry to use %s Date",
                "Media type label of the button",
              ),
              getUpdateEntryByMediaInfoTypeLabel(),
            )}
          </MenuButton>

          <Divider sx={{ color: "borderPrimary" }} />

          <MenuButton isDestructive onClick={onDelete} icon="trash">
            {__("Delete")}
          </MenuButton>
        </div>
      )}
    />
  );
};
