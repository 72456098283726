import { unregisterBlockType, registerBlockType } from "@wordpress/blocks";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import {
  WORKOUT_BLOCK_ID,
  WorkoutBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { SuggestionWrapper } from "@/components/Editor/blocks/suggestions/SuggestionWrapper";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { formatDuration } from "@/utils/date-helper";
import { formatDistance } from "@/utils/distance";
import { getWorkoutIcon } from "@/utils/icons";
import { userSettingsViewState } from "@/view_state/ViewStates";

export const WorkoutBlock: React.FC<WorkoutBlockEditProps> = observer(
  ({ attributes, clientId, isSelected }) => {
    const { __ } = useI18n();
    const startDate = new Date(attributes.startDate);
    const endDate = new Date(attributes.endDate);
    const duration = (endDate.getTime() - startDate.getTime()) / 1000;
    const bpm = Math.round(attributes.workoutMetrics.averageHeartRate || 0);
    const icon = getWorkoutIcon(attributes.activityType);
    const { temperatureUnit } = userSettingsViewState;
    const unit = temperatureUnit === "C" ? "metric" : "imperial";
    const bodyParts: string[] = [];
    if (attributes.distance) {
      bodyParts.push(formatDistance(attributes.distance, unit, __));
    }
    if (attributes.startDate && attributes.endDate) {
      bodyParts.push(formatDuration(duration));
    }
    if (attributes.workoutMetrics.activeEnergyBurned) {
      bodyParts.push(
        `${attributes.workoutMetrics.activeEnergyBurned} ${__("CAL")}`,
      );
    }
    if (bpm > 0) {
      bodyParts.push(`${bpm} ${__("BPM")}`);
    }

    const body = bodyParts.join(", ");

    return (
      <SuggestionWrapper
        title={attributes.displayName || attributes.activityType}
        body={body}
        fallBackIconName={icon}
        clientId={clientId}
        isSelected={isSelected}
      />
    );
  },
);

export const register = () => {
  if (!blockIsRegistered(WORKOUT_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(WORKOUT_BLOCK_ID, {
      edit: (props: WorkoutBlockEditProps) => {
        return <WorkoutBlock {...props} />;
      },
      title: "Workout",
      category: "media",
      textdomain: "default",
      description: "Day One Workout Block",
      attributes: {
        type: "string",
        identifier: "string",
        route: "array",
        displayName: "string",
        workoutMetrics: {
          activeEnergyBurned: "number",
          averageHeartRate: "number",
        },
        activityType: "string",
        startDate: "string",
        endDate: "string",
        distance: "number",
        source: "string",
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(WORKOUT_BLOCK_ID)) {
    return unregisterBlockType(WORKOUT_BLOCK_ID);
  }
  return false;
};
