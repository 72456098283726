import { SuggestionTypes } from "types/rtj-format";

import { Icon } from "@/components/Icon";
import { getWorkoutIcon } from "@/utils/icons";

const getIconForSuggestion = (
  suggestionType: SuggestionTypes,
  activityType?: string,
) => {
  if (suggestionType === "contact") {
    return "embed_contact";
  }
  if (suggestionType === "song") {
    return "embed_song";
  }
  if (suggestionType === "motionActivity") {
    return "embed-activity";
  }
  if (suggestionType === "workout") {
    return getWorkoutIcon(activityType);
  }
  if (suggestionType === "location") {
    return "location-pin-map";
  }
  if (suggestionType === "podcast") {
    return "embed_podcast";
  }
  if (suggestionType === "genericMedia") {
    return "embed_song";
  }
  if (suggestionType === "stateOfMind") {
    return "embed_state-of-mind";
  }

  return "embed-activity";
};

type Props = {
  type: SuggestionTypes;
  activityType?: string;
};

export const SuggestionThumbnail: React.FC<Props> = ({
  type,
  activityType,
}) => {
  return (
    <div
      className="entry-media entry-media-thumbnail"
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "sm",
        background: "surface_light2_dark2",
        color: "textPrimary",
        flexShrink: 0,
      }}
    >
      <Icon
        icon={getIconForSuggestion(type, activityType)}
        sx={{ height: 4 }}
      />
    </div>
  );
};
