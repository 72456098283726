import { Button, Flex } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { D1Modal } from "@/components/D1Modal";
import { CheckboxControl } from "@/components/Form/CheckboxControl";
import { Icon } from "@/components/Icon";
import { SearchBox } from "@/components/SearchBox";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { TagCount } from "@/data/stores/TagStore";
import { sortTags } from "@/utils/tags";
import { SelectedEntryTags } from "@/view_state/EntryMultiSelectViewState";
import {
  activeEntryViewState,
  entryMultiSelectViewState,
  primaryViewState,
} from "@/view_state/ViewStates";

const getCurrentTags = (allTags: TagCount[], entryTags: string[]) => {
  const tags = allTags.reduce(
    (acc, tag) => {
      if (entryTags.includes(tag[0])) {
        acc.currentTags.push(tag);
      } else {
        acc.otherTags.push(tag);
      }
      return acc;
    },
    {
      currentTags: [],
      otherTags: [],
    } as { currentTags: TagCount[]; otherTags: TagCount[] },
  );
  return tags;
};

type SingleEntryTagManagerProps = {
  globalEntryID: GlobalEntryID;
  onChangeTags: () => void;
  onClose: () => void;
};

export const SingleEntryTagManagerModal: React.FC<SingleEntryTagManagerProps> =
  observer(({ globalEntryID, onChangeTags, onClose }) => {
    const tagStore = d1Classes.tagStore;
    const entryTags = activeEntryViewState.tags;
    const entryHashTags = activeEntryViewState.hashtags;

    const handleAddTag = (tag: string) => {
      tagStore.addForEntry(tag, globalEntryID).then(() => {
        onChangeTags();
      });
    };

    const handleRemoveTag = (tag: string) => {
      tagStore.removeForEntry(tag, globalEntryID).then(() => {
        onChangeTags();
      });
    };

    return (
      <TagManagerModal
        entryTags={entryTags}
        entryHashTags={entryHashTags}
        journalId={globalEntryID.journal_id}
        onAddTag={handleAddTag}
        onRemoveTag={handleRemoveTag}
        onClose={onClose}
      />
    );
  });

export type MultiEntryTagManagerProps = {
  journalId: string;
  onChangeTags?: (tag: string, isAdd: boolean) => void;
  onClose: () => void;
};

export const MultiEntryTagManagerModal: React.FC<MultiEntryTagManagerProps> =
  observer(({ journalId, onChangeTags, onClose }) => {
    const {
      multiSelectedEntries,
      selectedEntryTags,
      selectedEntryHashtags,
      updateSelectedEntriesTags,
    } = entryMultiSelectViewState;

    const handleAddTag = (tag: string) => {
      updateSelectedEntriesTags(tag).then(() => {
        onChangeTags?.(tag, true);
      });
    };

    const handleRemoveTag = (tag: string) => {
      updateSelectedEntriesTags(tag, false).then(() => {
        onChangeTags?.(tag, false);
      });
    };
    return (
      <TagManagerModal
        entryTags={Object.keys(selectedEntryTags)}
        entryHashTags={selectedEntryHashtags}
        journalId={journalId}
        onAddTag={handleAddTag}
        onRemoveTag={handleRemoveTag}
        onClose={onClose}
        multiSelectedEntries={multiSelectedEntries}
        selectedEntryTags={selectedEntryTags}
      />
    );
  });

type TagManagerProps = {
  journalId: string;
  entryTags: string[];
  entryHashTags: string[];
  onAddTag: (tag: string) => void;
  onRemoveTag: (tag: string) => void;
  onClose: () => void;
  multiSelectedEntries?: GlobalEntryID[];
  selectedEntryTags?: SelectedEntryTags;
};

export const TagManagerModal: React.FC<TagManagerProps> = observer(
  ({
    journalId,
    entryTags,
    entryHashTags,
    onAddTag,
    onRemoveTag,
    onClose,
    multiSelectedEntries,
    selectedEntryTags,
  }) => {
    const { __, _n } = useI18n();
    const [newTagInput, setNewTagInput] = useState<null | string>(null);
    const [showAll, setShowAll] = useState(true);
    const [confirmAction, setConfirmAction] = useState<{
      tag: string;
      isAdd: boolean;
      count: number;
    } | null>(null);

    const singleJournal = showAll ? undefined : journalId;
    const selectedEntriesTagCount: TagCount[] = selectedEntryTags
      ? Object.keys(selectedEntryTags).map((tag) => [
          tag,
          selectedEntryTags[tag].length,
        ])
      : [];

    let searchableTags = primaryViewState.getTagCounts(singleJournal);

    if (selectedEntriesTagCount?.length) {
      searchableTags = searchableTags.filter((s) => {
        return !selectedEntriesTagCount.some((t) => t[0] === s[0]);
      });
      const sortedTags = selectedEntriesTagCount.sort(sortTags);
      searchableTags = [...sortedTags, ...searchableTags];
    }
    const { currentTags, otherTags } = getCurrentTags(
      searchableTags,
      entryTags,
    );

    // tabs + search
    const constantHeaderHeight = 48 + 53;
    // If we are adding a new tag add that input height as well
    const headerHeight = newTagInput
      ? constantHeaderHeight + 48
      : constantHeaderHeight;

    const handleTagAction = (
      tag: string,
      changeCount: number,
      isAdd: boolean,
    ) => {
      if (multiSelectedEntries && multiSelectedEntries.length > 1) {
        if (changeCount > 0) {
          setConfirmAction({ tag, isAdd, count: changeCount });
        }
      } else {
        if (isAdd) {
          onAddTag(tag);
        } else {
          onRemoveTag(tag);
        }
      }
    };

    const handleConfirmAction = () => {
      if (confirmAction) {
        if (confirmAction.isAdd) {
          onAddTag(confirmAction.tag);
        } else {
          onRemoveTag(confirmAction.tag);
        }
        setConfirmAction(null);
      }
    };

    return (
      <>
        <D1Modal
          sx={{
            width: "420px",
            maxWidth: "100%",
            "& .components-modal__content": {
              p: 0,
              // Full screen for mobile minus top margin or a fixed size for larger screens
              height: ["calc(100dvh - 40px)", "420px"],
              overflow: "hidden",
            },
            "& .components-modal__header": { borderBottom: "none" },
          }}
          title={__("Manage tags")}
          onRequestClose={() => onClose()}
          shouldCloseOnClickOutside={true}
        >
          <Flex
            sx={{
              flexDirection: "column",
              gap: 0,
            }}
          >
            <TagFilter showAll={showAll} toggleShowAll={setShowAll} />
            <SearchBox
              value={newTagInput}
              setInputValue={setNewTagInput}
              onSubmit={onAddTag}
              onSubmitNullifyInput={true}
              placeholder={__("Search or create tag")}
              sx={{
                borderTop: "1px solid",
                borderColor: "borderPrimary",
                "&&& span": {
                  left: "30px",
                },
                "&&& .components-text-control__input": {
                  pl: "4rem",
                },
              }}
            />

            {newTagInput &&
              !Object.keys(searchableTags).some((t) => t === newTagInput) && (
                <Button
                  sx={{
                    height: "48px",
                    fontSize: "1rem",
                    "&&.components-button.has-icon.has-text": {
                      width: "95%",
                      color: "dayOneBlue",
                      pl: 4,
                      alignSelf: "baseline",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    },
                  }}
                  icon={<Icon icon="plus" sx={{ mr: 3, flexShrink: 0 }} />}
                  onClick={() => {
                    onAddTag(newTagInput);
                    setNewTagInput(null);
                  }}
                >
                  {sprintf(__('Create tag "%s"'), newTagInput)}
                </Button>
              )}
          </Flex>
          <div
            sx={{
              width: "100%",
              pb: 3,
              overflowY: "scroll",
              // Height of modal minus filters, search box, and padding
              // The height of the search box changes searching
              // Full screen for mobile minus top margin or the fixed size for larger screens
              height: newTagInput
                ? [
                    `calc(100dvh - ${headerHeight}px - 40px)`,
                    `calc(420px - ${headerHeight}px)`,
                  ]
                : [
                    `calc(100dvh - ${headerHeight}px - 40px)`,
                    `calc(420px - ${constantHeaderHeight}px)`,
                  ],
            }}
            title={__("Manage tags")}
          >
            <TagList
              tags={currentTags}
              hashtags={entryHashTags}
              onClick={(tag, changeCount, isIndeterminate) => {
                handleTagAction(tag, changeCount, isIndeterminate);
              }}
              inputValue={newTagInput}
              added={true}
              key="currentTags"
              multiSelectedEntries={multiSelectedEntries}
            />
            <TagList
              tags={otherTags}
              hashtags={entryHashTags}
              onClick={(tag) => {
                onAddTag(tag);
              }}
              inputValue={newTagInput}
              key="otherTags"
            />
          </div>
        </D1Modal>
        {confirmAction && (
          <ConfirmDialog
            handleClose={() => setConfirmAction(null)}
            handleAction={handleConfirmAction}
            title={confirmAction.isAdd ? __("Add tag") : __("Remove tag")}
            message={sprintf(
              _n(
                'Do you want to %1$s tag "%2$s" %3$s %4$d entry?',
                'Do you want to %1$s tag "%2$s" %3$s %4$d entries?',
                confirmAction.count,
              ),
              confirmAction.isAdd ? __("add") : __("remove"),
              confirmAction.tag,
              confirmAction.isAdd ? __("to") : __("from"),
              confirmAction.count,
            )}
            actionLabel={confirmAction.isAdd ? __("Add Tag") : __("Remove Tag")}
          />
        )}
      </>
    );
  },
);

TagManagerModal.displayName = "TagManagerModal";

const TagList: React.FC<{
  tags: TagCount[];
  hashtags: string[];
  onClick: (tag: string, changeCount: number, isIndeterminate: boolean) => void;
  inputValue: string | null;
  added?: boolean;
  multiSelectedEntries?: GlobalEntryID[];
}> = ({ tags, hashtags, onClick, inputValue, added, multiSelectedEntries }) => {
  return (
    <>
      {tags
        .filter(([t]) =>
          t.toLocaleLowerCase().includes(inputValue?.toLocaleLowerCase() || ""),
        )
        .map(([tag, count]) => {
          const selectedEntriesCount = multiSelectedEntries?.length ?? 1;
          const changeCount = selectedEntriesCount - count;

          return (
            <TagRow
              key={tag}
              isHashtag={hashtags.includes(tag)}
              tag={tag}
              added={changeCount > 0 ? changeCount : added}
              count={count}
              inMultiSelect={!!multiSelectedEntries}
              onClick={() =>
                onClick(
                  tag,
                  changeCount > 0 ? changeCount : selectedEntriesCount,
                  changeCount > 0,
                )
              }
            />
          );
        })}
    </>
  );
};

const TagRow: React.FC<{
  tag: string;
  isHashtag: boolean;
  count: number;
  added?: boolean | number;
  inMultiSelect?: boolean;
  onClick: () => void;
}> = ({ tag, isHashtag, count, added, inMultiSelect, onClick }) => {
  const { __ } = useI18n();

  const isDisabled = isHashtag && added === true;
  const isIndeterminate = inMultiSelect && typeof added === "number";
  const label = inMultiSelect
    ? isIndeterminate
      ? sprintf(__("Add tag to %d entries"), added)
      : sprintf(__("Remove tag from %d entries"), count)
    : added === true
      ? __("Remove Tag")
      : __("Add Tag");

  return (
    <div sx={{ pl: 2, pr: 1, py: 1 }}>
      <Button
        sx={{
          "&:hover": {
            bg: "surfaceHover",
          },
          py: 3,
          px: 3,
          display: "flex",
          color: "textPrimary",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          height: "48px",
          fontSize: 16,
          borderRadius: 3,
          gap: 4,
          "& .components-base-control__field": {
            mb: 0,
          },
        }}
        showTooltip={inMultiSelect}
        label={label}
        onClick={isDisabled ? () => {} : onClick}
        disabled={isDisabled}
        title={isDisabled ? __("Hashtags cannot be removed") : tag}
      >
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
            overflow: "hidden",
          }}
        >
          <div sx={{ ml: 2 }}>
            <CheckboxControl
              indeterminate={isIndeterminate}
              checked={!!added}
              disabled={isDisabled}
              onChange={() => {}}
            />
          </div>
          <div
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {tag}
          </div>
        </div>
        <span sx={{ color: "textSecondary", mr: 2 }}>{count}</span>
      </Button>
    </div>
  );
};

const TagFilter = ({
  showAll,
  toggleShowAll,
}: {
  showAll: boolean;
  toggleShowAll: (value: boolean) => void;
}) => {
  const { __ } = useI18n();

  return (
    <div
      sx={{
        display: "flex",
        fontSize: 2,
        fontWeight: "heading",
        height: "48px",
        alignItems: "baseline",
        alignSelf: "stretch",
        ml: 3,
        "& button": {
          color: "textPrimary",
          height: "100%",
          borderRadius: 0,
          px: 3,
        },
        "& button:disabled": {
          borderRadius: 0,
          borderBottom: "4px solid",
          opacity: 1,
        },
        "& button:not(:disabled):hover": {
          backgroundColor: "surfaceHover",
        },
      }}
    >
      <Button
        disabled={showAll}
        onClick={() => toggleShowAll(true)}
        label={__("Show tags for All Journals")}
      >
        {__("All Journals")}
      </Button>
      <Button
        disabled={!showAll}
        onClick={() => toggleShowAll(false)}
        label={__("Show tags for Current Journal")}
      >
        {__("Current Journal")}
      </Button>
    </div>
  );
};
