import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { useCallback } from "react";
import { useLocation } from "wouter";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { getEntryURL, getJournalURL } from "@/data/URLFunctions";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { journalId_AllEntries } from "@/view_state/PrimaryViewState";
import {
  featureFlagsViewState,
  primaryViewState,
  snackbarViewState,
  viewStates,
} from "@/view_state/ViewStates";

export enum TransferType {
  MOVE = "move",
  COPY = "copy",
}

export function useEntryTransfer() {
  const [, setLocation] = useLocation();
  const { __, _n } = useI18n();

  const showSnackbar = (
    message: string,
    redirectUrl?: string,
    showFailedMessage = false,
  ) => {
    const actions = redirectUrl
      ? [
          {
            label: __("Go to entry"),
            onClick: () => setLocation(redirectUrl),
          },
        ]
      : showFailedMessage && snackbarViewState.failedMoves.length > 0
        ? [
            {
              label: __("More details"),
              onClick: snackbarViewState.displayFailedMovesModal,
            },
          ]
        : [];
    viewStates.snackbar.newMessage(message, actions);
  };

  const finishTransfer = (
    destinationJournalId: string,
    entryCount = 1,
    transferType: TransferType,
    entryId?: string,
    shouldRedirect?: boolean,
    selectedEntries?: GlobalEntryID[],
  ) => {
    const canNavigateToJournal =
      primaryViewState.userCanNavigateToJournal(destinationJournalId);

    const shouldGoToAllEntries =
      !canNavigateToJournal ||
      primaryViewState.selectedJournalId === journalId_AllEntries;

    const redirectUrl = entryId
      ? getEntryURL(
          destinationJournalId,
          entryId,
          "timeline_view",
          shouldGoToAllEntries,
        )
      : shouldGoToAllEntries
        ? "/all"
        : getJournalURL(destinationJournalId, "timeline_view");

    const snackbarMessage = sprintf(
      transferType === TransferType.MOVE
        ? _n("%d Entry moved", "%d Entries moved", entryCount)
        : _n("%d Entry copied", "%d Entries copied", entryCount),
      entryCount,
    );
    if (shouldRedirect) {
      setLocation(redirectUrl, {
        state: {
          selectedEntries,
          journalId: destinationJournalId,
        },
      });
      showSnackbar(snackbarMessage);
    } else {
      showSnackbar(snackbarMessage, redirectUrl);
    }
  };

  const transferEntry = useCallback(
    async (
      entryId: string,
      sourceJournalId: string,
      destinationJournalId: string,
      transferType: TransferType,
      shouldRedirect?: boolean,
      handleTransferFinish?: boolean,
      fail?: boolean, // For testing purposes only
    ) => {
      try {
        const isShared =
          await d1Classes.journalStore.isSharedJournal(sourceJournalId);

        if (isShared && transferType === TransferType.MOVE) {
          viewStates.snackbar.newFailedMove({
            reason: "shared_journal_source",
            entryCount: 1,
          });
          return false;
        }
        let transferResult;
        if (transferType === TransferType.MOVE) {
          transferResult = await d1Classes.entryStore.moveToJournal(
            entryId,
            sourceJournalId,
            destinationJournalId,
            fail,
          );
        } else {
          transferResult = await d1Classes.entryStore.copyToJournal(
            entryId,
            sourceJournalId,
            destinationJournalId,
          );
        }

        if (!transferResult) {
          return;
        }

        if (
          transferResult.transferred === false &&
          transferType === TransferType.MOVE
        ) {
          viewStates.snackbar.newFailedMove({
            reason: "unknown",
            entryCount: 1,
          });
        }
        if (handleTransferFinish) {
          if (transferResult.transferred && transferResult.resultEntryId) {
            finishTransfer(
              destinationJournalId,
              1,
              transferType,
              transferResult.resultEntryId,
              shouldRedirect,
            );
          } else {
            showSnackbar(__("Entry could not be moved"), undefined, true);
          }
        }
        return transferResult;
      } catch (error) {
        const errorMessage =
          transferType === TransferType.MOVE
            ? `Failed to move entry: ${(error as Error).message}`
            : `Failed to copy entry: ${(error as Error).message}`;
        showSnackbar(
          transferType === TransferType.MOVE
            ? __("Entry could not be moved")
            : __("Entry could not be copied"),
          undefined,
          true,
        );
        Sentry.captureException(new Error(errorMessage));
      }
      return false;
    },
    [finishTransfer, showSnackbar],
  );

  const transferMultipleEntries = useCallback(
    async (
      entries: GlobalEntryID[],
      destinationJournalId: string,
      transferType: TransferType,
    ) => {
      viewStates.entryMultiSelect.setLoadingEntryMoves(true);
      const shouldFailTransfer = featureFlagsViewState.shouldFailEntryTransfers;

      await Promise.allSettled(
        entries.map(({ id, journal_id }, index) => {
          if (
            journal_id === destinationJournalId &&
            transferType === TransferType.MOVE
          )
            return;

          if (journal_id && id) {
            return transferEntry(
              id,
              journal_id,
              destinationJournalId,
              transferType,
              false,
              false,
              shouldFailTransfer && index % 2 === 0, // Fail every other Entry transfer for testing purposes
            );
          }
        }),
      )
        .then((results) => {
          const failed = results.filter((result) => {
            return (
              (result.status === "fulfilled" &&
                (!result.value || result.value.transferred === false)) ||
              result.status === "rejected"
            );
          });
          const failedCount = failed.length;
          const successCount = results.length - failedCount;

          if (failedCount > 0) {
            const snackbarMessage = sprintf(
              transferType === TransferType.MOVE
                ? _n(
                    "1 Entry could not be moved",
                    "%d entries could not be moved",
                    failedCount,
                  )
                : _n(
                    "1 Entry could not be copied",
                    "%d entries could not be copied",
                    failedCount,
                  ),
              failedCount,
            );
            showSnackbar(snackbarMessage, undefined, true);
          }
          if (successCount > 0) {
            let shouldRedirect = false;
            const singleEntrySelected = entries.length === 1;
            const activeEntrySelected =
              primaryViewState.selectedGlobalEntryID?.id === entries[0].id;
            if (
              entries.length > 1 ||
              (singleEntrySelected && activeEntrySelected)
            ) {
              shouldRedirect = true;
            }

            let resultEntryId: string | undefined;
            if (
              singleEntrySelected &&
              results[0]?.status === "fulfilled" &&
              results[0].value
            ) {
              resultEntryId = results[0].value.resultEntryId || undefined;
            }

            let selectedEntries: GlobalEntryID[] | undefined;
            if (!singleEntrySelected) {
              selectedEntries = [];

              entries.forEach((entry, index) => {
                const result = results[index];

                if (
                  transferType === TransferType.COPY &&
                  result?.status === "fulfilled" &&
                  result.value &&
                  typeof result.value === "object" &&
                  result.value.transferred &&
                  result.value.resultEntryId
                ) {
                  selectedEntries!.push({
                    id: result.value.resultEntryId,
                    journal_id: destinationJournalId,
                  });
                } else if (transferType === TransferType.MOVE) {
                  selectedEntries!.push({
                    id: entry.id,
                    journal_id: destinationJournalId,
                  });
                }
              });
            }

            finishTransfer(
              destinationJournalId,
              successCount,
              transferType,
              resultEntryId ??
                (singleEntrySelected ? entries[0].id : undefined),
              shouldRedirect,
              selectedEntries,
            );
          }
        })
        .catch((error) => {
          const errorMessage =
            transferType === TransferType.MOVE
              ? `Failed to move entries: ${(error as Error).message}`
              : `Failed to copy entries: ${(error as Error).message}`;
          showSnackbar(
            transferType === TransferType.MOVE
              ? __("Some entries could not be moved")
              : __("Some entries could not be copied"),
            undefined,
            true,
          );
          Sentry.captureException(new Error(errorMessage));
        });
      viewStates.entryMultiSelect.setLoadingEntryMoves(false);
    },
    [],
  );

  return {
    transferEntry,
    transferMultipleEntries,
  };
}
