import { ThemeUIStyleObject } from "@theme-ui/css";

import { Icon, IconFont } from "@/components/Icon";

const baseItemStyles = {
  display: "flex",
  flexGrow: 0,
  alignItems: "center",
  width: "100%",
  gap: 1,
};

const baseTextStyles: ThemeUIStyleObject = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

type DefaultProps = {
  icon: IconFont;
  size?: number;
  children: React.ReactNode;
};

const Default: React.FC<DefaultProps> = ({ icon, size = 20, children }) => {
  return (
    <div sx={baseItemStyles}>
      <Icon icon={icon} size={size} />
      <span sx={baseTextStyles}>{children}</span>
    </div>
  );
};

type GalleryProps = {
  template: {
    color: string;
    symbol?: IconFont;
    icon_url?: string;
    bgImage?: string;
  };
  children: React.ReactNode;
};

const Gallery: React.FC<GalleryProps> = ({ template, children }) => {
  return (
    <div sx={baseItemStyles}>
      <span
        sx={{
          flexShrink: 0,
          display: "inline-block",
          height: "24px",
          width: "24px",
          backgroundColor: template.color,
          backgroundImage: template.bgImage
            ? `url("${template.bgImage}")`
            : undefined,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: "sm",
          mr: 2,
          "& img": {
            mt: "5px",
            width: "14px",
            height: "14px",
            filter: "invert(1)",
          },
        }}
      >
        {!template.symbol ? (
          <img src={template.icon_url ?? ""} />
        ) : (
          <Icon
            icon={template.symbol}
            iconStyles={{
              display: "inline-block",
              color: "textPrimaryInverse",
              mt: "5px",
              fontSize: 1,
            }}
          />
        )}
      </span>
      <span sx={baseTextStyles}>{children}</span>
    </div>
  );
};

export const TemplateMenuItem = {
  Default,
  Gallery,
};
