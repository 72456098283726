import { Button } from "@wordpress/components";

import { Icon } from "@/components/Icon";
import { searchViewState } from "@/view_state/ViewStates";

export const RecentSearches: React.FC = () => {
  const {
    searchHistory: _searchHistory,
    setSearchString,
    saveSearchHistory,
  } = searchViewState;

  // show most recent search on top
  const reversedSearchHistory = [..._searchHistory].reverse();
  return (
    <>
      {reversedSearchHistory.map((searchString) => (
        <div
          sx={{
            "&& ": {
              opacity: 1,
              color: "textPrimary",
              lineHeight: "body",
              fontSize: 1,
              fontWeight: "body",
              pt: 1,
            },
            "&& :hover": {
              opacity: 0.7,
            },
          }}
          key={searchString}
        >
          <div sx={{ display: "flex" }}>
            <Button
              onClick={() => {
                setSearchString(searchString);
                saveSearchHistory(searchString);
              }}
              icon={() => <Icon icon="backup" size={4} />}
            >
              {searchString}
            </Button>
          </div>
        </div>
      ))}
    </>
  );
};
